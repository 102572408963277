import { ReactNode, useEffect, useRef, useState } from 'react';
import { StyledMarginTop, StyledStaticHeaderContainer } from './StaticHeader.styles';
import { useHeaderSizeContext } from 'components/Header/components/HeaderSizeContext';

type StaticHeaderProps = {
  children?: ReactNode;
};

export const StaticHeader = ({ children }: StaticHeaderProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [marginTop, setMarginTop] = useState(0);
  const headerSizeContext = useHeaderSizeContext();

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        const height = entry.contentRect.height;
        setMarginTop(height);

        // Set the CSS variable to the height of the header
        document.documentElement.style.setProperty('--header-scroll-padding-top', `${height}px`);

        headerSizeContext.setHeight(height);
      }
    });

    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    return () => resizeObserver.disconnect();
  }, [headerSizeContext]);

  return (
    <>
      <StyledStaticHeaderContainer ref={containerRef}>{children}</StyledStaticHeaderContainer>
      <StyledMarginTop marginTop={marginTop} />
    </>
  );
};
