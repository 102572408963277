import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  StyledButton,
  StyledContainer,
  StyledError,
  StyledForm,
  StyledHeading,
  StyledPinBox,
  StyledPinContainer,
} from './LoginDevicePage.styles';
import { ChangeEvent, FormEvent, KeyboardEvent, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { loginDevice } from 'services/deviceService';
import { useLogger, useSnackbars } from 'hooks';
import { RequestContentNotFoundError } from '@telia-company/tv.common-sdk/dist/ErrorTypes';

type ErrorTypes = 'LOGIN_USER_CODE_INVALID' | 'ERROR_GENERIC_INFORMATION';

export const LoginDevicePage = () => {
  const { t } = useTranslation();
  const logger = useLogger('LoginDevice');
  const [searchParams] = useSearchParams();
  const [error, setError] = useState<ErrorTypes>();
  const [busy, setBusy] = useState(false);
  const { createPositiveSnackbar, createNegativeSnackbar } = useSnackbars();
  const navigate = useNavigate();

  const userCodeFromParams = searchParams.get('userCode');

  const pinBoxRefs = useRef<HTMLInputElement[]>([]);

  const [userCodeValues, setUserCodeValues] = useState<{ [key: string]: string }>({
    0: userCodeFromParams?.charAt(0) || '',
    1: userCodeFromParams?.charAt(1) || '',
    2: userCodeFromParams?.charAt(2) || '',
    3: userCodeFromParams?.charAt(3) || '',
    4: userCodeFromParams?.charAt(4) || '',
    5: userCodeFromParams?.charAt(5) || '',
  });

  const userCode = Object.values(userCodeValues).join('');

  const onKeyDown = (pinBoxKey: string, event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Backspace' && event.currentTarget.value.length === 0) {
      const previousPinBox = pinBoxRefs.current[parseInt(pinBoxKey, 10) - 1];
      previousPinBox.focus();
      event.preventDefault();
    }
  };

  const onKeyUp = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.currentTarget.blur();
    }
  };

  const onChange = (pinBoxKey: string, event: ChangeEvent<HTMLInputElement>) => {
    const value = event.currentTarget.value[0] ?? '';
    if (value.length) {
      const nextPinBox = pinBoxRefs.current[parseInt(pinBoxKey, 10) + 1];
      if (nextPinBox) {
        nextPinBox.focus();
        nextPinBox.selectionStart = 0;
        nextPinBox.selectionEnd = 0;
      }
    }
    setUserCodeValues({ ...userCodeValues, [pinBoxKey]: value });
  };

  const onSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (busy) {
      return;
    }
    setBusy(true);
    try {
      await loginDevice(userCode);
      createPositiveSnackbar(t('LOGIN_DEVICE_SUCCESSFUL'));
      navigate('/');
    } catch (error: unknown) {
      if (error instanceof RequestContentNotFoundError) {
        setError('LOGIN_USER_CODE_INVALID');
      } else {
        logger.error('Failed to login device', error);
        setError('ERROR_GENERIC_INFORMATION');
        createNegativeSnackbar(t('ERROR_GENERIC_INFORMATION'));
      }
    } finally {
      setBusy(false);
    }
  };

  return (
    <StyledContainer>
      <StyledForm
        onSubmit={(e) => {
          onSubmit(e);
        }}
      >
        <StyledHeading>{t('ENTER_CODE_SHOWN_ON_DEVICE')}</StyledHeading>
        <StyledPinContainer>
          {Object.keys(userCodeValues).map((key) => (
            <StyledPinBox
              autoFocus={key === '0'}
              key={key}
              state={error ? 'INVALID' : 'DEFAULT'}
              onChange={(event) => {
                onChange(key, event);
              }}
              onKeyDown={(event) => {
                onKeyDown(key, event);
              }}
              onKeyUp={(event) => {
                onKeyUp(event);
              }}
              onFocus={() => {
                setError(undefined);
              }}
              ref={(el) => {
                if (el) {
                  pinBoxRefs.current[parseInt(key)] = el;
                }
              }}
              value={userCodeValues[key]}
            />
          ))}
        </StyledPinContainer>
        {!!error && <StyledError>{t(error)}</StyledError>}
        <StyledButton disabled={userCode.length !== 6} isLoading={busy}>
          {t('COMMON_BUTTON_PROCEED')}
        </StyledButton>
      </StyledForm>
    </StyledContainer>
  );
};
