import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Input, mq } from '@telia-company/tv.oneapp-web-ui';
import { ComponentProps } from 'react';
import { textSecondary } from 'theme/theme.utils';

export const StyledPinContainer = styled.div`
  margin: 0;
  padding: 0;
  list-style: none;
  display: grid;
  gap: 6px;
  grid-template-columns: repeat(auto-fit, minmax(0, 1fr));

  ${mq.M`
      gap: 8px;
    `}

  > li {
    flex-grow: 0;
    flex-shrink: 0;
  }
`;

export const StyledInput = styled.input<{ state: ComponentProps<typeof Input>['state'] }>`
  width: 100%;
  height: 48px;

  padding: 0;
  text-align: center;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.designTokens.color.border.subtle};
  background: ${({ theme }) => theme.designTokens.color.button.secondaryDefault};
  color: ${({ theme }) => theme.designTokens.color.text.primary};
  font-weight: 700;
  font-size: 36px;
  line-height: 36px;

  ${mq.S`
    height: 64px;
    font-size: 40px;
    font-size: 40px;
  `}

  ${({ theme, state }) => {
    switch (state) {
      case 'DEFAULT':
        return css`
          border-color: ${theme.designTokens.color.border.backgroundSurface};
        `;
      case 'INVALID':
        return css`
          border-color: ${theme.designTokens.color.infoDialogue.errorBackgroundDefault};
        `;
      case 'VALID':
        return css`
          border-color: ${theme.designTokens.color.infoDialogue.helpfulBackgroundDefault};
        `;
    }
  }}

  &:focus {
    background: ${({ theme }) => theme.designTokens.color.button.secondaryFocus};
    border-color: ${({ theme }) => theme.designTokens.color.border.buttonSecondaryFocus};
  }
`;

export const StyledMessage = styled.p<{ state: ComponentProps<typeof Input>['state'] }>`
  user-select: none;
  padding: 8px 0;
  margin: 0;
  ${({ theme }) => textSecondary(theme)}
  ${({ theme, state }) => {
    switch (state) {
      case 'DEFAULT':
        return css`
          color: ${theme.designTokens.color.text.tertiary};
        `;
      case 'INVALID':
        return css`
          color: ${theme.designTokens.color.infoDialogue.errorBackgroundDefault};
        `;
      case 'VALID':
        return css`
          color: ${theme.designTokens.color.infoDialogue.helpfulBackgroundDefault};
        `;
    }
  }}
`;
