import { StyledSectionHeading } from 'pages/navigation.styles';
import { useTranslation } from 'react-i18next';
import { ErrorComponent, SectionSpinner } from 'components';
import { PurchaseHistoryList } from './components/PurchaseHistoryList';
import { useFetchFunction } from 'hooks/useFetchFunction';
import { getMediaAccessHistory } from 'services/purchaseHistoryService';
import { useEffect, useId } from 'react';
import { ServiceMessages } from '@telia-company/tv.common-sdk/dist/ServiceMessages';

export const PurchaseHistory = () => {
  const { t } = useTranslation();
  const {
    loading,
    error,
    data: mediaAccessHistory,
    refresh: refreshMediaAccessHistory,
  } = useFetchFunction(getMediaAccessHistory);

  const baseId = useId();

  const headingId = `${baseId}-section-heading`;

  useEffect(() => {
    const estListener = ServiceMessages.statics.events.est.on(() => {
      refreshMediaAccessHistory();
    });
    const rentalListener = ServiceMessages.statics.events.rental.on(() => {
      refreshMediaAccessHistory();
    });

    return () => {
      ServiceMessages.statics.events.est.off(estListener);
      ServiceMessages.statics.events.rental.off(rentalListener);
    };
  }, [refreshMediaAccessHistory]);

  if (error) <ErrorComponent error={error} />;
  if (loading) <SectionSpinner />;
  if (!mediaAccessHistory?.length) return null;

  return (
    <section aria-labelledby={headingId}>
      <StyledSectionHeading id={headingId}>
        {t('SUBSCRIPTION_PURCHASE_HISTORY_HEADING')}
      </StyledSectionHeading>
      <PurchaseHistoryList items={mediaAccessHistory} />
    </section>
  );
};
