import * as Dialog from '@radix-ui/react-dialog';
import { ComponentProps, ReactNode } from 'react';
import {
  StyledDialogButtonStrip,
  StyledDialogCloseIconButton,
  StyledDialogContent,
  StyledDialogOverlay,
  StyledDialogTitle,
} from './RadixModal.styles';
import { Icon } from '@telia-company/tv.oneapp-web-ui';
import { useTranslation } from 'react-i18next';

type RadixModalProps = ComponentProps<typeof Dialog.Root>;

export const RadixModal = ({ children, ...rest }: RadixModalProps) => {
  return (
    <Dialog.Root modal={true} {...rest}>
      {children}
    </Dialog.Root>
  );
};

const RadixModalTrigger = ({ children, asChild = true, ...rest }: Dialog.DialogTriggerProps) => {
  return (
    <Dialog.Trigger {...rest} asChild={asChild}>
      {children}
    </Dialog.Trigger>
  );
};

const defaultOnPointerDownOutside: ComponentProps<typeof Dialog.Content>['onPointerDownOutside'] = (
  event,
) => {
  const withinSnackbar = !!(
    event.target instanceof Element && event.target.closest('[data-snackbar]')
  );
  if (withinSnackbar) {
    event.preventDefault();
  }
};

type RadixModalContentProps = {} & Dialog.DialogContentProps;

const RadixModalContent = ({
  children,
  onPointerDownOutside,
  title,
  ...rest
}: RadixModalContentProps) => {
  const { t } = useTranslation();

  return (
    <Dialog.Portal>
      <StyledDialogOverlay />
      <StyledDialogContent
        title={title}
        onPointerDownOutside={onPointerDownOutside ?? defaultOnPointerDownOutside}
        aria-modal={true}
        {...rest}
      >
        {!!title && <StyledDialogTitle>{title}</StyledDialogTitle>}
        {children}
        <StyledDialogCloseIconButton asChild>
          <button aria-label={t('CLOSE_DIALOG')}>
            <Icon name="Close" width="24px" />
          </button>
        </StyledDialogCloseIconButton>
      </StyledDialogContent>
    </Dialog.Portal>
  );
};

const RadixModalButtonStrip = ({
  className,
  children,
}: {
  className?: string;
  children?: ReactNode;
}) => {
  return <StyledDialogButtonStrip className={className}>{children}</StyledDialogButtonStrip>;
};

RadixModal.Trigger = RadixModalTrigger;
RadixModal.Content = RadixModalContent;
RadixModal.Description = Dialog.Description;
RadixModal.Close = Dialog.Close;
RadixModal.ButtonStrip = RadixModalButtonStrip;
