import { Config } from '@telia-company/tv.common-sdk/dist/Config';
import { Button } from '@telia-company/tv.oneapp-web-ui';
import { SmartLink } from 'components';
import { useLogger } from 'hooks';
import {
  StyledButtonContainer,
  StyledSubSection,
  StyledSubSectionLabel,
} from 'pages/navigation.styles';
import { useTranslation } from 'react-i18next';

export const NoSubscription = () => {
  const logger = useLogger('NoSubscription');
  const { t } = useTranslation();

  const manageSubscriptionLink = Config.get('telia-manage-subscription-link');

  if (!manageSubscriptionLink) {
    logger.error('No manage subscription link is configured');
  }

  return (
    <StyledSubSection>
      <div>
        <StyledSubSectionLabel>{t('SUBSCRIPTION_NO_SUBSCRIPTION_LABEL')}</StyledSubSectionLabel>
      </div>
      {manageSubscriptionLink && (
        <StyledButtonContainer>
          <Button as={SmartLink} variant="secondary" to={manageSubscriptionLink}>
            {t('SUBSCRIPTION_BUTTON_CHOOSE_SUBSCRIPTION_TELIA')}
          </Button>
        </StyledButtonContainer>
      )}
    </StyledSubSection>
  );
};
