import { useLogger, useSnackbars } from 'hooks';
import { useTranslation } from 'react-i18next';
import { MfaStatus, removeMfa, requestMfaOneTimePassword } from 'services/userService';
import { ModalVerifyMfa } from './ModalVerifyMfa';
import { RadixModal } from 'components/RadixModal';
import { ComponentProps, FormEventHandler, useCallback, useState } from 'react';
import { InvalidPasswordError } from 'errorTypes';
import { StyledModalContent } from './ModalContentRemoveMfa.styles';
import { Button } from '@telia-company/tv.oneapp-web-ui';

type ModalContentRemoveMfaProps = {
  mfaStatus: MfaStatus;
  onRemoved: () => void;
};

export const RadixModalContentRemoveMfa = ({
  mfaStatus,
  onRemoved,
}: ModalContentRemoveMfaProps) => {
  const logger = useLogger('RadixModalContentRemoveMfa');
  const [verifyModalOpen, setVerifyModalOpen] = useState(false);
  const [busy, setBusy] = useState(false);
  const { createNegativeSnackbar } = useSnackbars();
  const { t } = useTranslation();

  let title = '';
  let description = '';
  let confirmLabel = '';
  switch (mfaStatus.verificationMethod) {
    case 'email':
      title = t('MFA_DELETE_MODAL_TITLE_EMAIL');
      description = t('MFA_DELETE_MODAL_DESCRIPTION_EMAIL');
      confirmLabel = t('MFA_DELETE_MODAL_CONFIRM_EMAIL');
      break;
    case 'sms':
      title = t('MFA_DELETE_MODAL_TITLE_SMS');
      description = t('MFA_DELETE_MODAL_DESCRIPTION_SMS');
      confirmLabel = t('MFA_DELETE_MODAL_CONFIRM_SMS');
      break;
    default:
      // generate compile time error if more verification methods are added
      mfaStatus.verificationMethod satisfies never;
  }

  const onVerifyOneTimePassword = useCallback<ComponentProps<typeof ModalVerifyMfa>['onVerify']>(
    async (oneTimePassword: string) => {
      setBusy(true);
      try {
        await removeMfa({ oneTimePassword });
        onRemoved();
        return { success: true };
      } catch (error) {
        if (error instanceof InvalidPasswordError) {
          return { success: false, errorMessage: t('MFA_VERIFY_MODAL_WRONG_ONE_TIME_PASSWORD') };
        }
        logger.error('Failed to verify MFA', error);
        return { success: false, errorMessage: t('ERROR_GENERIC_INFORMATION') };
      } finally {
        setBusy(false);
      }
    },
    [logger, onRemoved, t],
  );

  const onSubmit = useCallback<FormEventHandler<HTMLFormElement>>(
    async (e) => {
      e.preventDefault();
      setBusy(true);
      try {
        await requestMfaOneTimePassword({
          value: mfaStatus.value,
          verificationMethod: mfaStatus.verificationMethod,
          action: 'remove',
        });
        setVerifyModalOpen(true);
      } catch (error) {
        createNegativeSnackbar(t('ERROR_GENERIC_INFORMATION'));
        logger.error('Failed to request one time password for removal of MFA', error);
      } finally {
        setBusy(false);
      }
    },
    [createNegativeSnackbar, logger, mfaStatus.value, mfaStatus.verificationMethod, t],
  );

  return (
    <>
      <StyledModalContent title={title}>
        <RadixModal.Description>{description}</RadixModal.Description>
        <form onSubmit={onSubmit}>
          <RadixModal.ButtonStrip>
            <RadixModal.Close asChild>
              <Button variant="secondary">{t('COMMON_BUTTON_CANCEL')}</Button>
            </RadixModal.Close>
            <Button type="submit" variant="primary" isLoading={busy} disabled={busy}>
              {confirmLabel}
            </Button>
          </RadixModal.ButtonStrip>
        </form>
      </StyledModalContent>
      <ModalVerifyMfa
        verificationMethod={mfaStatus.verificationMethod}
        verificationTarget={mfaStatus.value}
        open={verifyModalOpen}
        onOpenChange={setVerifyModalOpen}
        onVerify={onVerifyOneTimePassword}
      />
    </>
  );
};
