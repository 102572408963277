import { UserSubscription, UserSubscriptionService } from 'services/userService';
import {
  StyledService,
  StyledServiceDescription,
  StyledServiceIcon,
  StyledServiceIconPlaceholder,
  StyledServiceInfoContainer,
  StyledServiceName,
  StyledServiceNameAndDescriptionContainer,
} from './VASItem.styles';
import { useTranslation } from 'react-i18next';
import { VASActivation } from './VASActivation';

type VASItemProps = {
  userSubscription: UserSubscription;
  service: UserSubscriptionService;
};

export const VASItem = ({
  userSubscription,
  service: { iconUrl, name, activationUrl },
}: VASItemProps) => {
  const { t } = useTranslation();
  return (
    <StyledService>
      <StyledServiceInfoContainer>
        {iconUrl ? <StyledServiceIcon src={iconUrl} alt="" /> : <StyledServiceIconPlaceholder />}
        <StyledServiceNameAndDescriptionContainer>
          <StyledServiceName>{name}</StyledServiceName>
          <StyledServiceDescription>
            {t('SUBSCRIPTION_VAS_INFORMATION', { name: userSubscription.name })}
          </StyledServiceDescription>
        </StyledServiceNameAndDescriptionContainer>
      </StyledServiceInfoContainer>
      <VASActivation activationUrl={activationUrl} />
    </StyledService>
  );
};
