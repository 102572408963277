import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { assumeProfile, User } from 'services/userService';
import { oneAppUrl } from 'utils';
import { MenuItem } from './useGetMenuItems';
import { isFeatureEnabled } from 'featureToggles';
import { shouldUseEnvironmentsProviderWithCookies } from 'providers/utils';
import { logoutWithEnvironmentSelectorEnabled } from '../components/utils/logoutWithEnvironmentSelectorEnabled';
import { logout } from '../components/utils/logout';
import { Config } from '@telia-company/tv.common-sdk/dist/Config';

export type MenuItemGroup = {
  menuItems: MenuItem[];
};

type UseGetUserMenuItemsProps = {
  user?: User;
  forMobileView: boolean;
};

export const useGetUserMenuItemsGroups = ({ user, forMobileView }: UseGetUserMenuItemsProps) => {
  const { t } = useTranslation();

  const logoutMenuItem = useMemo<MenuItem | undefined>(() => {
    return (
      user && {
        id: 'logout',
        label: t('HEADER_USERMENU_LOGOUT'),
        url: '/', // not used, since we handle the logout with onClick
        info: user.username,
        onClick: () => {
          if (shouldUseEnvironmentsProviderWithCookies()) {
            logoutWithEnvironmentSelectorEnabled();
          } else {
            logout();
          }
        },
      }
    );
  }, [t, user]);

  const profileMenuItems: MenuItem[] = useMemo(() => {
    const otherProfiles =
      user?.profiles.filter((profile) => profile.id !== user.currentProfileId) ?? [];

    return (
      otherProfiles.map((profile) => ({
        id: profile.id,
        imageUrl: profile.avatar.url,
        label: profile.alias,
        ariaLabel: t('CHANGE_TO_PROFILE', { profile: profile.alias }),
        url: '',
        onClick: (e) => {
          e.preventDefault();
          assumeProfile({
            profileId: profile.id,
          });
        },
      })) ?? []
    );
  }, [t, user?.currentProfileId, user?.profiles]);

  const userMenuItemGroups = useMemo<MenuItemGroup[] | undefined>(() => {
    if (!user) {
      return undefined;
    }

    return [
      {
        menuItems: [
          ...profileMenuItems,
          ...(user.showSelectionMenuItem
            ? [
                {
                  id: 'selection-menu',
                  label: t('HEADER_USERMENU_SELECTION_MENU'),
                  url: '/subscription/selection-menu',
                },
              ]
            : []),
          {
            id: 'my-account',
            label: t('HEADER_USERMENU_MY_ACCOUNT'),
            url: '/account',
          },
          {
            id: 'edit-profiles',
            label: t('HEADER_USERMENU_EDIT_PROFILES_TELIA'),
            url: oneAppUrl('/profiles/edit'),
          },
          {
            id: 'my-content',
            label: t('HEADER_USERMENU_MY_CONTENT'),
            url: oneAppUrl('/my-content'),
          },
          ...(isFeatureEnabled(['Help.ShowFeedback'])
            ? [
                {
                  id: 'feedback',
                  label: t('HEADER_USERMENU_FEEDBACK'),
                  url: oneAppUrl('/feedback'),
                },
              ]
            : []),
          ...(isFeatureEnabled(['SupportWeb'])
            ? [
                {
                  id: 'support-web',
                  label: t('SUPPORT_WEB_MENU_ITEM'),
                  url: Config.get('support-web-url'),
                },
              ]
            : []),
          ...(forMobileView && logoutMenuItem ? [logoutMenuItem] : []),
        ],
      },
      // For desktop view, we want to show the logout button in a separate group (to align with logged in web)
      ...(!forMobileView && logoutMenuItem ? [{ menuItems: [logoutMenuItem] }] : []),
    ];
  }, [forMobileView, logoutMenuItem, profileMenuItems, t, user]);

  return { userMenuItemGroups };
};
