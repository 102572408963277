import styled from '@emotion/styled';
import { Chevron } from 'components';
import { textPrimary } from 'theme/theme.utils';
import { Button } from '@telia-company/tv.oneapp-web-ui';
import * as Select from '@radix-ui/react-select';

export const StyledDropDownContainer = styled.div``;

export const StyledChevron = styled(Chevron)`
  path {
    stroke: ${({ theme }) => theme.designTokens.color.text.secondary};
  }
`;

export const StyledSelectTrigger = styled(Select.Trigger)`
  display: inline-flex;
  align-items: center;
  justify-content: center;
`;

export const StyledTriggerButton = styled(Button)`
  ${({ theme }) => textPrimary(theme)}
  color: ${({ theme }) => theme.designTokens.color.text.secondary};
  > div {
    display: flex;
    gap: 8px;
  }
`;

export const StyledSelectContent = styled(Select.Content)`
  z-index: 3;
  ${({ theme }) => textPrimary(theme)}
  background: ${({ theme }) => theme.designTokens.color.background.tertiary};
  filter: drop-shadow(0px 2px 40px rgba(0, 0, 0, 0.5));
  border-radius: 6px;
  overflow: hidden;
`;

export const StyledSelectViewport = styled(Select.Viewport)`
  padding: 8px;
`;

export const StyledSelectItem = styled(Select.Item)`
  cursor: pointer;
  ${({ theme }) => textPrimary(theme)}
  color: ${({ theme }) => theme.designTokens.color.text.primary};
  display: block;
  padding: 8px 16px;
  border-radius: 6px;
  white-space: nowrap;

  &[data-state='checked'],
  &[data-highlighted],
  &:hover {
    background: ${({ theme }) => theme.designTokens.color.button.ghostHover};
  }

  &:hover {
    background: ${({ theme }) => theme.designTokens.color.button.ghostHover};
  }
`;

export const StyledSelectScrollUpButton = styled(Select.ScrollUpButton)`
  display: grid;
  place-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
  background: ${({ theme }) =>
    `linear-gradient(to bottom, ${theme.designTokens.color.background.tertiary} 50%, rgba(0, 0, 0, 0) 100%)`};
`;

export const StyledSelectScrollDownButton = styled(Select.ScrollDownButton)`
  display: grid;
  place-items: center;
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  z-index: 1;
  background: ${({ theme }) =>
    `linear-gradient(to top, ${theme.designTokens.color.background.tertiary} 50%, rgba(0, 0, 0, 0) 100%)`};
`;
