import { MfaStatus } from 'services/userService';
import { getValidEmailAddress } from './getValidEmailAddress.utils';
import { getValidPhoneNumber } from './getValidPhoneNumber.utils';
import { country } from 'environment';

export const getValidatedVerificationValue = (
  value: string,
  verificationMethod: MfaStatus['verificationMethod'],
) => {
  switch (verificationMethod) {
    case 'email':
      return getValidEmailAddress(value);
    case 'sms':
      return getValidPhoneNumber(value, country);
    default:
      // generate compile time error if more verification methods are added
      verificationMethod satisfies never;
  }
};
