import { Config } from '@telia-company/tv.common-sdk/dist/Config';
import { Icon } from '@telia-company/tv.oneapp-web-ui';
import { StyledSearchLink } from './SearchLink.styles';
import { useTranslation } from 'react-i18next';

type SearchLinkProps = {
  className?: string;
};

export const SearchLink = ({ className }: SearchLinkProps) => {
  const { t } = useTranslation();
  const searchIcon = Icon({ name: 'Search', 'aria-hidden': true });
  return (
    /* @a11y - this is a link, not a button as it is in oneapp. using role="button" seems not to be recommended, so keeping it as it is */
    <StyledSearchLink
      aria-label={t('SEARCH')}
      className={className}
      href={`${Config.get('one-app-base-url')}/search`}
    >
      {searchIcon}
    </StyledSearchLink>
  );
};
