import { useEffect } from 'react';
import { useGetUser, useLogger, useMediaQueryMinSize as useMediaQueryMinWidth } from 'hooks';
import { HeaderViewLarge } from './components';
import { useGetMenuItems, useGetUserMenuItemsGroups } from './hooks';
import { HeaderViewSmall } from './components/HeaderViewSmall/HeaderViewSmall';
import { SkipLink } from 'components/SkipLink/SkipLink';
import { useHeaderSkipLinkContext } from 'components/SkipLink/HeaderSkipLinkContext';

export const Header = () => {
  const logger = useLogger('components/Header');
  const isLargeWidth = useMediaQueryMinWidth('L');

  const { menuItems, error: menuItemsError } = useGetMenuItems();
  const { user, error: userError } = useGetUser();
  const { userMenuItemGroups } = useGetUserMenuItemsGroups({ user, forMobileView: !isLargeWidth });
  const { skipLinkTargetId, skipLinkContent } = useHeaderSkipLinkContext();

  useEffect(() => {
    if (userError) {
      logger.error('Failed to fetch user', userError);
    }
  }, [userError, logger]);

  useEffect(() => {
    if (menuItemsError) {
      logger.error('Failed to fetch menuItems', menuItemsError);
    }
  }, [menuItemsError, logger]);

  return (
    <>
      {skipLinkTargetId && skipLinkContent && (
        <SkipLink targetId={skipLinkTargetId}>{skipLinkContent}</SkipLink>
      )}
      {isLargeWidth ? (
        <HeaderViewLarge
          menuItems={menuItems}
          user={user}
          userMenuItemGroups={userMenuItemGroups}
        />
      ) : (
        <HeaderViewSmall
          menuItems={menuItems}
          user={user}
          userMenuItemGroups={userMenuItemGroups}
        />
      )}
    </>
  );
};
