import { Config } from '@telia-company/tv.common-sdk/dist/Config';
import { Button } from '@telia-company/tv.oneapp-web-ui';
import { SmartLink } from 'components';
import { useLogger } from 'hooks';
import {
  StyledButtonContainer,
  StyledSectionHeading,
  StyledSubSection,
  StyledSubSectionContainer,
} from 'pages/navigation.styles';
import { useTranslation } from 'react-i18next';
import { StyledPaymentMethod } from './Payment.styles';
import { useId } from 'react';

export const Payment = () => {
  var { t } = useTranslation();
  var logger = useLogger('Payment');
  const baseId = useId();

  const headingId = `${baseId}-section-heading`;

  const managePaymentLink = Config.get('telia-manage-payment-link');

  if (!managePaymentLink) {
    logger.error('No link to manage payment configured');
  }

  return (
    <section aria-labelledby={headingId}>
      <StyledSectionHeading id={headingId}>
        {t('SUBSCRIPTION_PAYMENT_HEADING')}
      </StyledSectionHeading>
      <StyledSubSectionContainer>
        <StyledSubSection>
          <StyledPaymentMethod>{t('SUBSCRIPTION_PAYMENT_INVOICE')}</StyledPaymentMethod>
          {!!managePaymentLink && (
            <StyledButtonContainer>
              <Button as={SmartLink} variant="secondary" to={managePaymentLink}>
                {t('SUBSCRIPTION_PAYMENT_BUTTON_MANAGE_TELIA')}
              </Button>
            </StyledButtonContainer>
          )}
        </StyledSubSection>
      </StyledSubSectionContainer>
    </section>
  );
};
