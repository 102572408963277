import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { m } from 'framer-motion';
import { NavLink } from 'react-router-dom';
import { textPrimary, textSecondary } from 'theme/theme.utils';

export const StyledNavigationContainer = styled.div`
  position: relative;
  margin: 0 16px;
  z-index: 1;
`;

export const StyledSectionIcon = styled.div`
  width: 24px;
  height: 24px;
  margin-right: 8px;

  & > svg {
    width: 100%;
  }
`;

export const StyledNavigationSelect = styled.button`
  ${({ theme }) => css`
    color: #fff;
    border: 0;
    display: flex;
    align-items: center;
    padding: 16px;
    border-radius: 2px;
    background-color: ${theme.designTokens.color.background.secondary};
    height: 56px;
    cursor: pointer;
    justify-content: space-between;
    width: 100%;

    ${textPrimary(theme)}
  `}
`;

export const StyledSectionLabel = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  > div {
    display: flex;
    align-items: center;
  }
`;

export const StyledSectionListContainer = styled(m.ul)`
  ${({ theme }) => css`
    list-style: none;
    margin: 0;
    padding: 0;
    position: absolute;
    left: 0px;
    top: 58px;
    width: 100%;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.25);
    background-color: ${theme.designTokens.color.background.secondary};
  `}
`;

export const StyledSectionLink = styled(NavLink)`
  ${({ theme }) => css`
    margin: 16px;
    display: block;
    ${textSecondary(theme)}
    color: ${theme.designTokens.color.text.secondary};

    svg {
      width: 16px;
      height: 16px;
    }
  `}
`;

export const StyledBackground = styled(m.div)`
  position: fixed;
  z-index: -1;
  top: 56px;
  margin-left: -16px;
  width: 100vw;
  bottom: 0px;
  overflow-y: scroll;
  background-color: ${({ theme }) => theme.designTokens.color.background.overlay60};
`;
