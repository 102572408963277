import { useEffect, useState } from 'react';
import { UpdatePinCodeDone, UpdatePinCodeForm } from './components';
import { useHeaderSkipLinkContext } from 'components/SkipLink/HeaderSkipLinkContext';

export const UpdatePinCode = () => {
  const [pinCodeUpdated, setPinCodeUpdated] = useState(false);
  const { update } = useHeaderSkipLinkContext();

  const onPinCodeUpdated = () => {
    setPinCodeUpdated(true);
  };

  useEffect(() => {
    update({ skipLinkTargetId: 'main-content', skipLinkContent: 'Skip to main content' });
  }, [update]);

  return (
    <main id="main-content">
      {pinCodeUpdated ? <UpdatePinCodeDone /> : <UpdatePinCodeForm onSuccess={onPinCodeUpdated} />}
    </main>
  );
};
