import { useTranslation } from 'react-i18next';
import {
  isActiveRental,
  getMediaUrl,
  getReadableDistance,
  getReadableAccessType,
  getReadableRentedOrBoughtOn,
} from './PurchaseHistoryItem.utils';
import { MediaAccessHistoryItem } from 'services/backend/purchaseGateway';
import {
  StyledPurchaseHistoryItem,
  StyledPurchaseHistoryItemHeading,
  StyledPurchaseHistoryItemInfo,
  StyledPurchaseHistoryItemInfoWrapper,
  StyledPurchaseHistoryItemPrice,
  StyledPurchaseHistoryItemWrapper,
} from './PurchaseHistoryItem.styles';

type PurchaseHistoryItemProps = {
  item: MediaAccessHistoryItem;
};

export const PurchaseHistoryItem = ({ item }: PurchaseHistoryItemProps) => {
  const { t } = useTranslation();

  const readableAccessType = getReadableAccessType(item, t);
  const readableRentedOrBoughtOn = getReadableRentedOrBoughtOn(item, t);

  return (
    <StyledPurchaseHistoryItem>
      <a href={getMediaUrl(item)}>
        <StyledPurchaseHistoryItemWrapper>
          <StyledPurchaseHistoryItemHeading>{`${readableAccessType} - ${item.title}`}</StyledPurchaseHistoryItemHeading>
          <StyledPurchaseHistoryItemPrice>{item.localizedPricePaid}</StyledPurchaseHistoryItemPrice>
        </StyledPurchaseHistoryItemWrapper>
        <StyledPurchaseHistoryItemInfoWrapper>
          <StyledPurchaseHistoryItemInfo>{readableRentedOrBoughtOn}</StyledPurchaseHistoryItemInfo>
          {isActiveRental(item) && (
            <StyledPurchaseHistoryItemInfo>
              {`${t('SUBSCRIPTION_PURCHASE_HISTORY_RENTAL_AVAILABLE')} ${getReadableDistance(
                item.endTime,
              )}`}
            </StyledPurchaseHistoryItemInfo>
          )}
        </StyledPurchaseHistoryItemInfoWrapper>
      </a>
    </StyledPurchaseHistoryItem>
  );
};
