import styled from '@emotion/styled';
import * as Dialog from '@radix-ui/react-dialog';
import { pxToRem } from '@telia-company/tv.oneapp-web-ui';
import { headingSecondary, textPrimary } from 'theme/theme.utils';

export const StyledDialogOverlay = styled(Dialog.Overlay)`
  @keyframes overlayShow {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  position: fixed;
  inset: 0;
  background: ${({ theme }) => theme.designTokens.color.background.overlay60};
  z-index: 100;
  animation: overlayShow 0.1s ease-in forwards;
`;

export const StyledDialogContent = styled(Dialog.Content)`
  @keyframes contentShow {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: ${({ theme }) => theme.designTokens.color.background.tertiary};
  border-radius: 6px;
  padding: ${pxToRem(24)}rem;
  overflow-y: auto;
  z-index: 100;
  animation: contentShow 0.2s ease-in forwards;
  ${({ theme }) => textPrimary(theme)}
`;

export const StyledDialogTitle = styled(Dialog.Title)`
  ${({ theme }) => headingSecondary(theme)}
  color: #fff;
  margin-top: 0;
  margin-right: 48px;
`;

export const StyledDialogCloseIconButton = styled(Dialog.Close)`
  all: unset;
  position: absolute;
  top: 24px;
  right: 24px;
  cursor: pointer;

  > button > svg {
    position: absolute;
    top: 0;
    left: 0;
  }
`;

export const StyledDialogButtonStrip = styled.div`
  display: flex;
  justify-content: end;
  gap: ${pxToRem(16)}rem;
  margin-top: ${pxToRem(24)}rem;
`;
