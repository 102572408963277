import { StyledSubSection, StyledSubSectionContainer } from 'pages/navigation.styles';
import { useTranslation } from 'react-i18next';
import { StyledErrorMessage, StyledErrorContainer, StyledIcon } from './ErrorSubSection.styles';

export const ErrorSubSection = () => {
  const { t } = useTranslation();
  return (
    <StyledSubSectionContainer>
      <StyledSubSection>
        <StyledErrorContainer>
          <StyledIcon name="Warning" aria-hidden={true} />
          <StyledErrorMessage>{t('ERROR_GENERIC_INFORMATION')}</StyledErrorMessage>
        </StyledErrorContainer>
      </StyledSubSection>
    </StyledSubSectionContainer>
  );
};
