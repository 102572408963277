import styled from '@emotion/styled';
import { textPrimary } from 'theme/theme.utils';

export const StyledServicesContainer = styled.div`
  margin-top: 16px;
  margin-bottom: 24px;
`;

export const StyledServicesHeader = styled.div`
  ${({ theme }) => textPrimary(theme)}
  color: ${({ theme }) => theme.designTokens.color.text.primary};
  margin-bottom: -8px; // compensate with the margin-top for each service
`;

export const StyledServicesList = styled.ul`
  list-style: none;
  padding: 0;
`;
