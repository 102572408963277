import React from 'react';

type AppleIconProps = React.SVGProps<SVGSVGElement>;

export const AppleIcon = (props: AppleIconProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" id="body_1" width="20" height="24" {...props}>
    <g transform="matrix(0.023752969 0 0 0.024 0 0)">
      <path
        d="M824.6664 779.30365C 809.5434 814.2409 791.6427 846.4004 770.9026 875.9674C 742.63184 916.2748 719.4844 944.17523 701.6454 959.6686C 673.99194 985.09985 644.36316 998.1242 612.63574 998.86487C 589.85864 998.86487 562.3904 992.38354 530.416 979.23584C 498.33676 966.1497 468.85617 959.6685 441.90018 959.6685C 413.62943 959.6685 383.30936 966.1497 350.87823 979.23584C 318.3977 992.38354 292.23184 999.2352 272.22626 999.91425C 241.80124 1001.21045 211.47504 987.81573 181.20433 959.6685C 161.88394 942.81714 137.71802 913.92914 108.76826 873.0044C 77.70748 829.302 52.171215 778.6246 32.16565 720.8485C 10.740416 658.4431 0 598.0128 0 539.5084C 0 472.49194 14.481044 414.69122 43.486336 366.2544C 66.28194 327.34824 96.60818 296.6578 134.5638 274.1276C 172.51945 251.59743 213.53055 240.1163 257.6959 239.38174C 281.86182 239.38174 313.55222 246.85683 352.93372 261.54773C 392.20413 276.28802 417.41943 283.76312 428.4746 283.76312C 436.73978 283.76312 464.75128 275.0226 512.2375 257.59726C 557.14355 241.43726 595.04364 234.74608 626.09204 237.3818C 710.2253 244.17172 773.4332 277.3374 815.469 337.08868C 740.22437 382.6799 703.0033 446.53598 703.74396 528.45325C 704.423 592.25995 727.5704 645.35706 773.06287 687.51636C 793.6795 707.0836 816.70355 722.2066 842.31995 732.9471C 836.76465 749.0577 830.90063 764.4893 824.6662 779.3037L824.6662 779.3037L824.6664 779.30365zM631.7093 20.0057C 631.7093 70.017105 613.43823 116.71263 577.0196 159.93353C 533.07025 211.31471 479.91144 241.00516 422.265 236.32013C 421.5305 230.3203 421.10455 224.00569 421.10455 217.3701C 421.10455 169.35919 442.00516 117.978035 479.12134 75.966965C 497.6516 54.696022 521.2188 37.009525 549.7982 22.900665C 578.3158 9.00229 605.2903 1.31621 630.65985 0C 631.40063 6.68575 631.7092 13.37191 631.7092 20.00505z"
        stroke="none"
        fill="#FFFFFF"
        fillRule="nonzero"
      />
    </g>
  </svg>
);
