import { Icon } from '@telia-company/tv.oneapp-web-ui';
import { useLogger } from 'hooks';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { Fullscreen } from 'components';
import {
  StyledButton,
  StyledDescription,
  StyledHeading,
  StyledIconWrapper,
  StyledPageNotFoundWrapper,
  StyledViewWrapper,
} from './PageNotFound.styles';
import { useHeaderSkipLinkContext } from 'components/SkipLink/HeaderSkipLinkContext';

type PageNotFoundProps = {
  retry?: () => void;
};
export const PageNotFound = ({ retry }: PageNotFoundProps) => {
  const logger = useLogger('PageNotFound');
  const location = useLocation();
  const locationRef = useRef(location);
  const { t } = useTranslation();
  const { update } = useHeaderSkipLinkContext();

  const mainContentId = 'main-content';

  useEffect(() => {
    if (retry) {
      if (locationRef.current !== location) {
        retry();
        locationRef.current = location;
      }
    }
  }, [location, locationRef, retry]);

  useEffect(() => {
    update({ skipLinkTargetId: mainContentId, skipLinkContent: t('SKIP_TO_MAIN_CONTENT') });
  }, [t, update]);

  logger.error(`User tried to access ${JSON.stringify(location)}`);

  return (
    <Fullscreen>
      <StyledPageNotFoundWrapper id={mainContentId}>
        <StyledViewWrapper data-testid="notFound">
          <StyledIconWrapper>
            <Icon name="Info" />
          </StyledIconWrapper>
          <StyledHeading>{t('COMMON_ERROR_PAGE_NOT_FOUND_TITLE')}</StyledHeading>
          <StyledDescription>{t('COMMON_ERROR_PAGE_NOT_FOUND_DESCRIPTION')}</StyledDescription>
          <Link aria-label={t('COMMON_ERROR_PAGE_NOT_FOUND_TO_START_PAGE')} to="/">
            <StyledButton>{t('COMMON_ERROR_PAGE_NOT_FOUND_TO_START_PAGE')}</StyledButton>
          </Link>
        </StyledViewWrapper>
      </StyledPageNotFoundWrapper>
    </Fullscreen>
  );
};
