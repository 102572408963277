import {
  StyledSubSection,
  StyledSubSectionDefinitionDescription,
  StyledSubSectionDefinitionTerm,
} from 'pages/navigation.styles';
import { useTranslation } from 'react-i18next';
import { User } from 'services/userService';

type NameProps = {
  user?: User;
};

export const Name = ({ user }: NameProps) => {
  const { t } = useTranslation();
  return (
    <StyledSubSection>
      <div>
        <StyledSubSectionDefinitionTerm>
          {t('ACCOUNT_INFO_NAME_SECTION_HEADING')}
        </StyledSubSectionDefinitionTerm>
        <StyledSubSectionDefinitionDescription>
          {user ? user.name || t('COMMON_NOT_AVAILABLE') : '...'}
        </StyledSubSectionDefinitionDescription>
      </div>
    </StyledSubSection>
  );
};
