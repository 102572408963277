import { GoogleIcon } from 'components';
import { StyledFederationProviderTitle } from 'pages/federated.styles';
import {
  StyledButtonContainer,
  StyledSubSection,
  StyledSubSectionLabel,
} from 'pages/navigation.styles';
import { FC } from 'react';
import { LoggedinDevice } from 'services/deviceService';
import { GoogleButton } from './GoogleButton';
import { FeatureEnabled } from 'components/FeatureEnabled';

type GoogleProps = {
  isGoogleConnected: boolean;
  setIsGoogleConnected: (isConnected: boolean) => void;
  thisDevice?: LoggedinDevice;
};
export const Google: FC<GoogleProps> = ({
  isGoogleConnected,
  setIsGoogleConnected,
  thisDevice,
}) => {
  return (
    <FeatureEnabled featureToggles={['Account.Federated.Login.Google']}>
      <StyledSubSection>
        {/* Removing the label from accessibiliy, since the button says it all */}
        <StyledSubSectionLabel aria-hidden={true}>
          <GoogleIcon aria-hidden={true} />
          <StyledFederationProviderTitle>{'Google'}</StyledFederationProviderTitle>
        </StyledSubSectionLabel>
        <StyledButtonContainer>
          <GoogleButton
            isGoogleConnected={isGoogleConnected}
            setIsGoogleConnected={setIsGoogleConnected}
            thisDevice={thisDevice}
          />
        </StyledButtonContainer>
      </StyledSubSection>
    </FeatureEnabled>
  );
};
