import { Input } from '@telia-company/tv.oneapp-web-ui';
import { StyledMessage, StyledPinContainer, StyledInput } from './PinField.styles';
import {
  ClipboardEvent,
  ComponentProps,
  FocusEvent,
  FormEvent,
  useCallback,
  useEffect,
  useId,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

type PinFieldProps = {
  className?: string;
  length: number;
  masked?: boolean;
  onValueChanged: (value: string) => void;
  value: string;
  state?: ComponentProps<typeof Input>['state'];
  message?: string;
  autocomplete?: string;
};

export const PinField = ({
  length,
  className,
  masked,
  onValueChanged,
  value,
  state: fieldState,
  message: fieldMessage,
  autocomplete,
}: PinFieldProps) => {
  const { t } = useTranslation();
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);
  const baseId = useId();
  const messageId = `${baseId}-message`;
  const [pinDigitErrors, setPinDigitErrors] = useState<string[]>([]);
  const [firstInvalidIndex, setFirstInvalidIndex] = useState<number | null>(null);

  const emitOnValueChanged = useCallback(() => {
    const combinedValue = inputRefs.current
      .map((input) => input?.value || ' ')
      .join('')
      .trimEnd();
    onValueChanged?.(combinedValue);
  }, [onValueChanged]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const newErrors = [...pinDigitErrors];
    newErrors[index] = '';
    setPinDigitErrors(newErrors);

    const { value } = e.target;
    if (value.length === 1 && index < length - 1) {
      inputRefs.current[index + 1]?.focus();
    }

    emitOnValueChanged();
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>, index: number) => {
    if (e.key === 'Backspace' && index > 0 && !e.currentTarget.value) {
      inputRefs.current[index - 1]?.focus();
    }
  };

  const pasteValue = useCallback(
    (value: string) => {
      for (let i = 0; i < length; i++) {
        const inputRef = inputRefs.current[i];
        if (inputRef) {
          inputRef.value = (value[i] ?? '').trim();
        }
      }
      emitOnValueChanged();
      const lastPastedInput = inputRefs.current[value.length - 1];
      if (lastPastedInput) {
        lastPastedInput.focus();
        lastPastedInput.selectionStart = 1;
      }
    },
    [emitOnValueChanged, length],
  );

  const handlePaste = (e: ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault();
    pasteValue(e.clipboardData.getData('text'));
  };

  const handleBeforeInput = (e: FormEvent<HTMLInputElement>, index: number) => {
    const inputEvent = e.nativeEvent as InputEvent;

    if (!inputEvent.data) {
      return;
    }

    // Clear the input to allow for replacing the content with a single character
    if (inputEvent.data.length === 1) {
      const inputRef = inputRefs.current[index];
      if (inputRef) {
        inputRef.value = '';
      }
      return;
    }

    // We have a long string of data, so we need to prevent the default behavior and behave like we're
    // handling a paste event
    e.preventDefault();
    pasteValue(inputEvent.data);
  };

  const handleFocus = (event: FocusEvent<HTMLInputElement, Element>) => {
    event.target.select();
  };

  const handleInvalid = (e: FormEvent<HTMLInputElement>, index: number) => {
    e.preventDefault();
    if (firstInvalidIndex === null) {
      setFirstInvalidIndex(index);
    }
    const newDigitErrors = [...pinDigitErrors];
    newDigitErrors[index] = t('PIN_VALIDATION_ERROR_FORMAT');
    setPinDigitErrors(newDigitErrors);
  };

  // When the digit errors have been updated, focus on the first pin input that has an error
  useEffect(() => {
    if (firstInvalidIndex === null) {
      return;
    }
    inputRefs.current[firstInvalidIndex]?.focus();
    setFirstInvalidIndex(null);
  }, [firstInvalidIndex]);

  useEffect(() => {
    for (let i = 0; i < length; i++) {
      const inputRef = inputRefs.current[i];
      if (inputRef) {
        inputRef.value = (value?.[i] ?? '').trim();
      }
    }
  }, [length, pasteValue, value]);

  const firstDigitError = pinDigitErrors.find(Boolean);
  const state = !!firstDigitError ? 'INVALID' : fieldState;
  const message = firstDigitError ?? fieldMessage;

  return (
    <div className={className}>
      <StyledPinContainer>
        {Array.from({ length }).map((_, index) => (
          <div key={index}>
            <StyledInput
              key={index}
              state={pinDigitErrors[index] ? 'INVALID' : fieldState}
              autoComplete={autocomplete}
              type={masked ? 'password' : 'text'}
              inputMode="numeric"
              pattern="[0-9]"
              maxLength={1}
              ref={(el) => (inputRefs.current[index] = el)}
              onChange={(e) => handleChange(e, index)}
              onKeyDown={(e) => handleKeyDown(e, index)}
              onBeforeInput={(e) => handleBeforeInput(e, index)}
              onInvalid={(e) => handleInvalid(e, index)}
              onPaste={handlePaste}
              onFocus={handleFocus}
              aria-label={t('A11Y_ENTER_PIN_X_OF_Y', { index: index + 1, count: length })}
              aria-invalid={state === 'INVALID'}
              aria-describedby={messageId}
              required={true}
            />
          </div>
        ))}
      </StyledPinContainer>
      {message && (
        <StyledMessage id={messageId} state={state} aria-live="assertive" role="alert">
          {message}
        </StyledMessage>
      )}
    </div>
  );
};
