import { useTranslation } from 'react-i18next';
import { Settings } from './components';
import { StyledMainHeading } from '../navigation.styles';

export const SettingsPage = () => {
  const { t } = useTranslation();

  return (
    <>
      <StyledMainHeading>{t('SETTINGS_HEADING')}</StyledMainHeading>
      <Settings />
    </>
  );
};
