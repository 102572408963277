import {
  StyledSubSection,
  StyledSubSectionContainer,
  StyledSubSectionContent,
  StyledSubSectionLabel,
} from 'pages/navigation.styles';
import { useTranslation } from 'react-i18next';
import {
  StyledInfoContainer,
  StyledInputWrapper,
  StyledRequestPinButtonContainer,
} from './RequestPurchasePinCode.styles';

import { RequestPurchasePinCodeButton } from './RequestPurchasePinCodeButton';

export const RequestPurchasePinCode = () => {
  const { t } = useTranslation();
  return (
    <StyledSubSectionContainer>
      <StyledSubSection>
        <StyledInputWrapper>
          <StyledInfoContainer>
            <StyledSubSectionLabel>{t('SETTINGS_RENTAL_PIN_CODE')}</StyledSubSectionLabel>
            <StyledSubSectionContent>
              {t('SETTINGS_RENTAL_PIN_REQUEST_DESCRIPTION')}
            </StyledSubSectionContent>
          </StyledInfoContainer>
          <StyledRequestPinButtonContainer>
            <RequestPurchasePinCodeButton />
          </StyledRequestPinButtonContainer>
        </StyledInputWrapper>
      </StyledSubSection>
    </StyledSubSectionContainer>
  );
};
