import {
  StyledLogoHorizontal,
  StyledMenuItemsWrapper,
  StyledMenuItemWrapper,
  StyledNavigationWrapper,
  StyledUserAndSearchWrapper,
} from './HeaderViewLarge.styles';
import { MenuItem, MenuItemGroup } from '../../hooks';
import { UserDropDown } from './UserDropDown';
import { HeaderContainer } from '../HeaderContainer';
import { MenuItemLink } from './MenuItemLink';
import { User } from 'services/userService';
import { SearchLink } from '../SearchLink';
import { useTranslation } from 'react-i18next';

type HeaderViewLargeProps = {
  user?: User;
  menuItems: MenuItem[];
  userMenuItemGroups?: MenuItemGroup[];
};

export const HeaderViewLarge = ({ user, menuItems, userMenuItemGroups }: HeaderViewLargeProps) => {
  const { t } = useTranslation();
  return (
    <HeaderContainer>
      <StyledNavigationWrapper aria-label={t('MAIN_MENU')}>
        <StyledLogoHorizontal />
        <StyledMenuItemsWrapper role="list">
          {menuItems.map((menuItem, index) => (
            <StyledMenuItemWrapper key={index}>
              {/* none of the MenuItemLink will be the current page */}
              <MenuItemLink aria-current="false" menuItem={menuItem} />{' '}
            </StyledMenuItemWrapper>
          ))}
        </StyledMenuItemsWrapper>
        <StyledUserAndSearchWrapper>
          {user && userMenuItemGroups && (
            <UserDropDown user={user} menuItemGroups={userMenuItemGroups} />
          )}
          <SearchLink />
        </StyledUserAndSearchWrapper>
      </StyledNavigationWrapper>
    </HeaderContainer>
  );
};
