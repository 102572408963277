import styled from '@emotion/styled';
import { textSecondary } from 'theme/theme.utils';
import { mq } from '@telia-company/tv.oneapp-web-ui';

export const StyledBorder = styled.div`
  background-image: linear-gradient(to right, #c46cf2, #990ae3);
  background-clip: padding-box, border-box;
  background-origin: border-box;
  border: 2px solid transparent;
  border-radius: 6px;
`;

export const StyledContent = styled.div`
  position: relative;
  display: flex;
  padding: 16px;
  align-items: center;
  background-color: ${({ theme }) => theme.designTokens.color.background.tertiary};
  border-radius: 4px;
  color: transparent;
  margin: 2px;
`;

export const StyledInformationContainer = styled.div`
  width: 100%;
`;

export const StyledInformation = styled.div`
  ${({ theme }) => textSecondary(theme)}
  color: ${({ theme }) => theme.designTokens.color.text.secondary};
  ${mq.S`
    margin-left: 48px;
  `}
`;
