import styled from '@emotion/styled';
import { Button, mq } from '@telia-company/tv.oneapp-web-ui';
import { objectStyleToString } from 'utils/objectStyleToString.utils';

export const StyledPageNotFoundWrapper = styled.main`
  display: flex;
  justify-content: center;
  width: 100vw;
  margin: 2.5rem auto 5rem;
  padding: 0 2rem 0 2rem;
`;

export const StyledViewWrapper = styled.div`
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;

  ${mq.S`
    margin-bottom: 6.5rem;
    margin-top:4rem;
    max-width: 41ch;
    `}
`;

export const StyledHeading = styled.h1`
  margin-top: 1.5rem;
  ${({ theme }) => objectStyleToString(theme.designTokens.typography.headingTertiary)};
`;

export const StyledDescription = styled.p`
  ${({ theme }) => objectStyleToString(theme.designTokens.typography.textPrimary)};
  margin: 0;
  text-align: center;
`;

export const StyledButton = styled(Button)`
  margin-top: 2rem;
  min-width: 20rem;
`;

export const StyledIconWrapper = styled.span`
  border-radius: 50%;
  padding: 1rem;
  background-color: ${({ theme }) => theme.designTokens.color.button.secondaryDefault};

  & > svg {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    vertical-align: middle;
  }
`;
