import { useTranslation } from 'react-i18next';
import { StyledButtonContainer } from '../../../navigation.styles';
import { StyledActivateYourTvButton } from '../../HelpPage.styles';
import { Link } from 'react-router-dom';

export const ActivateYourTv = () => {
  const { t } = useTranslation();
  return (
    <StyledButtonContainer>
      <StyledActivateYourTvButton as={Link} to={'/login-device'} variant="primary">
        {t('HELP_BUTTON_ACTIVATE_YOUR_TV')}
      </StyledActivateYourTvButton>
    </StyledButtonContainer>
  );
};
