import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Button } from '@telia-company/tv.oneapp-web-ui';
import { StyledSubSectionList } from 'pages/navigation.styles';

export const StyledShowMoreButton = styled(Button)`
  display: flex;
  place-self: end;
`;

export const StyledPurchaseItemsList = styled(StyledSubSectionList)<{
  hasCollapsibleItems: boolean;
}>`
  overflow: hidden;
  ${({ hasCollapsibleItems }) =>
    hasCollapsibleItems &&
    css`
      margin-bottom: 14px;
    `}
`;
