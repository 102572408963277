import { Button } from '@telia-company/tv.oneapp-web-ui';
import { RadixModal } from 'components/RadixModal';
import { useLogger } from 'hooks';
import { useCallback, useEffect, useState } from 'react';
import { StyledDescription, StyledPinField } from './BasePinCodeModalContent.styles';
import { useTranslation } from 'react-i18next';
import { setRentalPinCodeActive } from 'services/userService';

type DeactivatePinCodeModalContentProps = {
  open: boolean;
  onDone: () => void;
};

export const ActivatePinCodeModalContent = ({
  open,
  onDone,
}: DeactivatePinCodeModalContentProps) => {
  const logger = useLogger('ActivatePinCodeModalContent');

  const { t } = useTranslation();
  const [pin, setPin] = useState('');
  const [busy, setBusy] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const activatePinCode = useCallback(async () => {
    setBusy(true);
    try {
      await setRentalPinCodeActive(pin, true);
      onDone();
    } catch (err) {
      logger.error(`Failed to activate PIN for user`, err);
      setErrorMessage(t('SETTINGS_LOCKER_WRONG_PIN_ERROR'));
      return false;
    } finally {
      setBusy(false);
    }
  }, [pin, onDone, logger, t]);

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    activatePinCode();
  };

  useEffect(() => {
    if (open) {
      setErrorMessage('');
      setPin('');
    }
  }, [open]);

  return (
    <RadixModal.Content title={t('SETTINGS_RENTAL_PIN_ACTIVATE_TITLE')}>
      <StyledDescription>{t('SETTINGS_LOCKER_ENTER_CURRENT_PIN_CODE')}</StyledDescription>
      <form onSubmit={onSubmit}>
        <StyledPinField
          value={pin}
          onValueChanged={(value) => {
            setPin(value);
            setErrorMessage('');
          }}
          length={4}
          masked={true}
          state={errorMessage ? 'INVALID' : undefined}
          message={errorMessage}
        />
        <RadixModal.ButtonStrip>
          <RadixModal.Close asChild>
            <Button variant="secondary">{t('COMMON_BUTTON_CANCEL')}</Button>
          </RadixModal.Close>
          <Button
            type="submit"
            isLoading={busy}
            disabled={busy}
            variant={pin.length === 4 ? 'primary' : 'secondary'}
          >
            {t('SETTINGS_RENTAL_PIN_ACTIVATE_BUTTON')}
          </Button>
        </RadixModal.ButtonStrip>
      </form>
    </RadixModal.Content>
  );
};
