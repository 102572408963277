import {
  StyledSectionHeading,
  StyledSectionSubHeadingDescription,
  StyledSubSectionContainer,
} from 'pages/navigation.styles';
import { useTranslation } from 'react-i18next';
import { SettingsRegionalChannels } from '../SettingsRegionalChannels';
import { SettingsParentalControls } from '../SettingsParentalControls';
import { SettingsRentalPinControls } from '../SettingsRentalPinControls';
import { FeatureEnabled } from 'components/FeatureEnabled';
import { SettingsPreferredLanguages } from '../SettingsPreferredLanguages';
import { StyledDeviceSettingsList, StyledPinCodeList } from './Settings.styles';
import { useId } from 'react';

export const Settings = () => {
  const { t } = useTranslation();
  const baseId = useId();

  const ids = {
    accountInformation: {
      heading: `${baseId}-account-settings-heading`,
      description: `${baseId}-account-settings-description`,
    },
    deviceSettings: {
      heading: `${baseId}-device-settings-heading`,
      description: `${baseId}-device-settings-description`,
    },
  };

  return (
    <>
      <section
        aria-labelledby={ids.accountInformation.heading}
        aria-describedby={ids.accountInformation.description}
      >
        <StyledSectionHeading id={ids.accountInformation.heading}>
          {t('SETTINGS_ACCOUNT_SUBHEADING')}
        </StyledSectionHeading>
        <StyledSectionSubHeadingDescription id={ids.accountInformation.description}>
          {t('SETTINGS_ACCOUNT_INFORMATION')}
        </StyledSectionSubHeadingDescription>
        <StyledSubSectionContainer>
          <StyledPinCodeList role="list">
            <li>
              <SettingsParentalControls />
            </li>
            <li>
              <SettingsRentalPinControls />
            </li>
          </StyledPinCodeList>
        </StyledSubSectionContainer>
      </section>
      <section
        aria-labelledby={ids.deviceSettings.heading}
        aria-describedby={ids.deviceSettings.description}
      >
        <StyledSectionHeading id={ids.deviceSettings.heading}>
          {t('SETTINGS_DEVICE_SUBHEADING')}
        </StyledSectionHeading>
        <StyledSectionSubHeadingDescription id={ids.deviceSettings.description}>
          {t('SETTINGS_DEVICE_INFORMATION')}
        </StyledSectionSubHeadingDescription>
        <StyledDeviceSettingsList role="list">
          <FeatureEnabled featureToggles={['Settings.RegionalChannels']}>
            <li>
              <SettingsRegionalChannels />
            </li>
          </FeatureEnabled>
          <li>
            <SettingsPreferredLanguages />
          </li>
        </StyledDeviceSettingsList>
      </section>
    </>
  );
};
