import { Chevron } from 'components';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PurchaseHistoryItem } from './PurchaseHistoryItem';
import { MediaAccessHistoryItem } from 'services/backend/purchaseGateway';
import { StyledPurchaseItemsList, StyledShowMoreButton } from './PurchaseHistoryList.styles';
import { AnimatePresence, motion, useAnimation } from 'framer-motion';

type PurchaseHistoryListProps = {
  items: MediaAccessHistoryItem[];
};

const MAX_STATIC_ITEMS = 2;

const AnimatedList = motion(StyledPurchaseItemsList);

export const PurchaseHistoryList = ({ items }: PurchaseHistoryListProps) => {
  const [expanded, setExpanded] = useState(false);
  const { t } = useTranslation();
  const [visibleItems, setVisibleItems] = useState(items.slice(0, MAX_STATIC_ITEMS));
  const listRef = useRef<HTMLUListElement>(null);
  const controls = useAnimation();

  const onToggleExpanded = useCallback(() => {
    setExpanded((prev) => !prev);
  }, []);

  useEffect(() => {
    if (expanded) {
      setVisibleItems(items);
    } else {
      setVisibleItems(items.slice(0, MAX_STATIC_ITEMS));
    }
  }, [controls, expanded, items]);

  const hasCollapsibleItems = items.length > MAX_STATIC_ITEMS;

  return (
    <>
      <AnimatedList
        animate={controls}
        initial={{ height: 'auto' }}
        hasCollapsibleItems={hasCollapsibleItems}
        role="list"
        ref={listRef}
      >
        <AnimatePresence>
          {visibleItems.map((item) => (
            <motion.li
              style={{ overflow: 'clip' }}
              initial={{ opacity: 0, height: '0' }}
              animate={{ opacity: 1, height: 'auto' }}
              exit={{ opacity: 0, height: '0' }}
              key={item.mediaId + item.startTime}
            >
              <PurchaseHistoryItem item={item} />
            </motion.li>
          ))}
        </AnimatePresence>
      </AnimatedList>
      {hasCollapsibleItems && (
        <StyledShowMoreButton variant="ghost" aria-expanded={expanded} onClick={onToggleExpanded}>
          {t(
            expanded
              ? 'SUBSCRIPTION_PURCHASE_HISTORY_SEE_LESS_PURCHASE_HISTORY'
              : 'SUBSCRIPTION_PURCHASE_HISTORY_SEE_MORE_PURCHASE_HISTORY',
          )}
          <Chevron open={expanded} />
        </StyledShowMoreButton>
      )}
    </>
  );
};
