import { CenteredSpinner } from 'components';
import { useLogger, useSnackbars } from 'hooks';
import { useFetchFunction } from 'hooks/useFetchFunction';
import { useTranslation } from 'react-i18next';
import { getAccountInfo } from 'services/userService';
import { getCreateEmailModalInfo } from './getCreateEmailModalInfo.utils';
import { RadixModal } from 'components/RadixModal';
import { Button } from '@telia-company/tv.oneapp-web-ui';

type CreateEmailModalProps = {
  open: boolean;
  onClose: () => void;
};

export const CreateEmailModalContent = ({ open, onClose }: CreateEmailModalProps) => {
  const logger = useLogger('CreateEmailModal');
  const { t } = useTranslation();
  const { data: accountInfo, loading, error } = useFetchFunction(getAccountInfo);
  const { createNegativeSnackbar } = useSnackbars();

  // Only report error when modal is open
  if (error && open) {
    logger.error('Failed to load account info', error);
    createNegativeSnackbar(t('ERROR_GENERIC_INFORMATION'));
    onClose();
  }

  const modalInfo = accountInfo ? getCreateEmailModalInfo(accountInfo) : undefined;

  const onConfirmModalResponse = (confirmed: boolean) => {
    modalInfo?.onConfirmModalResponse?.(confirmed);
    onClose();
  };

  return (
    <RadixModal.Content title={t('SETTINGS_RENTAL_PIN_REQUEST_EMAIL_MISSING_TITLE')}>
      <RadixModal.Description>
        {!modalInfo ? <CenteredSpinner /> : modalInfo.description}
      </RadixModal.Description>
      <RadixModal.ButtonStrip>
        <RadixModal.Close asChild>
          <Button type="button" variant="secondary">
            {t('COMMON_BUTTON_CANCEL')}
          </Button>
        </RadixModal.Close>
        <Button
          type="submit"
          variant="primary"
          isLoading={loading}
          onClick={() => onConfirmModalResponse(true)}
        >
          {modalInfo?.confirmLabel ?? '...'}
        </Button>
      </RadixModal.ButtonStrip>
    </RadixModal.Content>
  );
};
