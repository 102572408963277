import { useEffect, useRef, useState } from 'react';
import { Chevron } from 'components/Chevron';
import { DropDownMenu } from './DropDownMenu';
import { StyledAvatar, StyledAvatarButton, StyledUserDropDown } from './UserDropDown.styled';
import { User } from 'services/userService';
import { useTranslation } from 'react-i18next';
import { MenuItemGroup } from 'components/Header/hooks';

type UserDropDownProps = {
  user: User;
  menuItemGroups: MenuItemGroup[];
};

export const UserDropDown = ({ user, menuItemGroups }: UserDropDownProps) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const containerRef = useRef<HTMLButtonElement>(null);
  useEffect(() => {
    const handleClick = (event: TouchEvent | MouseEvent) => {
      if (containerRef.current?.contains(event.target as Element)) {
        return;
      }
      setOpen(false);
    };

    const eventType = 'ontouchstart' in window ? 'touchstart' : 'mousedown';
    document.addEventListener(eventType, handleClick);
    return () => {
      document.removeEventListener(eventType, handleClick);
    };
  }, []);

  const currentProfile = user.profiles.find((profile) => profile.id === user.currentProfileId);
  const name = currentProfile ? currentProfile.alias : user.username ?? user.email;

  return (
    <StyledUserDropDown>
      <StyledAvatarButton
        aria-expanded={open}
        aria-label={t('USER_MENU', { name })}
        open={open}
        onClick={() => {
          setOpen((prev) => !prev);
        }}
        ref={containerRef}
      >
        {currentProfile ? (
          <>
            <StyledAvatar src={currentProfile.avatar.url} alt="" />
            {currentProfile.alias}
          </>
        ) : (
          user.username || user.email
        )}
        <Chevron open={open} />
      </StyledAvatarButton>
      <DropDownMenu open={open} menuItemGroups={menuItemGroups} />
    </StyledUserDropDown>
  );
};
