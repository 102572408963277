import { BrowserRouter } from 'react-router-dom';
import { GlobalStyles } from 'GlobalStyles';
import {
  Header,
  ScrollToTop,
  FullscreenContextProvider,
  Footer,
  Loggedin,
  MessageBar,
  StaticHeader,
} from 'components';
import { FullscreenContainer } from 'components/FullscreenContainer';
import { ErrorBoundary, RouteErrorBoundary } from 'components';
import { PageTracker } from 'components/trackers';
import { AllRoutes } from 'pages';
import { logDeviantSubdomain as logIfSettingsSubdomain } from 'App.utils';
import { DecoratedHelmet } from 'DecoratedHelmet';
import { LazyMotion } from 'framer-motion';
import { EnvSelector } from 'components/EnvSelector/EnvSelector';
import { EnvironmentProvider } from 'providers';
import { shouldShowEnvSelectorComponent } from 'utils';
import { UIProvider } from 'UIProvider';
import { HeaderSizeContextProvider } from 'components/Header/components/HeaderSizeContext';
import { HeaderSkipLinkContextProvider } from 'components/SkipLink/HeaderSkipLinkContext';

// lazy load features from framer-motion on demand
const loadFeatures = () =>
  import('./components/Footer/utils/fetchFramerMotionFeatures').then((res) => res.default);

const showEnvSelector = shouldShowEnvSelectorComponent();

export const App = () => {
  // In case we start the app on a subdomain other than expected, log it.
  // This is because we are moving the settings subdomain to account web
  // and the logs will determine when we can safely remove the settings
  // subdomain from the infra.
  logIfSettingsSubdomain();

  return (
    <EnvironmentProvider>
      <UIProvider>
        {showEnvSelector && <EnvSelector />}
        <GlobalStyles />
        <ErrorBoundary>
          <LazyMotion features={loadFeatures}>
            <BrowserRouter>
              <PageTracker />
              <HeaderSizeContextProvider>
                <FullscreenContextProvider>
                  <HeaderSkipLinkContextProvider>
                    <ScrollToTop />
                    <DecoratedHelmet />
                    <StaticHeader>
                      <MessageBar />
                      <Loggedin>
                        <Header />
                      </Loggedin>
                    </StaticHeader>
                    <RouteErrorBoundary>
                      <AllRoutes />
                    </RouteErrorBoundary>
                  </HeaderSkipLinkContextProvider>
                  <FullscreenContainer />
                  <Loggedin>
                    <Footer />
                  </Loggedin>
                </FullscreenContextProvider>
              </HeaderSizeContextProvider>
            </BrowserRouter>
          </LazyMotion>
        </ErrorBoundary>
      </UIProvider>
    </EnvironmentProvider>
  );
};
