import { StyledSubSectionHeading, StyledSubSectionList } from 'pages/navigation.styles';
import { useTranslation } from 'react-i18next';
import { PreferredAudioLanguage, PreferredSubtitleLanguage } from './components';

export const SettingsPreferredLanguages = () => {
  const { t } = useTranslation();
  return (
    <>
      <StyledSubSectionHeading>
        {t('SETTINGS_DEVICE_PREFERRED_LANGUAGES_SECTION')}
      </StyledSubSectionHeading>
      <StyledSubSectionList role="list">
        <li>
          <PreferredAudioLanguage />
        </li>
        <li>
          <PreferredSubtitleLanguage />
        </li>
      </StyledSubSectionList>
    </>
  );
};
