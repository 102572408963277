import { BankIDIcon } from 'components';
import { StyledFederationProviderTitle } from 'pages/federated.styles';
import { StyledSubSectionDisabled, StyledSubSectionLabel } from 'pages/navigation.styles';

export const BankId = () => {
  return (
    <StyledSubSectionDisabled>
      <div>
        <StyledSubSectionLabel>
          <BankIDIcon aria-hidden={true} />
          <StyledFederationProviderTitle>{'BankID'}</StyledFederationProviderTitle>
        </StyledSubSectionLabel>
      </div>
    </StyledSubSectionDisabled>
  );
};
