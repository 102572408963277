import { useTranslation } from 'react-i18next';
import { UserSubscription } from 'services/userService';
import { VASItem } from './VASItem';
import {
  StyledServicesContainer,
  StyledServicesHeader,
  StyledServicesList,
} from './VASList.styles';

type VASProps = {
  userSubscription: UserSubscription;
};

export const VASList = ({ userSubscription }: VASProps) => {
  const { t } = useTranslation();
  return (
    <StyledServicesContainer>
      <StyledServicesHeader>{t('SUBSCRIPTION_VAS_HEADER')}</StyledServicesHeader>
      <StyledServicesList role="list">
        {userSubscription.services.map((service) => (
          <li key={service.id}>
            <VASItem userSubscription={userSubscription} service={service} />
          </li>
        ))}
      </StyledServicesList>
    </StyledServicesContainer>
  );
};
