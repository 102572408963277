import styled from '@emotion/styled';
import { textPrimary, textSecondary } from 'theme/theme.utils';

export const StyledPurchaseHistoryItem = styled.div`
  background: ${({ theme }) => theme.designTokens.color.background.tertiary};
  display: flex;
  flex-direction: column;
  padding: 16px;
  gap: 0;
  transition: border-radius 0.2 ease-in-out;
`;

export const StyledPurchaseHistoryItemWrapper = styled.div`
  ${({ theme }) => textPrimary(theme)}
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const StyledPurchaseHistoryItemHeading = styled.h3`
  ${({ theme }) => textPrimary(theme)}
  margin: 0;
  padding-right: 1rem;
`;

export const StyledPurchaseHistoryItemInfo = styled.p`
  ${({ theme }) => textSecondary(theme)}
  color: ${({ theme }) => theme.designTokens.color.text.secondary};
  margin: 0;
`;

export const StyledPurchaseHistoryItemInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledPurchaseHistoryItemPrice = styled.p`
  color: ${({ theme }) => theme.designTokens.color.text.secondary};
  white-space: nowrap;
  margin: 0;
  align-self: flex-start;
`;
