import { ErrorComponent, SectionSpinner } from 'components';
import { useTranslation } from 'react-i18next';
import {
  StyledSectionHeading,
  StyledMainHeading,
  StyledSectionSubHeadingDescription,
  StyledSubSectionList,
} from '../navigation.styles';
import { useFetchFunction } from 'hooks/useFetchFunction';
import { getAccountInfo, getUser } from 'services/userService';
import { useLogger } from 'hooks';
import { AccountInfoNotFoundError, UserNotFoundError } from 'errorTypes';
import { CustomerNumber, Email, Name, Password, Username, FederatedLogin } from './components';
import { isCustomerNumberVisible, isNameVisible, isUsernameVisible } from './AccountPage.utils';
import { getCountry } from 'utils';
import { SubscriptionNorway } from 'pages/Subscription/components/Subscription/SubscriptionNorway';
import { FeatureEnabled } from 'components/FeatureEnabled';
import { MfaSection } from './components/MfaSection/MfaSection';

export const AccountPage = () => {
  const { t } = useTranslation();
  const logger = useLogger('Account');
  const { data: user, loading, error } = useFetchFunction(getUser);
  const {
    data: customerAccount,
    loading: loadingAccount,
    error: errorAccount,
  } = useFetchFunction(getAccountInfo);

  if (error) {
    return <ErrorComponent error={error} />;
  } else if (errorAccount) {
    logger.error('Failed to load account info', errorAccount);
    throw errorAccount;
  }

  if (loading || loadingAccount) {
    return <SectionSpinner />;
  }

  if (!user) {
    logger.error('No user found');
    throw new UserNotFoundError();
  } else if (!customerAccount) {
    logger.error('No customer account info found');
    throw new AccountInfoNotFoundError();
  }

  const isNorway = getCountry() === 'NO';
  const isPartnerCustomer = customerAccount?.accountProvider?.type !== 'TELIA';
  const isNorwegianPartnerCustomer = isNorway && isPartnerCustomer;

  return (
    <>
      <StyledMainHeading>{t('ACCOUNT_INFO_HEADING')}</StyledMainHeading>
      <section aria-label={t('ACCOUNT_INFO_SECONDARY_HEADING')}>
        <StyledSectionHeading>{t('ACCOUNT_INFO_SECONDARY_HEADING')}</StyledSectionHeading>
        <StyledSubSectionList role="list">
          {isUsernameVisible() && (
            <li>
              <Username user={user} />
            </li>
          )}
          {isNameVisible() && (
            <li>
              <Name user={user} />
            </li>
          )}
          {isCustomerNumberVisible() && (
            <li>
              <CustomerNumber user={user} />
            </li>
          )}
          <li>
            <Email user={user} hideChangeButton={isNorwegianPartnerCustomer} />
          </li>
          {!isNorwegianPartnerCustomer && (
            <li>
              <Password user={user} />
            </li>
          )}
          {isNorwegianPartnerCustomer && (
            <li>
              <SubscriptionNorway isPartnerCustomer={isPartnerCustomer} />
            </li>
          )}
        </StyledSubSectionList>
      </section>
      <FeatureEnabled featureToggles={['Mfa']}>
        <MfaSection />
      </FeatureEnabled>

      <FeatureEnabled featureToggles={['Account.Federated.Login']}>
        <section aria-label={t('ACCOUNT_INFO_THIRD_HEADING')}>
          <StyledSectionHeading>{t('ACCOUNT_INFO_THIRD_HEADING')}</StyledSectionHeading>
          <StyledSectionSubHeadingDescription>
            {t('ACCOUNT_FEDERATED_INFORMATION')}
          </StyledSectionSubHeadingDescription>
          <StyledSubSectionList role="list">
            <FederatedLogin user={user} />
          </StyledSubSectionList>
        </section>
      </FeatureEnabled>
    </>
  );
};
