import { useTranslation } from 'react-i18next';
import {
  StyledInformation,
  StyledInformationContainer,
  StyledBorder,
  StyledContent,
} from './MediaPrepaid.styles';
import { MediaPrepaid as MediaPrepaidType } from 'services/mediaPrepaidService';
import { RemainingInformation } from './components/RemainingInformation';

type MediaPrepaidProps = {
  mediaPrepaid: MediaPrepaidType;
};

export const MediaPrepaid = ({ mediaPrepaid: { quantity, timesUsed, end } }: MediaPrepaidProps) => {
  const { t } = useTranslation();

  const remainingLabel = t('FREE_RENTALS_REMAINING', {
    count: quantity - timesUsed,
    postProcess: 'pluralizer',
  });

  const expireLabel = t('FREE_RENTALS_EXPIRE', { date: end });

  return (
    <StyledBorder>
      <StyledContent>
        <StyledInformationContainer>
          <RemainingInformation remainingLabel={remainingLabel} expireLabel={expireLabel} />
          <StyledInformation>{t('FREE_RENTALS_INFORMATION')}</StyledInformation>
        </StyledInformationContainer>
      </StyledContent>
    </StyledBorder>
  );
};
