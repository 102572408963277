import React from 'react';

type BankIDIconProps = React.SVGProps<SVGSVGElement>;

export const BankIDIcon = (props: BankIDIconProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" id="body_1" width="20" height="15" {...props}>
    <g transform="matrix(0.050200805 0 0 0.050200805 -5.5123 -5.371486)">
      <g>
        <path
          d="M251.3 263.4L264.5 180.2C 259.3 180.2 250.4 180.2 250.4 180.2C 243.79999 180.2 235.29999 176.5 232.79999 169.7C 231.99998 167.4 230.09999 159.5 240.99998 151.8C 244.89998 149.1 247.39998 146.1 247.89998 143.8C 248.39998 141.40001 247.79997 139.3 246.09998 137.7C 243.69998 135.4 238.99997 134.09999 232.99997 134.09999C 222.89996 134.09999 215.79997 139.9 215.09998 144.09999C 214.59998 147.2 216.99997 149.7 219.09998 151.29999C 225.39998 155.99998 226.89998 162.79999 222.99997 169.19998C 218.99997 175.79999 210.29997 180.09998 200.99997 180.19998C 200.99997 180.19998 191.79997 180.19998 186.59998 180.19998C 185.39998 188.29999 165.79997 312.5 164.29997 322.3L164.29997 322.3L242.09998 322.3C 242.8 318 246.4 294.5 251.3 263.4z"
          stroke="none"
          fill="#FFFFFF"
          fillRule="nonzero"
        />
        <g>
          <path
            d="M160.1 351.1L192 351.1C 205.6 351.1 208.9 358 207.9 364.30002C 207.09999 369.40002 203.59999 373.2 197.59999 375.7C 205.2 378.6 208.2 383.1 207.09999 390.2C 205.7 399.1 197.99998 405.7 187.9 405.7L187.9 405.7L151.59999 405.7L160.1 351.1zM181.2 373.7C 187.4 373.7 190.3 370.40002 190.9 366.5C 191.5 362.3 189.59999 359.4 183.4 359.4L183.4 359.4L177.9 359.4L175.7 373.69998L181.2 373.69998zM177.8 397.2C 184.2 397.2 187.90001 394.6 188.8 389.30002C 189.5 384.7 186.90001 382.00003 180.7 382.00003L180.7 382.00003L174.5 382.00003L172.1 397.30002L177.8 397.30002z"
            stroke="none"
            fill="#FFFFFF"
            fillRule="nonzero"
          />
          <path
            d="M251.8 406.1C 243.5 406.7 239.5 405.80002 237.5 402.2C 233.1 404.90002 228.2 406.30002 223 406.30002C 213.6 406.30002 210.3 401.40002 211.2 396.00003C 211.59999 393.40002 213.09999 390.90002 215.5 388.80002C 220.7 384.30002 233.5 383.7 238.5 380.30002C 238.9 376.50003 237.4 375.1 232.7 375.1C 227.2 375.1 222.59999 376.9 214.7 382.30002L214.7 382.30002L216.59999 369.90002C 223.4 365.00003 229.99998 362.7 237.59999 362.7C 247.29999 362.7 255.9 366.7 254.29999 377.30002L254.29999 377.30002L252.4 389.30002C 251.7 393.50003 251.9 394.80002 256.6 394.90002L256.6 394.90002L251.8 406.1zM237.4 387.2C 233 390 224.79999 389.5 223.9 395.30002C 223.5 398.00003 225.2 400.00003 227.9 400.00003C 230.5 400.00003 233.7 398.90002 236.29999 397.10004C 236.09999 396.10004 236.19998 395.10004 236.49998 393.20004L236.49998 393.20004L237.4 387.2z"
            stroke="none"
            fill="#FFFFFF"
            fillRule="nonzero"
          />
          <path
            d="M267.3 363.4L283.9 363.4L283 368.9C 288.3 364.4 292.3 362.69998 297.5 362.69998C 306.8 362.69998 311.1 368.4 309.6 377.69998L309.6 377.69998L305.30002 405.59998L288.7 405.59998L292.30002 382.49997C 293.00003 378.29996 291.7 376.29996 288.50003 376.29996C 285.90002 376.29996 283.50003 377.69995 281.20004 380.79996L281.20004 380.79996L277.40005 405.49997L260.80005 405.49997L267.3 363.4z"
            stroke="none"
            fill="#FFFFFF"
            fillRule="nonzero"
          />
          <path
            d="M322.6 351.1L339.2 351.1L335 377.9L350.9 363.4L371.4 363.4L351 381.4L367.4 405.6L346.5 405.6L333.9 386L333.69998 386L330.69998 405.5L314.09998 405.5L322.6 351.1z"
            stroke="none"
            fill="#FFFFFF"
            fillRule="nonzero"
          />
        </g>
        <path
          d="M381.3 351.1L400.4 351.1L392 405.6L372.9 405.6L381.3 351.1z"
          stroke="none"
          fill="#FFFFFF"
          fillRule="nonzero"
        />
        <path
          d="M409.7 351.1L437 351.1C 458.1 351.1 464.2 366.4 462.2 379.1C 460.30002 391.5 450.5 405.6 432 405.6L432 405.6L401.2 405.6L409.7 351.1zM427.4 392.6C 436.69998 392.6 441.8 388 443.3 378.30002C 444.4 371.1 442.19998 364.00003 431.9 364.00003L431.9 364.00003L426.8 364.00003L422.4 392.60004L427.4 392.60004z"
          stroke="none"
          fill="#FFFFFF"
          fillRule="nonzero"
        />
        <path
          d="M355.9 107.5L276.4 107.5L265.8 174.8L279.3 174.8C 286.69998 174.8 293.69998 171.40001 296.69998 166.5C 297.69998 164.9 298.09998 163.5 298.09998 162.2C 298.09998 159.4 296.19998 157.3 294.3 155.9C 289.09998 152 288 147.9 288 145C 288 144.4 288 143.9 288.1 143.4C 289.2 136.29999 298.80002 128.59999 311.5 128.59999C 319.1 128.59999 324.9 130.4 328.4 133.7C 331.5 136.59999 332.69998 140.7 331.8 145C 330.69998 150.1 325.59998 154.3 322.69998 156.4C 314.99997 161.79999 315.99997 166.5 316.49997 167.9C 318.09998 172.09999 324.19998 174.79999 328.89996 174.79999L328.89996 174.79999L349.49997 174.79999C 349.49997 174.79999 349.49997 174.79999 349.49997 174.9C 377.49997 175.09999 392.49997 188 387.79996 218C 383.39996 245.9 361.99997 257.9 336.49997 258.1L336.49997 258.1L326.39996 322.5L341.29996 322.5C 404.19995 322.5 455.59998 282.1 465.69995 218.3C 478.2 139.1 427.9 107.5 355.9 107.5z"
          stroke="none"
          fill="#FFFFFF"
          fillRule="nonzero"
        />
      </g>
      <path d="M0 0L619.2 0L619.2 513L0 513z" stroke="none" fill="none" />
    </g>
  </svg>
);
