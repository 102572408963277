import { NavigatorSection } from 'pages';
import {
  StyledAside,
  StyledMenuItems,
  StyledNavigationContainer,
  StyledNavLink,
} from './NavigationLarge.styles';
import { useTranslation } from 'react-i18next';
import { HTMLAttributes } from 'react';

type NavigatorLargeProps = HTMLAttributes<HTMLElement> & {
  sections: NavigatorSection[];
};

export const NavigationLarge = ({ sections: navigatorSections, ...rest }: NavigatorLargeProps) => {
  const { t } = useTranslation();
  return (
    <StyledAside {...rest}>
      <StyledNavigationContainer aria-label={t('SIDE_MENU')}>
        <StyledMenuItems role="list">
          {navigatorSections.map(({ icon, path, label }) => (
            <li key={path}>
              <StyledNavLink to={path}>
                {icon}
                <span>{label}</span>
              </StyledNavLink>
            </li>
          ))}
        </StyledMenuItems>
      </StyledNavigationContainer>
    </StyledAside>
  );
};
