import { SectionSpinner } from 'components';
import { AccountInfoNotFoundError, UserNotFoundError } from 'errorTypes';
import { useLogger } from 'hooks';
import { useFetchFunction } from 'hooks/useFetchFunction';
import { StyledSectionHeading, StyledSubSectionList } from 'pages/navigation.styles';
import { useTranslation } from 'react-i18next';
import { getUser, getAccountInfo } from 'services/userService';
import { NoSubscription, Subscription, SelectionMenu } from '..';
import { PurchaseHistory } from '../PurchaseHistory';
import { Payment } from '../Payment/Payment';
import { FeatureEnabled } from 'components/FeatureEnabled';
import { getCountry } from 'utils';
import { SubscriptionNorway } from '../Subscription/SubscriptionNorway';
import { getMediaPrepaids } from 'services/mediaPrepaidService';
import { MediaPrepaids } from '../MediaPrepaids';
import { useId } from 'react';

export const Subscriptions = () => {
  const logger = useLogger('Subscriptions');
  const { data: user, loading: loadingUser, error: errorUser } = useFetchFunction(getUser);
  const {
    data: customerAccount,
    loading: loadingAccount,
    error: errorAccount,
  } = useFetchFunction(getAccountInfo);

  const baseId = useId();
  const sectionHeadingId = `${baseId}-section-heading`;

  const {
    data: mediaPrepaids,
    loading: loadingMediaPrepaids,
    error: errorMediaPrepaids,
  } = useFetchFunction(getMediaPrepaids);

  const { t } = useTranslation();

  if (loadingUser || loadingAccount || loadingMediaPrepaids) {
    return <SectionSpinner />;
  }

  if (errorUser) {
    logger.error('Failed to load user', errorUser);
    throw errorUser;
  } else if (errorAccount) {
    logger.error('Failed to load account info', errorAccount);
    throw errorAccount;
  } else if (errorMediaPrepaids) {
    logger.error('Failed to load media prepaids', errorMediaPrepaids);
    // Never mind if the media prepaids failed - it's not critical
  }

  if (!user) {
    logger.error('No user found');
    throw new UserNotFoundError();
  } else if (!customerAccount) {
    logger.error('No customer account info found');
    throw new AccountInfoNotFoundError();
  }

  const isNorway = getCountry() === 'NO';
  const isPartnerCustomer = customerAccount?.accountProvider?.type !== 'TELIA';

  return (
    <>
      {!!mediaPrepaids?.length && <MediaPrepaids mediaPrepaids={mediaPrepaids} />}
      <section aria-labelledby={sectionHeadingId}>
        <StyledSectionHeading id={sectionHeadingId}>
          {t('SUBSCRIPTION_SECONDARY_HEADING')}
        </StyledSectionHeading>

        <StyledSubSectionList role="list">
          {/* No subscription */}
          {user.subscriptions.length === 0 ? (
            <li>
              <NoSubscription />
            </li>
          ) : isNorway ? (
            <li>
              <SubscriptionNorway
                manageSubscriptionLink={customerAccount?.accountProvider?.url}
                isPartnerCustomer={isPartnerCustomer}
              />
            </li>
          ) : (
            user.subscriptions.map((sub) => (
              <li key={sub.id}>
                <Subscription userSubscription={sub} key={sub.id} />
              </li>
            ))
          )}

          {/* The selection menu subsection should be shown if it's feature toggled AND user has a package */}
          {!(isNorway && isPartnerCustomer) && (
            <FeatureEnabled featureToggles={['Subscription.SelectionMenu']}>
              <li>
                <SelectionMenu />
              </li>
            </FeatureEnabled>
          )}
        </StyledSubSectionList>
      </section>
      {!(isNorway && isPartnerCustomer) && <Payment />}
      <PurchaseHistory />
    </>
  );
};
