import { MouseEventHandler, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyledButton } from './RequestPurchasePinCodeButton.styles';
import { getUser, requestPinCode } from 'services/userService';
import { useLogger, useSnackbars } from 'hooks';
import { CreateEmailModalContent } from './components/CreateEmailModal';
import { RadixModal } from 'components/RadixModal';

export const RequestPurchasePinCodeButton = () => {
  const { t } = useTranslation();
  const [successfulRequest, setSuccessfulRequest] = useState(false);
  const [isCreateEmailModalOpen, setIsCreateEmailModalOpen] = useState(false);
  const [busy, setBusy] = useState(false);
  const { createPositiveSnackbar, createNegativeSnackbar } = useSnackbars();
  const logger = useLogger('RequestPurchasePinCodeButton');

  const onButtonClick = useCallback<MouseEventHandler>(
    async (e) => {
      e.preventDefault();

      // Prevent multiple requests from being sent
      if (successfulRequest || busy) {
        return;
      }

      setBusy(true);
      try {
        const { email } = await getUser();
        if (email) {
          await requestPinCode();
          setSuccessfulRequest(true);
          createPositiveSnackbar(t('SETTINGS_RENTAL_PIN_REQUEST_BUTTON', { email }));
        } else {
          // if user has no email, open a dialog to allow them to create one
          setIsCreateEmailModalOpen(true);
        }
      } catch (error) {
        logger.error('Failed to send request pin email', error);
        createNegativeSnackbar(t('ERROR_GENERIC_INFORMATION'));
      } finally {
        setBusy(false);
      }
    },
    [busy, createNegativeSnackbar, createPositiveSnackbar, logger, successfulRequest, t],
  );

  return (
    <RadixModal
      open={isCreateEmailModalOpen}
      onOpenChange={(open) => {
        setIsCreateEmailModalOpen(open);
      }}
    >
      <RadixModal.Trigger asChild>
        <StyledButton
          variant="secondary"
          isLoading={busy}
          iconName={successfulRequest ? 'Check' : undefined}
          onClick={onButtonClick}
        >
          {successfulRequest
            ? t('SETTINGS_RENTAL_PIN_REQUEST_EMAIL_SENT')
            : t('SETTINGS_RENTAL_PIN_REQUEST_BUTTON_LABEL')}
        </StyledButton>
      </RadixModal.Trigger>
      <CreateEmailModalContent
        open={isCreateEmailModalOpen}
        onClose={() => setIsCreateEmailModalOpen(false)}
      />
    </RadixModal>
  );
};
