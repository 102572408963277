import styled from '@emotion/styled';
import { pxToRem } from '@telia-company/tv.oneapp-web-ui';
import { textPrimary } from 'theme/theme.utils';

export const StyledSkipLink = styled.a`
  /* visually hidden */
  position: absolute;
  top: ${pxToRem(16)}rem;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;

  /* visible when focused */
  &:focus {
    ${({ theme }) => textPrimary(theme)}
    width: auto;
    height: auto;
    padding: ${pxToRem(8)}rem ${pxToRem(16)}rem;
    left: 50%;
    transform: translateX(-50%);
    background: ${({ theme }) => theme.designTokens.color.background.tertiary};
    color: ${({ theme }) => theme.designTokens.color.text.primary};
    border-radius: ${pxToRem(4)}rem;
    clip: auto;
    z-index: 100;
  }
`;
