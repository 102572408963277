import styled from '@emotion/styled';
import { textPrimary } from 'theme/theme.utils';
import { mq } from '@telia-company/tv.oneapp-web-ui';
import { GiftIcon } from './GiftIcon';

export const StyledGiftIcon = styled(GiftIcon)`
  flex-shrink: 0;
  margin-right: 16px;

  ${mq.S`
    position: absolute;
    height: 100%;
    top: 0;
    bottom: 0;
  `};
`;

export const StyledContainer = styled.div`
  ${({ theme }) => textPrimary(theme)}
  color: ${({ theme }) => theme.designTokens.color.text.primary};
  margin-bottom: 8px;
  display: flex;
  align-items: center;
`;

export const StyledInformationContainer = styled.h3`
  ${({ theme }) => textPrimary(theme)}
  margin: 0;
  display: flex;
  flex-direction: column;

  ${mq.S`
    flex-direction: row;
    width: 100%;
    margin-left: 48px;
  `}
`;

export const StyledRemainingLabel = styled.span`
  flex: 1;
`;

export const StyledValidUntilLabel = styled.span`
  flex-shrink: 0;
  color: ${({ theme }) => theme.designTokens.color.text.secondary};
`;
