import { FormEvent, useEffect, useId, useRef, useState } from 'react';
import {
  StyledButton,
  StyledForm,
  StyledHeader,
  StyledLabel,
  StyledPinField,
} from './UpdatePinForm.styles';
import { PinFieldChangeEvent, PinFieldHandle } from '.';
import { useTranslation } from 'react-i18next';
import { resetPinCode } from 'services/userService';
import { useLogger, useSnackbars } from 'hooks';
import { useSearchParams } from 'react-router-dom';

type UpdatePinFormProps = {
  onSuccess: () => void;
};

export const UpdatePinCodeForm = ({ onSuccess }: UpdatePinFormProps) => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const logger = useLogger('UpdatePinForm');
  const { createNegativeSnackbar } = useSnackbars();

  const newPinRef = useRef<PinFieldHandle>(null);
  const verifyPinRef = useRef<PinFieldHandle>(null);
  const formRef = useRef<HTMLFormElement>(null);

  const [isUpdatingPin, setIsUpdatingPin] = useState(false);
  const [newPin, setNewPin] = useState('');
  const [verifyPin, setVerifyPin] = useState('');
  const [verifyPinErrorMessage, setVerifyPinErrorMessage] = useState('');
  const [newPinErrorMessage, setNewPinErrorMessage] = useState('');
  const [firstInvalidElement, setFirstInvalidElement] = useState<HTMLElement | null>(null);

  const newPinId = useId();
  const verifyPinId = useId();

  const onNewPinChanged = ({ value, lastIndexChanged }: PinFieldChangeEvent) => {
    setNewPin(value);

    if (verifyPin !== '') {
      verifyPinRef.current?.clear();
      setVerifyPin('');
    }

    if (value.length === 4 && lastIndexChanged === 3) {
      verifyPinRef.current?.focus();
    }
  };

  const onVerifyPinChanged = ({ value, lastIndexChanged }: PinFieldChangeEvent) => {
    setVerifyPin(value);
  };

  const onSubmit = async (event: FormEvent) => {
    event.preventDefault();

    if (newPin !== verifyPin) {
      setVerifyPinErrorMessage(t('SETTINGS_LOCKER_PIN_CODE_MATCH_ERROR'));
      verifyPinRef.current?.focus();
      return;
    }

    setIsUpdatingPin(true);
    try {
      const token = searchParams.get('token') ?? '';
      await resetPinCode(newPin, token);
      onSuccess();
    } catch (error: unknown) {
      logger.error('Failed to reset pin code', error);
      createNegativeSnackbar(t('SETTINGS_LOCKER_PIN_CODE_UPDATE_ERROR'));
    } finally {
      setIsUpdatingPin(false);
    }
  };

  const onInvalid = (event: FormEvent) => {
    event.preventDefault();
    // Keep track on the first invalid element so we can focus on it after
    // the validation is done
    if (!firstInvalidElement) {
      setFirstInvalidElement(event.target as HTMLElement);
    }
  };

  useEffect(() => {
    setNewPinErrorMessage('');
    setVerifyPinErrorMessage('');
  }, [newPin, verifyPin, t]);

  // When the validation is done - we can now focus on the first invalid element
  useEffect(() => {
    if (firstInvalidElement) {
      firstInvalidElement.focus();
      setFirstInvalidElement(null);
    }
  }, [firstInvalidElement]);

  return (
    <>
      <StyledHeader>{t('RESET_PURCHASE_PIN_HEADER')}</StyledHeader>
      <StyledForm onSubmit={onSubmit} onInvalid={onInvalid} ref={formRef}>
        <div>
          <StyledLabel htmlFor={newPinId}>{t('RESET_PURCHASE_PIN_NEW_PIN_LABEL')}</StyledLabel>
          <StyledPinField
            id={newPinId}
            ref={newPinRef}
            onChange={onNewPinChanged}
            autoFocus
            errorMessage={newPinErrorMessage}
            required={true}
            length={4}
          />
        </div>
        <div>
          <StyledLabel htmlFor={verifyPinId}>
            {t('RESET_PURCHASE_PIN_VERIFY_PIN_LABEL')}
          </StyledLabel>
          <StyledPinField
            id={verifyPinId}
            ref={verifyPinRef}
            onChange={onVerifyPinChanged}
            errorMessage={verifyPinErrorMessage}
            required={true}
            length={4}
          />
        </div>
        <StyledButton
          className="submit-button"
          variant={newPin.length === 4 && newPin === verifyPin ? 'primary' : 'secondary'}
          isLoading={isUpdatingPin}
        >
          {t('RESET_PURCHASE_PIN_SAVE_BUTTON_LABEL')}
        </StyledButton>
      </StyledForm>
    </>
  );
};
