import { Icon } from '@telia-company/tv.oneapp-web-ui';
import { useTranslation } from 'react-i18next';
import {
  StyledActivationContainer,
  StyledActivationLabel,
  StyledButton,
} from './VASActivation.styles';
import { SmartLink } from 'components';

type VASActivationProps = {
  activationUrl?: string;
};

export const VASActivation = ({ activationUrl }: VASActivationProps) => {
  const { t } = useTranslation();

  return (
    <StyledActivationContainer>
      {activationUrl ? (
        <StyledButton as={SmartLink} to={activationUrl} variant="primary">
          {t('SUBSCRIPTION_VAS_BUTTON_ACTIVATE_NOW')}
        </StyledButton>
      ) : (
        <StyledActivationLabel>
          {t('SUBSCRIPTION_VAS_LABEL_ACTIVATED')}
          <Icon name="Check" height={24} aria-hidden={true} />
        </StyledActivationLabel>
      )}
    </StyledActivationContainer>
  );
};
