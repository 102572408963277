import { StyledSubSectionDisabled, StyledSubSectionLabel } from 'pages/navigation.styles';
import { useTranslation } from 'react-i18next';

export const Title = () => {
  const { t } = useTranslation();
  return (
    <StyledSubSectionDisabled>
      <div>
        <StyledSubSectionLabel>{t('ACCOUNT_FEDERATED_USERNAME_PASSWORD')}</StyledSubSectionLabel>
      </div>
    </StyledSubSectionDisabled>
  );
};
