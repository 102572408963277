import { Button } from '@telia-company/tv.oneapp-web-ui';
import {
  StyledButtonContainer,
  StyledSubSection,
  StyledSubSectionContent,
  StyledSubSectionHeadingEmphasis,
} from 'pages/navigation.styles';
import { useTranslation } from 'react-i18next';
import { SmartLink } from 'components';
import { useNavigate } from 'react-router-dom';

export const SelectionMenu = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <StyledSubSection forcedDirection="column">
      <div>
        <StyledSubSectionHeadingEmphasis>
          {t('SUBSCRIPTION_SELECTION_MENU_HEADING')}
        </StyledSubSectionHeadingEmphasis>
        <StyledSubSectionContent>
          {t('SUBSCRIPTION_SELECTION_MENU_DESCRIPTION')}
        </StyledSubSectionContent>
      </div>
      <StyledButtonContainer>
        <Button
          as={SmartLink}
          variant="secondary"
          onClick={() => navigate('/subscription/selection-menu')}
        >
          {t('SUBSCRIPTION_SELECTION_MENU_BUTTON')}
        </Button>
      </StyledButtonContainer>
    </StyledSubSection>
  );
};
