import { Button } from '@telia-company/tv.oneapp-web-ui/dist/components/Button/Button';
import {
  StyledButtonContainer,
  StyledMainHeading,
  StyledSectionHeading,
} from 'pages/navigation.styles';
import { useTranslation } from 'react-i18next';
import { useSnackbars } from 'hooks/useSnackbars';
import { trackLogoutAllDevices } from 'tracking';
import { OtherDevices, ThisDevice } from './components';
import { useFetchFunction } from 'hooks/useFetchFunction';
import { LoggedinDevice, getLoggedinDevices, logoutOtherDevices } from 'services/deviceService';
import { LoadingSubSection } from './components/LoadingSubSection';
import { ErrorSubSection } from '../../components/ErrorSubSection/ErrorSubSection';
import { useEffect, useId, useState } from 'react';

export const DevicesPage = () => {
  const { t } = useTranslation();
  const { data, error, refresh } = useFetchFunction(getLoggedinDevices);
  const [thisDevice, setThisDevice] = useState<LoggedinDevice | undefined>();
  const [otherDevices, setOtherDevices] = useState<LoggedinDevice[] | undefined>();

  const baseId = useId();
  const thisDeviceSectionHeadingId = `${baseId}-this-device-section-heading`;
  const otherDevicesSetionHeadingId = `${baseId}-other-devices-section-heading`;

  const { createPositiveSnackbar, createNegativeSnackbar } = useSnackbars();

  const logoutAllDevices = async () => {
    try {
      trackLogoutAllDevices();
      await logoutOtherDevices();
      createPositiveSnackbar(t('NAV_DEVICES_LOGOUT_OTHER_DEVICES_SUCCESS_MESSAGE'));
      refresh();
    } catch (error) {
      createNegativeSnackbar(t('NAV_DEVICES_LOGOUT_OTHER_DEVICES_ERROR_MESSAGE'));
    }
  };

  useEffect(() => {
    if (data) {
      setThisDevice(data.thisDevice);
      setOtherDevices(data.otherDevices);
    }
  }, [data]);

  const isLoading = !error && !otherDevices;

  return (
    <>
      <StyledMainHeading>{t('NAV_DEVICES')}</StyledMainHeading>
      <section aria-labelledby={thisDeviceSectionHeadingId} aria-busy={isLoading}>
        <StyledSectionHeading id={thisDeviceSectionHeadingId}>
          {t('NAV_DEVICES_SECONDARY_HEADING_THIS_DEVICE')}
        </StyledSectionHeading>
        {error ? (
          <ErrorSubSection />
        ) : isLoading ? (
          <LoadingSubSection />
        ) : thisDevice ? (
          <ThisDevice device={thisDevice} />
        ) : (
          <ErrorSubSection />
        )}
      </section>

      {!isLoading && !error && otherDevices && !!otherDevices.length && (
        <section aria-labelledby={otherDevicesSetionHeadingId}>
          <StyledSectionHeading id={otherDevicesSetionHeadingId}>
            {t('NAV_DEVICES_SECONDARY_HEADING_OTHER_DEVICES')}
          </StyledSectionHeading>
          <OtherDevices devices={otherDevices} onLogout={() => refresh()} />
          <StyledButtonContainer>
            <Button onClick={logoutAllDevices} variant="primary" style={{ width: '100%' }}>
              {t('NAV_DEVICES_SECONDARY_BUTTON_LOG_OUT_ALL_DEVICES')}
            </Button>
          </StyledButtonContainer>
        </section>
      )}
    </>
  );
};
