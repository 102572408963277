import styled from '@emotion/styled';
import { textSecondary } from 'theme/theme.utils';

export const StyledContainer = styled.div``;

export const StyledPinContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

export const StyledInput = styled.input`
  width: 64px;
  height: 64px;
  border-radius: 6px;
  text-align: center;

  background: ${({ theme }) => theme.designTokens.color.button.secondaryDefault};
  border: 1px solid ${({ theme }) => theme.designTokens.color.border.subtle};
  color: ${({ theme }) => theme.designTokens.color.text.primary};

  font-size: ${({ theme }) => theme.designTokens.typography.textPrimary.fontSize};

  &[aria-invalid='true'] {
    border: 1px solid ${({ theme }) => theme.designTokens.color.infoDialogue.errorBackgroundDefault};
  }

  :focus {
    border: 1px solid white;
  }
`;

export const StyledErrorMessage = styled.div`
  ${({ theme }) => textSecondary(theme)}
  margin-top: 8px;
  text-align: left;
  color: ${({ theme }) => theme.designTokens.color.infoDialogue.errorBackgroundDefault};
`;
