import { Icon } from '@telia-company/tv.oneapp-web-ui';
import { useState } from 'react';
import { User } from 'services/userService';
import { MenuItem, MenuItemGroup } from '../../hooks';
import { StyledHamburgerButton } from './HamburgerMenu.styles';
import { SideMenu } from './SideMenu';
import { useTranslation } from 'react-i18next';

type HamburgerMenuProps = {
  user?: User;
  menuItems: MenuItem[];
  userMenuItemGroups?: MenuItemGroup[];
};

export const HamburgerMenu = ({ user, menuItems, userMenuItemGroups }: HamburgerMenuProps) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const menu = Icon({ name: 'Menu', 'aria-hidden': true });

  return (
    <>
      <StyledHamburgerButton
        aria-expanded={open}
        aria-label={t('MENU')}
        onClick={() => setOpen((previousOpen) => !previousOpen)}
      >
        {menu}
      </StyledHamburgerButton>
      <SideMenu
        onDismiss={() => setOpen(false)}
        open={open}
        menuItems={menuItems}
        userMenuItemGroups={userMenuItemGroups}
      />
    </>
  );
};
