import { useFullscreenContext } from 'components';
import { useMediaQueryMinSize } from 'hooks';
import { NavigatorSection } from 'pages/AllRoutes';
import { Outlet } from 'react-router-dom';
import { NavigationLarge, NavigationSmall } from 'components';
import {
  StyledNavigationContainer,
  StyledMain,
  StyledAsideWrapper,
} from './NavigatorLayout.styles';
import { useEffect } from 'react';
import { useHeaderSkipLinkContext } from 'components/SkipLink/HeaderSkipLinkContext';
import { SkipLink } from 'components/SkipLink/SkipLink';
import { useTranslation } from 'react-i18next';

type NavigatorLayoutProps = {
  sections: NavigatorSection[];
};

export const NavigatorLayout = ({ sections }: NavigatorLayoutProps) => {
  const { fullscreen } = useFullscreenContext();
  const { update } = useHeaderSkipLinkContext();
  const { t } = useTranslation();
  const mqS = useMediaQueryMinSize('S');

  const mainContentId = 'main-content';
  const sideNavigationId = 'side-navigation';

  useEffect(() => {
    // If we're in fullscreen mode, we don't show this component, and should not try to update the skip link
    if (!fullscreen) {
      update({ skipLinkTargetId: sideNavigationId, skipLinkContent: t('SKIP_TO_SIDE_MENU') });
    }
  }, [mainContentId, update, fullscreen, t]);

  return (
    <StyledNavigationContainer style={fullscreen ? { display: 'none' } : {}}>
      <StyledAsideWrapper id={sideNavigationId}>
        <SkipLink targetId={mainContentId}>{t('SKIP_TO_MAIN_CONTENT')}</SkipLink>
        {mqS ? <NavigationLarge sections={sections} /> : <NavigationSmall sections={sections} />}
      </StyledAsideWrapper>
      <StyledMain id={mainContentId}>
        <Outlet />
      </StyledMain>
    </StyledNavigationContainer>
  );
};
