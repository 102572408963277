import { Switch, SwitchProps } from '@telia-company/tv.oneapp-web-ui';
import { forwardRef, useCallback, useEffect, useState } from 'react';

type WobbleSwitchProps = SwitchProps;

export const WobbleSwitch = forwardRef<HTMLButtonElement, WobbleSwitchProps>(
  ({ value, onValueChange, ...props }, ref) => {
    const [wobbling, setWobbling] = useState(false);

    useEffect(() => {
      if (wobbling) {
        const timeout = setTimeout(() => {
          setWobbling(false);
        }, 100);
        return () => clearTimeout(timeout);
      }
    }, [wobbling, onValueChange]);

    const wobbleBeforeOnValueChange = useCallback<typeof onValueChange>(
      (value) => {
        setWobbling(true);
        onValueChange(value);
      },
      [onValueChange],
    );

    return (
      <Switch
        ref={ref}
        {...props}
        value={wobbling ? !value : value}
        onValueChange={wobbleBeforeOnValueChange}
      />
    );
  },
);
