import { Button, Input } from '@telia-company/tv.oneapp-web-ui';
import { Link } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import { ErrorComponent } from 'components';
import { useLoadAccountInfo } from '../../hooks';
import { useSaveEmail } from './hooks';
import { useTranslation } from 'react-i18next';
import { useLogger, useSnackbars } from 'hooks';
import { RequestError } from '@telia-company/tv.common-sdk/dist/ErrorTypes';
import { StyledButtonStrip, StyledMainHeading, StyledInputGroup } from '../../../navigation.styles';

const isValidEmail = (email: string): boolean => /\S+@\S+\.\S+/.test(email);

type ValidationErrors = {
  email?: string;
  password?: string;
};

export const ChangeCredentials = () => {
  const logger = useLogger('navigation/account/change-credentials');

  const { t } = useTranslation();
  const { createPositiveSnackbar, createNegativeSnackbar } = useSnackbars();
  const { accountInfo, loading, error: loadError } = useLoadAccountInfo();
  const { saving, save, saved, error: saveError } = useSaveEmail();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [validationErrors, setValidationErrors] = useState<ValidationErrors>({});

  useEffect(() => {
    if (accountInfo) {
      setEmail(accountInfo.email);
    }
  }, [accountInfo]);

  const validate = useCallback(() => {
    const newValidationErrors: ValidationErrors = {
      email: !isValidEmail(email) ? t('ACCOUNT_CHANGE_CREDENTIALS_WARN_FAULTY_EMAIL') : undefined,
      password: !password ? t('COMMON_WARN_PASSWORD_REQUIRED') : undefined,
    };
    setValidationErrors(newValidationErrors);
    return Object.values(newValidationErrors).some(Boolean) === false;
  }, [email, password, t]);

  const onSave = useCallback(() => {
    if (saving) return;

    if (validate()) {
      save({ newEmail: email, password });
    }
  }, [saving, validate, save, email, password]);

  useEffect(() => {
    if (saved) {
      createPositiveSnackbar(t('ACCOUNT_CHANGE_CREDENTIALS_NOTIFICATION_SAVED'));
      setPassword('');
    }
  }, [saved, createPositiveSnackbar, t]);

  useEffect(() => {
    if (saveError) {
      if (saveError instanceof RequestError) {
        if (saveError.status === 403) {
          setValidationErrors((prev) => ({
            ...prev,
            password: t('ACCOUNT_CHANGE_CREDENTIALS_WARN_FAULTY_PASSWORD'),
          }));
        }
      } else {
        logger.error('Failed to save credentials', saveError);
        createNegativeSnackbar(t('ACCOUNT_CHANGE_CREDENTIALS_NOTIFICATION_GENERAL_ERROR'));
      }
    }
  }, [saveError, createNegativeSnackbar, t, logger]);

  if (loadError || !accountInfo) {
    return <ErrorComponent error={loadError} />;
  }

  return (
    <>
      <StyledMainHeading>{t('ACCOUNT_CHANGE_CREDENTIALS_HEADING')}</StyledMainHeading>
      <form onSubmit={(e) => e.preventDefault()}>
        <StyledInputGroup>
          <div>
            <Input
              label={t('ACCOUNT_CHANGE_CREDENTIALS_EMAIL_LABEL')}
              type={'email'}
              defaultValue={accountInfo?.email}
              state={!!validationErrors.email ? 'INVALID' : 'DEFAULT'}
              message={validationErrors.email}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            ></Input>
          </div>
        </StyledInputGroup>
        <StyledInputGroup>
          <div>
            <Input
              label={t('ACCOUNT_CHANGE_CREDENTIALS_PASSWORD_LABEL')}
              type="password"
              showPasswordIcon={true}
              state={!!validationErrors.password ? 'INVALID' : 'DEFAULT'}
              message={validationErrors.password}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
        </StyledInputGroup>
        <StyledButtonStrip>
          <Button as={Link} variant="ghost" to="..">
            {t('COMMON_BUTTON_CANCEL')}
          </Button>
          <Button
            onClick={() => onSave()}
            isLoading={saving}
            type="submit"
            disabled={saving || loading}
          >
            {t('COMMON_BUTTON_SAVE')}
          </Button>
        </StyledButtonStrip>
      </form>
    </>
  );
};
