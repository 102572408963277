import { useTranslation } from 'react-i18next';
import { SelectableItem } from 'services/selectionMenuService';
import {
  StyledButton,
  StyledCard,
  StyledCardImage,
  StyledCardInfo,
  StyledTagContainer,
} from './Card.styles';
import { getImageProxyLogoUrl } from './getLogoUrl.utils';
import { useContext, useRef } from 'react';
import { SelectionMenuContext } from '../../SelectionMenuContext';
import { AddedTag } from './AddedTag';
import { MissingPointsModal, MissingPointsModalHandle } from '../ItemModal/MissingPointsModal';
import { ActivateTag } from './ActivateTag';
import { ActivateModal, ActivateModalHandle } from '../ItemModal/ActivateModal';

type SelectableCardProps = {
  item: SelectableItem;
};
export const SelectableCard = ({ item }: SelectableCardProps) => {
  const { t } = useTranslation();
  const { selectedPoints, totalPoints, oldSelectedItems, onSelectableItemChange, openItemModal } =
    useContext(SelectionMenuContext);
  const missingPointsModalRef = useRef<MissingPointsModalHandle>(null);
  const activateModalRef = useRef<ActivateModalHandle>(null);

  const { id, logoUrl, isSelected, name, points, hasPremiumAccess } = item;
  const imageProxyUrl = getImageProxyLogoUrl(logoUrl);
  const missingPoints = Math.max(0, (points ?? 0) + selectedPoints - totalPoints);

  // The item is activatable if it is currently selected (and not premium access), has an external registration URL
  // and is already stored in the user's selection menu. This will mean that you shouldn't be able to activate a
  // selected item which hasn't been saved in the user's selection menu yet.
  const isActivatable =
    isSelected &&
    !hasPremiumAccess &&
    !!item.externalRegistration?.url &&
    !!oldSelectedItems.find((i) => i.id === id);

  const onButtonClicked = () => {
    if (isActivatable) {
      activateModalRef.current?.open(item);
      return;
    }

    if (!item.isSelected && missingPoints) {
      missingPointsModalRef.current?.open(item);
      return;
    }

    item.isSelected = !item.isSelected;
    onSelectableItemChange(item);
  };

  // Determine if the button should be shown. The button is shown if the item does not have premium access
  // or is already selected, or if the item is activatable.
  const showButton = !(hasPremiumAccess && !isSelected) || isActivatable;

  const buttonLabel = isActivatable
    ? t('SELECTIONMENU_ITEM_ACTIVATE')
    : isSelected
    ? t('SELECTIONMENU_SCORETABLE_BUTTON_REMOVE')
    : missingPoints > 0
    ? t('SELECTIONMENU_ITEM_MISSING_POINTS', { points: missingPoints })
    : t('SELECTIONMENU_SCORETABLE_BUTTON_ADD');

  return (
    <>
      <StyledCard
        onClick={() => openItemModal(item)}
        active={isSelected}
        activatable={isActivatable}
        premiumAccess={hasPremiumAccess ?? false}
      >
        <StyledTagContainer>
          {isActivatable && <ActivateTag />}
          {isSelected && <AddedTag />}
        </StyledTagContainer>
        <StyledCardImage src={imageProxyUrl} alt={name} />
        {hasPremiumAccess ? (
          <StyledCardInfo>{t('SELECTIONMENU_ITEM_INCLUDED')}</StyledCardInfo>
        ) : (
          <StyledCardInfo>{t('SELECTIONMENU_ITEM_POINTS', { points })}</StyledCardInfo>
        )}
        {showButton && (
          <StyledButton
            variant={isActivatable ? 'primary' : 'secondary'}
            onClick={(e) => {
              e.stopPropagation();
              onButtonClicked();
            }}
          >
            {buttonLabel}
          </StyledButton>
        )}
      </StyledCard>
      <MissingPointsModal ref={missingPointsModalRef} />
      <ActivateModal ref={activateModalRef} />
    </>
  );
};
