import {
  StyledGiftIcon,
  StyledContainer,
  StyledInformationContainer,
  StyledRemainingLabel,
  StyledValidUntilLabel,
} from './RemainingInformation.styles';

type RemainingInformationProps = {
  remainingLabel: string;
  expireLabel: string;
};

export const RemainingInformation = ({
  remainingLabel,
  expireLabel,
}: RemainingInformationProps) => {
  return (
    <StyledContainer>
      <StyledGiftIcon aria-hidden={true} />
      <StyledInformationContainer>
        <StyledRemainingLabel>{remainingLabel}</StyledRemainingLabel>
        <StyledValidUntilLabel>{expireLabel}</StyledValidUntilLabel>
      </StyledInformationContainer>
    </StyledContainer>
  );
};
