import { NavLink } from 'react-router-dom';

type SmartLinkProps = Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, 'href'> & {
  to?: string;
  openInNewTab?: boolean;
};

export const SmartLink = ({ to, openInNewTab, children, ...rest }: SmartLinkProps) => {
  const target = openInNewTab ? '_blank' : undefined;
  const rel = openInNewTab ? 'noopener noreferrer' : undefined;

  return to?.startsWith('/') ? (
    <NavLink to={to} {...rest} target={target} rel={rel}>
      {children}
    </NavLink>
  ) : (
    <a href={to} {...rest} target={target} rel={rel}>
      {children}
    </a>
  );
};
