import styled from '@emotion/styled';
import { mq } from '@telia-company/tv.oneapp-web-ui';
import { NavLink } from 'react-router-dom';
import { textPrimary } from 'theme/theme.utils';

export const StyledAside = styled.aside`
  background-color: ${({ theme }) => theme.designTokens.color.background.surfaceDark};

  height: 100%;
  min-width: 217px;
  ${mq.L`{
    min-width: 308px;
  }`}
`;

export const StyledNavigationContainer = styled.nav`
  display: flex;
  flex-direction: column;
  padding: 32px 0px 16px 0px;

  ${mq.L`{
    padding: 16px 0px 16px 0px;
  }`}
`;

export const StyledMenuItems = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

export const StyledNavLink = styled(NavLink)`
  border-left: 2px solid transparent;

  margin-bottom: 2px;
  transition: background-color 0.2s ease;
  display: flex;
  align-items: center;
  ${({ theme }) => textPrimary(theme)}
  padding: 12px 24px;
  position: relative;

  &.active {
    border-left-color: white;
  }

  &.active {
    ${({ theme }) => `
      background-color: ${theme.designTokens.color.button.ghostFocus}
    `}
  }

  &:hover {
    ${({ theme }) => `
      background-color: ${theme.designTokens.color.button.ghostFocus}
    `}
  }

  & > svg {
    position: absolute;
    top: 0px;
    left: 16px;
    height: 100%;
    width: 24px;
    display: flex;
    align-items: center;
    align-content: center;
  }

  & > span {
    margin-left: 40px;
  }
`;
