import { Button } from '@telia-company/tv.oneapp-web-ui';
import { PinField } from 'components';
import { RadixModal } from 'components/RadixModal';
import { FormEventHandler, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MfaStatus } from 'services/userService';

export type VerificationResult = {
  success: boolean;
  errorMessage?: string;
};

type ModalVerifyMfaProps = {
  onVerify: (oneTimePassword: string) => Promise<VerificationResult>;
  open: boolean;
  onOpenChange: (open: boolean) => void;
  verificationMethod: MfaStatus['verificationMethod'];
  verificationTarget: string;
};

export const ModalVerifyMfa = ({
  verificationMethod,
  verificationTarget,
  onVerify,
  open,
  onOpenChange,
}: ModalVerifyMfaProps) => {
  const { t } = useTranslation();
  const [oneTimePassword, setOneTimePassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [busy, setBusy] = useState(false);

  const oneTimePasswordRef = useRef('');
  const openRef = useRef(false);

  const onSubmit = useCallback<FormEventHandler<HTMLFormElement>>(
    async (e) => {
      e.preventDefault();
      setBusy(true);
      try {
        const verificationResult = await onVerify(oneTimePassword);
        if (verificationResult.success) {
          onOpenChange(false);
        } else {
          if (verificationResult.errorMessage) {
            setErrorMessage(verificationResult.errorMessage);
          }
        }
      } finally {
        setBusy(false);
      }
    },
    [onOpenChange, onVerify, oneTimePassword],
  );

  const confirmButtonVariant = oneTimePassword.length === 6 ? 'primary' : 'secondary';

  // Whenever the modal is opened, reset the form
  useEffect(() => {
    if (open && !openRef.current) {
      setBusy(false);
      setOneTimePassword('');
      setErrorMessage('');
    }
    openRef.current = open ?? false;
  }, [open]);

  // Whenever the one-time password changes, reset the error message
  useEffect(() => {
    if (oneTimePasswordRef.current !== oneTimePassword) {
      oneTimePasswordRef.current = oneTimePassword;
      setErrorMessage('');
    }
  }, [oneTimePassword]);

  let title = '';
  switch (verificationMethod) {
    case 'email':
      title = t('MFA_VERIFY_MODAL_EMAIL_TITLE');
      break;
    case 'sms':
      title = t('MFA_VERIFY_MODAL_SMS_TITLE');
      break;
    default:
      // generate compile time error if more verification methods are added
      verificationMethod satisfies never;
  }

  return (
    <RadixModal open={open} onOpenChange={onOpenChange}>
      <RadixModal.Content title={title}>
        <RadixModal.Description>
          {t('MFA_VERIFY_MODAL_INFORMATION')}
          <br />
          <strong>{verificationTarget}</strong>
        </RadixModal.Description>
        <form onSubmit={onSubmit}>
          <PinField
            length={6}
            value={oneTimePassword}
            onValueChanged={(value) => setOneTimePassword(value)}
            state={!!errorMessage ? 'INVALID' : 'DEFAULT'}
            message={errorMessage}
            autocomplete="one-time-code"
          />
          <p>{t('MFA_VERIFY_MODAL_RETRY_MESSAGE')}</p>

          <RadixModal.ButtonStrip>
            <RadixModal.Close asChild>
              <Button variant="secondary">{t('COMMON_BUTTON_BACK')}</Button>
            </RadixModal.Close>
            <Button type="submit" variant={confirmButtonVariant} isLoading={busy} disabled={busy}>
              {t('COMMON_BUTTON_CONFIRM')}
            </Button>
          </RadixModal.ButtonStrip>
        </form>
      </RadixModal.Content>
    </RadixModal>
  );
};
