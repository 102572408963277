import { Variants } from 'framer-motion';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Chevron } from 'components';
import {
  StyledBackground,
  StyledNavigationContainer,
  StyledNavigationSelect,
  StyledSectionLink,
  StyledSectionListContainer,
} from './NavigationDropDown.styles';
import { SectionLabel } from './SectionLabel';
import { NavigatorSection } from 'pages';
import { getSection } from './NavigationDropDown.utils';

const animations: Variants = {
  visible: {
    display: 'block',
    opacity: 1.0,
    transition: {
      duration: 0.3,
    },
  },
  hidden: {
    opacity: 0.0,
    transition: {
      duration: 0.3,
    },
    transitionEnd: {
      display: 'none',
    },
  },
};

type NavigationDropDownProps = {
  sections: NavigatorSection[];
};

export const NavigationDropDown = ({ sections }: NavigationDropDownProps) => {
  const { pathname } = useLocation();

  const [open, setOpen] = useState(false);

  const currentSection = getSection({ pathname, sections });

  return (
    <StyledNavigationContainer>
      <StyledNavigationSelect
        onClick={() => setOpen((previousOpen) => !previousOpen)}
        aria-expanded={open}
      >
        {currentSection && (
          <>
            <SectionLabel section={currentSection} />
            <Chevron open={open} />
          </>
        )}
      </StyledNavigationSelect>
      <StyledBackground
        animate={open ? 'visible' : 'hidden'}
        initial="hidden"
        variants={animations}
        onClick={() => setOpen(false)}
      />
      <StyledSectionListContainer
        onClick={() => setOpen((prev) => !prev)}
        animate={open ? 'visible' : 'hidden'}
        initial="hidden"
        variants={animations}
        role="list"
      >
        {sections.map((section, i) => (
          <li key={section.path}>
            <StyledSectionLink to={section.path}>
              <SectionLabel section={section} />
            </StyledSectionLink>
          </li>
        ))}
      </StyledSectionListContainer>
    </StyledNavigationContainer>
  );
};
