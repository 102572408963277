import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { navigationText } from 'theme/theme.utils';

export const StyledUserDropDown = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const StyledAvatar = styled.img`
  height: 32px;
  width: 32px;
  border-radius: 50%;
  overflow: hidden;
`;

export const StyledAvatarButton = styled.button<{ open: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 8px;
  padding: 8px 16px;
  border-radius: ${({ theme }) => theme.designTokens.radius.button};
  border: none;
  color: inherit;

  transition: background-color 0.2s, color 0.2s, opacity 0.2s;

  ${({ theme }) => navigationText(theme)}
  ${({ theme, open }) => css`
    background-color: ${open
      ? theme.designTokens.color.navigation.currentSecondary
      : 'transparent'};
  `}

  &:hover {
    background-color: ${({ theme }) => theme.designTokens.color.navigation.currentSecondary};
  }
`;
