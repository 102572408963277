import { Config } from '@telia-company/tv.common-sdk/dist/Config';
import { Button } from '@telia-company/tv.oneapp-web-ui';
import { SmartLink } from 'components';
import {
  StyledButtonContainer,
  StyledSubSection,
  StyledSubSectionHeading,
} from 'pages/navigation.styles';
import { useTranslation } from 'react-i18next';
import { UserSubscription } from 'services/userService';
import { VASList } from './components';
import { VASActivation } from './components/VASActivation';

type SubscriptionProps = {
  userSubscription: UserSubscription;
};

export const Subscription = ({ userSubscription }: SubscriptionProps) => {
  const { t } = useTranslation();

  const manageSubscriptionLink = Config.get('telia-manage-subscription-link');
  const { name, services, packageType, activationUrl } = userSubscription;

  return (
    <StyledSubSection forcedDirection="column">
      <div>
        <StyledSubSectionHeading>{name}</StyledSubSectionHeading>
        {services.length > 0 && <VASList userSubscription={userSubscription} />}
        {!!manageSubscriptionLink && (
          <StyledButtonContainer>
            {packageType === 'VAS' ? (
              <VASActivation activationUrl={activationUrl} />
            ) : (
              <Button as={SmartLink} variant="secondary" to={manageSubscriptionLink}>
                {t('SUBSCRIPTION_BUTTON_MANAGE_SUBSCRIPTION_TELIA')}
              </Button>
            )}
          </StyledButtonContainer>
        )}
      </div>
    </StyledSubSection>
  );
};
