import {
  ComponentProps,
  HTMLInputTypeAttribute,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { createMfa, MfaStatus, requestMfaOneTimePassword } from 'services/userService';

import { Button, Input } from '@telia-company/tv.oneapp-web-ui';
import { getPhoneNumberPlaceholder } from './getPhoneNumberPlaceholder.utils';
import { useLogger } from 'hooks';
import { country } from 'environment';
import { getValidatedVerificationValue } from './getValidatedVerificationValue.utils';
import { RadixModal } from 'components/RadixModal';
import { ModalVerifyMfa } from './ModalVerifyMfa';
import { InvalidPasswordError } from 'errorTypes';
import { StyledModalContent } from './ModalContentCreateMfa.styles';

type ModalContentCreateMfaProps = {
  verificationMethod: MfaStatus['verificationMethod'];
  value: string;
  onCreated: () => void;
};

export const ModalContentCreateMfa = ({
  verificationMethod,
  onCreated,
  value,
}: ModalContentCreateMfaProps) => {
  const { t } = useTranslation();
  const logger = useLogger('ModalContentCreateMfa');
  const [busy, setBusy] = useState(false);
  const [currentValue, setCurrentValue] = useState(value);
  const [errorMessage, setErrorMessage] = useState('');
  const [verifyModalOpen, setVerifyModalOpen] = useState(false);

  const focusedElementRef = useRef<Element | null>(null);

  let title = '';
  let description = '';
  let label = '';
  let placeholder = '';
  let inputType: HTMLInputTypeAttribute = 'text';

  switch (verificationMethod) {
    case 'email':
      title = t('MFA_CREATE_MODAL_EMAIL_TITLE');
      description = t('MFA_CREATE_MODAL_EMAIL_DESCRIPTION');
      label = t('MFA_CREATE_MODAL_EMAIL_INPUT_LABEL');
      placeholder = t('MFA_CREATE_MODAL_EMAIL_INPUT_PLACEHOLDER');
      inputType = 'email';
      break;
    case 'sms':
      title = t('MFA_CREATE_MODAL_SMS_TITLE');
      description = t('MFA_CREATE_MODAL_SMS_DESCRIPTION');
      label = t('MFA_CREATE_MODAL_SMS_INPUT_LABEL');
      placeholder = getPhoneNumberPlaceholder(country);
      inputType = 'tel';
      break;
    default:
      // generate compile time error if more verification methods are added
      verificationMethod satisfies never;
  }

  const onSubmitCreateMfa = useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      // Save the currently focused element so we can return focus to it after the modal closes
      focusedElementRef.current = document.activeElement;

      const validatedValue = getValidatedVerificationValue(currentValue, verificationMethod);
      if (!validatedValue) {
        switch (verificationMethod) {
          case 'email':
            setErrorMessage(t('MFA_CREATE_MODAL_INVALID_EMAIL_ERROR'));
            break;
          case 'sms':
            setErrorMessage(t('MFA_CREATE_MODAL_INVALID_PHONE_NUMBER_ERROR'));
            break;
          default:
            // generate compile time error if more verification methods are added
            verificationMethod satisfies never;
        }
        return;
      }

      setBusy(true);
      setErrorMessage('');
      try {
        await requestMfaOneTimePassword({
          verificationMethod,
          value: validatedValue,
          action: 'create',
        });
        setVerifyModalOpen(true);
      } catch (error) {
        logger.error('Failed to request one time password for creating MFA', error);
        setErrorMessage(t('ERROR_GENERIC_INFORMATION'));
      } finally {
        setBusy(false);
      }
    },
    [currentValue, logger, t, verificationMethod],
  );

  useEffect(() => {
    if (!verifyModalOpen && focusedElementRef.current && !busy) {
      (focusedElementRef.current as HTMLElement).focus();
    }
  }, [busy, verifyModalOpen]);

  const onVerifyOneTimePassword = useCallback<ComponentProps<typeof ModalVerifyMfa>['onVerify']>(
    async (oneTimePassword: string) => {
      try {
        await createMfa({ oneTimePassword });
        onCreated();
        return { success: true };
      } catch (error) {
        if (error instanceof InvalidPasswordError) {
          return { success: false, errorMessage: t('MFA_VERIFY_MODAL_WRONG_ONE_TIME_PASSWORD') };
        }
        logger.error('Failed to verify MFA', error);
        return { success: false, errorMessage: t('ERROR_GENERIC_INFORMATION') };
      }
    },
    [logger, onCreated, t],
  );

  return (
    <>
      <StyledModalContent title={title}>
        <RadixModal.Description>{description}</RadixModal.Description>
        <form onSubmit={(e) => onSubmitCreateMfa(e)}>
          <Input
            label={label}
            value={currentValue}
            state={!!errorMessage ? 'INVALID' : 'VALID'}
            message={errorMessage}
            placeholder={placeholder}
            type={inputType}
            onChange={(e) => setCurrentValue(e.target.value)}
          />
          <RadixModal.ButtonStrip>
            <RadixModal.Close asChild>
              <Button variant="secondary">{t('COMMON_BUTTON_CANCEL')}</Button>
            </RadixModal.Close>
            <Button type="submit" variant="primary" isLoading={busy} disabled={busy}>
              {t('MFA_ADD_BUTTON')}
            </Button>
          </RadixModal.ButtonStrip>
        </form>
      </StyledModalContent>

      <ModalVerifyMfa
        verificationMethod={verificationMethod}
        verificationTarget={currentValue}
        open={verifyModalOpen}
        onOpenChange={setVerifyModalOpen}
        onVerify={onVerifyOneTimePassword}
      />
    </>
  );
};
