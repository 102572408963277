import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { mq } from '@telia-company/tv.oneapp-web-ui';
import { m } from 'framer-motion';
import { textSecondary } from 'theme/theme.utils';

export const StyledMotion = styled(m.div)`
  overflow: hidden;
`;

export const StyledColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.designTokens.color.border.separator};
  padding: 0.75rem 1rem;

  ${mq.L`
      width: 200px;
      border: none;
      padding: 0;
    `}
`;

export const StyledLinkList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;

  & > li {
    padding: 0;
    margin: 0;
    list-style: none;

    & > a {
      margin-top: 0.75rem;

      ${({ theme }) => css`
        ${textSecondary(theme)}
        color: ${theme.designTokens.color.text.primary};
      `}

      ${mq.L`
            margin-top: 0.25rem;
        `}
    }

    & > button {
      margin-top: 0.75rem;
      background-color: transparent;
      border: none;
      padding: 0;

      ${({ theme }) => css`
        ${textSecondary(theme)}
        color: ${theme.designTokens.color.text.primary};
      `}

      ${mq.L`
            margin-top: 0.25rem;
        `}
    }
  }
`;

export const StyledHeading = styled.h4`
  margin: 0;

  ${({ theme }) => css`
    ${textSecondary(theme)}
    color: ${theme.designTokens.color.text.primary};
  `}

  &.open {
    color: ${({ theme }) => theme.designTokens.color.text.tertiary};
  }
`;

export const StyledOptAnonButton = styled.button`
  text-align: left;
`;

export const StyledCollapsibleButton = styled.button`
  width: 100%;
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  text-align: left;
  padding: 0;
  background-color: ${({ theme }) => theme.designTokens.color.button.ghostDefault};
  outline: none;
  border: none;
  display: flex;
  justify-content: space-between;

  &:focus-visible {
    outline: -webkit-focus-ring-color auto 1px;
    outline-offset: 1px;
  }
`;
