import { User } from 'services/userService';
import { MenuItem, MenuItemGroup } from '../../hooks';
import { SearchLink } from '../SearchLink';
import { HamburgerMenu } from './HamburgerMenu';
import {
  StyledHeaderContainer,
  StyledLogo,
  StyledNavigationWrapper,
} from './HeaderViewSmall.styles';
import { useTranslation } from 'react-i18next';

type HeaderViewSmallProps = {
  user?: User;
  menuItems: MenuItem[];
  userMenuItemGroups?: MenuItemGroup[];
};

export const HeaderViewSmall = ({ user, menuItems, userMenuItemGroups }: HeaderViewSmallProps) => {
  const { t } = useTranslation();
  return (
    <StyledHeaderContainer>
      <StyledNavigationWrapper aria-label={t('MAIN_MENU')}>
        <HamburgerMenu user={user} menuItems={menuItems} userMenuItemGroups={userMenuItemGroups} />
        <StyledLogo />
        <SearchLink />
      </StyledNavigationWrapper>
    </StyledHeaderContainer>
  );
};
