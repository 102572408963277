import { createContext, ReactNode, useCallback, useContext, useState } from 'react';

type HeaderSkipLinkContextUpdateProps = {
  skipLinkTargetId: string;
  skipLinkContent: ReactNode;
};

type HeaderSkipLinkContextProps = {
  update: ({ skipLinkTargetId, skipLinkContent }: HeaderSkipLinkContextUpdateProps) => void;
  skipLinkTargetId: string | null;
  skipLinkContent: ReactNode | null;
};

const HeaderSkipLinkContext = createContext<HeaderSkipLinkContextProps>({
  update: () => {},
  skipLinkTargetId: null,
  skipLinkContent: null,
});

type HeaderSkipLinkContextProviderProps = {
  children?: ReactNode;
};

export const HeaderSkipLinkContextProvider = ({ children }: HeaderSkipLinkContextProviderProps) => {
  const [skipLinkTargetId, setSkipLinkTargetId] = useState<string | null>(null);
  const [skipLinkContent, setSkipLinkContent] = useState<ReactNode | null>(null);

  const update = useCallback(
    ({ skipLinkTargetId, skipLinkContent }: HeaderSkipLinkContextUpdateProps) => {
      setSkipLinkTargetId(skipLinkTargetId);
      setSkipLinkContent(skipLinkContent);
    },
    [],
  );

  return (
    <HeaderSkipLinkContext.Provider value={{ update, skipLinkTargetId, skipLinkContent }}>
      {children}
    </HeaderSkipLinkContext.Provider>
  );
};

export const useHeaderSkipLinkContext = () => useContext(HeaderSkipLinkContext);
