import { useLogger } from 'hooks';
import {
  StyledSubSection,
  StyledSubSectionHeading,
  StyledSubSectionList,
} from 'pages/navigation.styles';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Channel, setRegionalChannel } from 'services/regionalChannelsService';
import { DropDownItem, SettingsDropDown } from '../SettingsDropDown';
import { useGetRegionalChannels } from './hooks/useGetRegionalChannels';
import { StyledChannelName } from './SettingsRegionalChannels.styles';
import {
  getDropDownItems,
  getPreferredChannellLabel,
  getSelectedDropDownItem,
} from './SettingsRegionalChannels.utils';

export const SettingsRegionalChannels = () => {
  const logger = useLogger('SettingsRegionalChannels');
  const { t } = useTranslation();
  const { error, channels, refresh } = useGetRegionalChannels();

  const onSelectRegionalChannel = useCallback(
    (channel: Channel) => {
      return async (dropDownItem: DropDownItem) => {
        await setRegionalChannel(channel.id, dropDownItem.id);
        refresh();
      };
    },
    [refresh],
  );

  if (error) {
    logger.error('Failed to retrieve the regional channels', error);
    return null;
  }

  return (
    <>
      <StyledSubSectionHeading>
        {t('SETTINGS_DEVICE_REGIONAL_CHANNELS_SECTION')}
      </StyledSubSectionHeading>
      <StyledSubSectionList role="list">
        {channels?.map((channel, i) => (
          <li key={channel?.id ?? i}>
            <StyledSubSection forcedDirection="row">
              <StyledChannelName>{channel?.name || '...'}</StyledChannelName>
              <SettingsDropDown
                items={channel ? getDropDownItems(channel) : []}
                selectedItem={channel ? getSelectedDropDownItem(channel) : undefined}
                label={
                  channel
                    ? getPreferredChannellLabel(channel) ??
                      t('SECTION_DEVICE_REGIONAL_CHANNELS_CHOOSE')
                    : '...'
                }
                onSelect={channel ? onSelectRegionalChannel(channel) : () => {}}
              />
            </StyledSubSection>
          </li>
        ))}
      </StyledSubSectionList>
    </>
  );
};
