import styled from '@emotion/styled';
import { mq } from '@telia-company/tv.oneapp-web-ui';
import { textPrimary, textSecondary } from 'theme/theme.utils';

export const StyledService = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  padding-bottom: 16px;
  border-bottom: 2px solid ${({ theme }) => theme.designTokens.color.border.separator};
  margin-top: 16px;
  ${mq.S`
    flex-direction: row;
  `}
`;

export const StyledServiceInfoContainer = styled.div`
  max-width: 100%;
  overflow: auto;
  display: flex;
  gap: 16px;
`;

export const StyledServiceNameAndDescriptionContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const StyledServiceIcon = styled.img`
  flex: 0 0 60px;
  margin-top: 6px;
  margin-bottom: 6px;
  overflow: hidden;
  border-radius: 8px;
  object-fit: fit;
`;

export const StyledServiceIconPlaceholder = styled.div`
  flex: 0 0 60px;
  margin-top: 6px;
  margin-bottom: 6px;
`;

export const StyledServiceName = styled.h4`
  margin: 0;
  ${({ theme }) => textPrimary(theme)}
  color: ${({ theme }) => theme.designTokens.color.text.primary};
`;

export const StyledServiceDescription = styled.p`
  ${({ theme }) => textSecondary(theme)}
  margin: 0;
  color: ${({ theme }) => theme.designTokens.color.text.secondary};
`;
