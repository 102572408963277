import { StyledSectionHeading } from 'pages/navigation.styles';
import { StyledMediaPrepaidList } from './MediaPrepaids.styles';
import { useTranslation } from 'react-i18next';
import { MediaPrepaid } from './components/MediaPrepaid';
import { MediaPrepaid as MediaPrepaidType } from 'services/mediaPrepaidService';

type MediaPrepaidsProps = {
  mediaPrepaids: MediaPrepaidType[];
};

export const MediaPrepaids = ({ mediaPrepaids }: MediaPrepaidsProps) => {
  const { t } = useTranslation();
  return (
    <section aria-label={t('FREE_RENTALS_HEADER')}>
      <StyledSectionHeading>{t('FREE_RENTALS_HEADER')}</StyledSectionHeading>
      <StyledMediaPrepaidList role="list">
        {mediaPrepaids.map((mediaPrepaid, index) => (
          <li key={index}>
            <MediaPrepaid mediaPrepaid={mediaPrepaid} />
          </li>
        ))}
      </StyledMediaPrepaidList>
    </section>
  );
};
