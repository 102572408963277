import { StyledMainHeading } from 'pages/navigation.styles';
import { useTranslation } from 'react-i18next';
import { Subscriptions } from './components';

export const SubscriptionPage = () => {
  const { t } = useTranslation();

  return (
    <>
      <StyledMainHeading>{t('SUBSCRIPTION_HEADING')}</StyledMainHeading>
      <Subscriptions />
    </>
  );
};
