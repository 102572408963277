import { css, useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { mq } from '@telia-company/tv.oneapp-web-ui';
import { m } from 'framer-motion';

import {
  headingPrimary,
  headingSecondary,
  headingTertiary,
  textPrimary,
  textPrimaryEmphasis,
  textSecondary,
} from 'theme/theme.utils';

export const StyledMainHeading = styled.h1`
  ${({ theme }) => css`
    ${headingPrimary(theme)}
    margin-top: 0;
  `}
`;

export const StyledSectionHeading = styled.h2`
  ${({ theme }) => css`
    ${headingSecondary(theme)}
    margin-top: 24px;
    margin-bottom: 8px;
  `}
`;

type StyledButtonStripProps = {
  stackable?: boolean;
};

export const StyledButtonStrip = styled.div(
  ({ stackable }: StyledButtonStripProps) => css`
    display: flex;
    justify-content: end;
    width: 100%;
    gap: 16px;
    margin-top: 8px;
    ${stackable &&
    css`
      gap: 8px;
      flex-direction: column-reverse;
      ${mq.S`
        gap: 16px;
        flex-direction: row;
      `}
    `};
  `,
);

type StyledSubSectionProps = {
  forcedDirection?: 'column' | 'row';
};

export const StyledSubSection = styled(m.div, {
  shouldForwardProp: (prop) => prop !== 'forcedDirection',
})(({ forcedDirection }: StyledSubSectionProps) => {
  const theme = useTheme();
  return css`
    background-color: ${theme.designTokens.color.background.tertiary};
    width: 100%;
    display: flex;
    ${forcedDirection === 'row'
      ? css`
          flex-direction: row;
          justify-content: space-between;
        `
      : css`
          flex-direction: column;
        `}
    padding: 12px 16px;
    gap: 8px;

    ${mq.S`
    gap: 24px;
    padding: 16px 16px;

    ${
      forcedDirection === 'column'
        ? css`
            flex-direction: column;
          `
        : css`
            align-items: center;
            flex-direction: row;
            justify-content: space-between;
          `
    }
    `};

    &:first-of-type {
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
    }

    &:last-child {
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;
    }
  `;
});

export const StyledSubSectionDisabled = styled(StyledSubSection)(
  ({ theme }) => css`
    background-color: ${theme.designTokens.color.background.surface};
  `,
);

export const Section = css`
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

export const StyledSubSectionContainer = styled.section`
  ${Section}
  margin-bottom: 40px;
`;

export const StyledSubSectionList = styled.ul`
  padding: 0;
  list-style: none;
  margin: 0 0 40px 0;

  & > li {
    margin-bottom: 2px;
  }

  & > li:not(:first-of-type):not(:last-of-type) {
    & > div {
      border-radius: 0px;
    }
  }

  & > li:first-of-type {
    & > div {
      border-radius: 6px 6px 0 0;
    }
  }

  & > li:last-of-type {
    margin-bottom: 0;

    & > div {
      border-radius: 0 0 6px 6px;
    }
  }

  & > li:first-of-type:last-of-type {
    & > div {
      border-radius: 6px;
    }
  }
`;

export const StyledSubSectionHeading = styled.h3`
  ${({ theme }) => css`
    ${headingTertiary(theme)}
    margin-bottom: 8px;
  `}
`;

export const StyledSubSectionHeadingEmphasis = styled.h3`
  ${({ theme }) => css`
    ${textPrimaryEmphasis(theme)}
    margin-bottom: 8px;
  `}
`;

export const StyledSubSectionLabel = styled.p`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    ${textPrimary(theme)};
    margin: 0 0 8px 0;

    &:last-of-type {
      margin: 0;
    }
  `}
`;

export const StyledSubSectionDefinitionTerm = styled.dt`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    ${textPrimary(theme)};
    margin: 0 0 8px 0;

    &:last-of-type {
      margin: 0;
    }
  `}
`;

type StyledSubSectionContentProps = {
  variant?: 'primary' | 'secondary';
};

export const StyledSubSectionContent = styled.div(({ variant }: StyledSubSectionContentProps) => {
  const theme = useTheme();
  return css`
    ${variant === 'primary' ? textPrimary(theme) : textSecondary(theme)}
    color: ${theme.designTokens.color.text.secondary};
    margin: 0 0 16px 0;

    &:last-child {
      margin: 0;
    }
  `;
});

type StyledSubSectionDefinitionDescriptionProps = {
  variant?: 'primary' | 'secondary';
};

export const StyledSubSectionDefinitionDescription = styled.dd(
  ({ variant }: StyledSubSectionDefinitionDescriptionProps) => {
    const theme = useTheme();
    return css`
      ${variant === 'primary' ? textPrimary(theme) : textSecondary(theme)}
      color: ${theme.designTokens.color.text.secondary};
      margin: 0 0 16px 0;

      &:last-child {
        margin: 0;
      }
    `;
  },
);

export const StyledButtonContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 8px;

  & > a {
    width: 100%;
  }

  ${mq.S`
    margin-top: 0;
    justify-content: end;
    & > a {
      width: auto;
    }
    width: auto;
  `}
`;

export const StyledInputGroup = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
  & > div {
    flex: 1;
  }
  margin-bottom: 24px;
`;

export const StyledSectionSubHeadingDescription = styled.p`
  ${({ theme }) => textSecondary(theme)}
  margin-top: 0px;
  margin-bottom: 8px;
`;
