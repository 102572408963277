import { Spinner } from '@telia-company/tv.oneapp-web-ui';
import { StyledWrapper } from './CenteredSpinner.styles';
import { useTranslation } from 'react-i18next';

export const CenteredSpinner = ({ width = '', height = '', children = '', size = 24 }) => {
  const { t } = useTranslation();
  return (
    <StyledWrapper data-test="loader" height={height} width={width}>
      <Spinner size={size} aria-label={t('A11Y_LOADING')} />
      <div className="text">{children}</div>
    </StyledWrapper>
  );
};
