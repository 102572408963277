import { Config } from '@telia-company/tv.common-sdk/dist/Config';
import { Logo } from '@telia-company/tv.oneapp-web-ui';
import { StyledLogoContainer } from './LogoHorizontal.styles';
import { useTranslation } from 'react-i18next';

export const LogoHorizontal = ({ ...props }) => {
  const { t } = useTranslation();
  return (
    <StyledLogoContainer
      href={Config.get('one-app-base-url')}
      aria-label={t('TO_START_PAGE')}
      {...props}
    >
      <Logo aria-hidden={true} />
    </StyledLogoContainer>
  );
};
