import { Config } from '@telia-company/tv.common-sdk/dist/Config';
import { useTranslation } from 'react-i18next';
import { HelpLink, HelpLinkType } from '../HelpLink';
import { StyledSubSectionList } from '../../../navigation.styles';

export const Support = () => {
  const { t } = useTranslation();

  const links: HelpLinkType[] = [
    {
      labelText: t('HELP_SUPPORT_TITLE'),
      labelContent: t('HELP_SUPPORT_TEXT'),
      url: Config.get('support-web-url'),
      buttonText: t('HELP_BUTTON_GO_TO_SUPPORT_WEB'),
    },
  ];

  return (
    <StyledSubSectionList role="list">
      {links.map((link, id) => (
        <li key={id}>
          <HelpLink link={link} key={id} iconString="supportIcon" />
        </li>
      ))}
    </StyledSubSectionList>
  );
};
