import { MenuItem } from '../../hooks';
import {
  StyledMenuItem,
  StyledMenuItemImage,
  StyledMenuItemImageLabelContainer,
  StyledMenuItemInfo,
  StyledMenuItemLabel,
  StyledSmartLink,
} from './DropDownMenuItem.styles';

type DropDownMenuItemProps = {
  menuItem: MenuItem;
  variant?: 'normal' | 'dark';
};
export const DropDownMenuItem = ({ menuItem, variant = 'normal' }: DropDownMenuItemProps) => {
  const { url, label, info, imageUrl, onClick } = menuItem;
  return (
    <StyledSmartLink to={url} onClick={(e) => onClick?.(e)} aria-label={menuItem.ariaLabel}>
      <StyledMenuItem variant={variant}>
        <StyledMenuItemImageLabelContainer>
          {imageUrl && <StyledMenuItemImage src={imageUrl} alt="" />}
          <StyledMenuItemLabel>{label}</StyledMenuItemLabel>
        </StyledMenuItemImageLabelContainer>
        {info && <StyledMenuItemInfo>{info}</StyledMenuItemInfo>}
      </StyledMenuItem>
    </StyledSmartLink>
  );
};
