import styled from '@emotion/styled';
import { Icon } from '@telia-company/tv.oneapp-web-ui';

export const SupportIcon = () => (
  <StyledIcon name="External" {...{ role: 'img' }} aria-hidden={true} />
);

const StyledIcon = styled(Icon)`
  width: 24px;
`;
