import { Icon } from '@telia-company/tv.oneapp-web-ui';
import { PageNotFound, NavigatorLayout, SubscriptionIcon, TitledRoute } from 'components';
import { FeatureToggleType, isFeatureEnabled } from 'featureToggles';
import { useTranslation } from 'react-i18next';
import { Navigate, Route, Routes } from 'react-router-dom';
import { ChangeCredentials, ChangePassword, ChangeUsername } from './Account/components';
import { SelectionMenuPage } from './Subscription/components';
import { SettingsPage } from './Settings';
import { DevicesPage } from './Devices';
import { Help } from './Help';
import { AccountPage } from './Account';
import { SubscriptionPage } from './Subscription';
import { NavigateWithParamsAndTracking, NavigateWithTracking } from 'components';
import { ProtectedRoute } from 'components/ProtectedRoute/ProtectedRoute';
import { UpdatePinCode } from './UpdatePinCode';
import { useUpdateMedalliaOnPageChange } from 'hooks';
import { LoginDevicePage } from './LoginDevice';
import { ChangeMfa } from './Account/components/ChangeMfa';

export type NavigatorSection = {
  icon: JSX.Element;
  label: string;
  path: string;
  featureToggles?: FeatureToggleType[];
};

export const AllRoutes = () => {
  const { t } = useTranslation();

  useUpdateMedalliaOnPageChange();

  const sections: NavigatorSection[] = [
    {
      icon: Icon({ name: 'User', 'aria-hidden': true }),
      label: t('NAV_ACCOUNT'),
      path: 'account',
    },
    {
      icon: <SubscriptionIcon aria-hidden={true} />,
      label: t('NAV_SUBSCRIPTION'),
      path: 'subscription',
    },
    {
      icon: Icon({ name: 'Settings', 'aria-hidden': true }),
      label: t('NAV_SETTINGS'),
      path: 'settings',
    },
    {
      icon: Icon({ name: 'Tv', 'aria-hidden': true }),
      label: t('NAV_DEVICES'),
      path: 'devices',
    },
    {
      icon: Icon({ name: 'Help', 'aria-hidden': true }),
      label: t('NAV_HELP'),
      path: 'help',
    },
  ];

  const filteredSections = sections.filter(({ featureToggles }) =>
    featureToggles ? isFeatureEnabled(featureToggles) : true,
  );

  const isActiveSection = (sectionPath: string) =>
    filteredSections.find(({ path }) => path === sectionPath);

  return (
    <Routes>
      {/* Login routes */}
      <Route element={<ProtectedRoute loginMethod="LOGIN" />}>
        <Route element={<NavigatorLayout sections={filteredSections} />}>
          <Route index element={<Navigate to={filteredSections[0].path} replace />} />

          {isActiveSection('account') && (
            <Route path="account" element={<TitledRoute title={t('NAV_ACCOUNT')} />}>
              <Route index element={<AccountPage />} />
              <Route path="credentials" element={<ChangeCredentials />} />
              <Route path="password" element={<ChangePassword />} />
              <Route path="username" element={<ChangeUsername />} />
              {isFeatureEnabled(['Mfa']) && <Route path="mfa" element={<ChangeMfa />} />}
            </Route>
          )}

          {isActiveSection('subscription') && (
            <Route path="subscription" element={<TitledRoute title={t('NAV_SUBSCRIPTION')} />}>
              <Route index element={<SubscriptionPage />} />
              {isFeatureEnabled(['Subscription.SelectionMenu']) && (
                <Route path="selection-menu" element={<SelectionMenuPage />} />
              )}
            </Route>
          )}

          {isActiveSection('settings') && (
            <Route path="settings" element={<TitledRoute title={t('NAV_SETTINGS')} />}>
              <Route index element={<SettingsPage />} />
            </Route>
          )}

          {isActiveSection('devices') && (
            <Route path="devices" element={<TitledRoute title={t('NAV_DEVICES')} />}>
              <Route index element={<DevicesPage />} />
            </Route>
          )}

          {isActiveSection('help') && (
            <Route path="help" element={<TitledRoute title={t('NAV_HELP')} />}>
              <Route index element={<Help />} />
            </Route>
          )}
        </Route>

        <Route path="login-device" element={<LoginDevicePage />} />

        <Route path="update-pincode" element={<UpdatePinCode />} />

        <Route path="subscriptions">
          <Route index element={<NavigateWithTracking to="/account" replace={true} />} />
        </Route>
        <Route path="buy" element={<NavigateWithTracking to="/subscription" replace={true} />} />
        <Route path="upsell">
          <Route index element={<NavigateWithTracking to="/subscriptions" replace={true} />} />
          <Route
            path="products"
            element={<NavigateWithTracking to="/subscriptions/products" replace={true} />}
          />
        </Route>
        <Route
          path="orders/direct/change/confirm/:productId"
          element={
            <NavigateWithParamsAndTracking to="/subscription/change/:productId" replace={true} />
          }
        />
        <Route path="orders" element={<NavigateWithTracking to="/subscription" replace={true} />} />

        <Route path="*" element={<PageNotFound />} />
      </Route>
    </Routes>
  );
};
