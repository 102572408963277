import styled from '@emotion/styled';
import { pxToRem } from '@telia-company/tv.oneapp-web-ui';
import { PinField } from 'components';
import { RadixModal } from 'components/RadixModal';

export const StyledDescription = styled(RadixModal.Description)`
  color: ${({ theme }) => theme.designTokens.color.text.secondary};
`;

export const StyledPinField = styled(PinField)`
  min-width: ${pxToRem(240)}rem;
  max-width: ${pxToRem(400)}rem;
  input {
    font-size: ${pxToRem(16)}rem;
  }
`;
