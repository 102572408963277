import {
  StyledButtonContainer,
  StyledSubSection,
  StyledSubSectionContent,
  StyledSubSectionHeadingEmphasis,
} from 'pages/navigation.styles';
import { useTranslation } from 'react-i18next';
import { Button } from '@telia-company/tv.oneapp-web-ui';
import { SmartLink } from 'components';
import { useId } from 'react';

export const SubscriptionNorway = ({
  manageSubscriptionLink,
  isPartnerCustomer,
}: {
  manageSubscriptionLink?: string;
  isPartnerCustomer: boolean;
}) => {
  const { t } = useTranslation();
  const baseId = useId();
  const headingId = `${baseId}-section-heading`;
  const descriptionId = `${baseId}-section-description`;

  return (
    <article aria-labelledby={headingId} aria-describedby={descriptionId}>
      <StyledSubSection forcedDirection="column">
        <div>
          <StyledSubSectionHeadingEmphasis id={headingId}>
            {t('SUBSCRIPTION_MANAGE_SUBSCRIPTION_BUTTON_CHANGE')}
          </StyledSubSectionHeadingEmphasis>
          <StyledSubSectionContent id={descriptionId}>
            {isPartnerCustomer
              ? t('SUBSCRIPTION_MANAGE_SUBSCRIPTION_PARTNER_CUSTOMER_CHANGE_SUBSCRIPTION')
              : t('SUBSCRIPTION_MANAGE_SUBSCRIPTION_CHANGE_SUBSCRIPTION_UPGRADE_BUY_POINTS')}
          </StyledSubSectionContent>
        </div>
        {!isPartnerCustomer && manageSubscriptionLink && (
          <StyledButtonContainer>
            <Button
              as={SmartLink}
              variant="secondary"
              to={manageSubscriptionLink}
              iconName="External"
            >
              {t('SUBSCRIPTION_MANAGE_SUBSCRIPTION_BUTTON_CHANGE')}
            </Button>
          </StyledButtonContainer>
        )}
      </StyledSubSection>
    </article>
  );
};
