import { AnchorHTMLAttributes } from 'react';
import { StyledSkipLink } from './SkipLink.styles';
import { forwardRef } from 'react';

type SkipLinkProps = AnchorHTMLAttributes<HTMLAnchorElement> & {
  targetId: string;
};

export const SkipLink = forwardRef<HTMLAnchorElement, SkipLinkProps>(
  ({ targetId, children, ...rest }, ref) => {
    return (
      <StyledSkipLink href={`#${targetId}`} ref={ref} {...rest}>
        {children}
      </StyledSkipLink>
    );
  },
);
