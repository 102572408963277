import { NavigatorSection } from 'pages';
import { NavigationDropDown } from './NavigationDropDown';
import { HTMLAttributes } from 'react';

type NavigationSmallProps = HTMLAttributes<HTMLElement> & {
  sections: NavigatorSection[];
};

export const NavigationSmall = ({ sections, ...rest }: NavigationSmallProps) => {
  return (
    <aside {...rest}>
      <NavigationDropDown sections={sections} />
    </aside>
  );
};
