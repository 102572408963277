import styled from '@emotion/styled';
import { LogoHorizontal } from 'components/LogoHorizontal';

export const StyledNavigationWrapper = styled.nav`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const StyledUserAndSearchWrapper = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const StyledLogoHorizontal = styled(LogoHorizontal)`
  margin-right: 32px;
`;

export const StyledMenuItemsWrapper = styled.ul`
  display: flex;
  padding: 0;
`;

export const StyledMenuItemWrapper = styled.li`
  list-style: none;
`;
