import styled from '@emotion/styled';
import { Button, mq } from '@telia-company/tv.oneapp-web-ui';
import { buttonText } from 'theme/theme.utils';

export const StyledActivationContainer = styled.div`
  white-space: nowrap;
  width: 100%;

  ${mq.S`
    width: auto;
  `}
`;

export const StyledActivationLabel = styled.span`
  display: flex;
  padding: 4px 16px 8px;
  flex-direction: row;
  gap: 8px;
  justify-content: center;
  ${({ theme }) => buttonText(theme)};
`;

export const StyledButton = styled(Button)`
  width: 100%;
`;
