import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLogger, useSnackbars } from 'hooks';
import { getUser, updateChildLock } from 'services/userService';
import { WobbleSwitch } from '..';
import { useFetchFunction } from 'hooks/useFetchFunction';
import {
  StyledChangePinCodeButton,
  StyledInputGroupShort,
  StyledSubSectionContainerShort,
  StyledSubSectionShort,
} from '../PinCodeSubSection/PinCodeSubSection.styles';
import { StyledSubSectionLabel } from 'pages/navigation.styles';
import { SetPinCodeModalContent } from './components/SetPinCodeModalContent';
import { RadixModal } from 'components/RadixModal';

export const SettingsParentalControls = () => {
  const logger = useLogger('SettingsParentalControls');
  const { t } = useTranslation();
  const { createPositiveSnackbar, createNegativeSnackbar } = useSnackbars();
  const { loading, data: user, error: userError, refresh: refreshUser } = useFetchFunction(getUser);
  const [isSetPinModalOpen, setIsSetPinModalOpen] = useState(false);
  const [isChangePinModalOpen, setIsChangePinModalOpen] = useState(false);
  const userRef = useRef(user);

  // The childlockEnabled state is used to control the switch's state, since it will animate
  // twice when disabling it due to the old value being true and the new value being false.
  // Updating the state manually will prevent the animation from playing twice.
  const [childLockEnabled, setChildLockEnabled] = useState(user?.childLock.enabled || false);

  useEffect(() => {
    if (user) {
      if (!userRef.current) {
        setChildLockEnabled(user.childLock.enabled);
      }
      userRef.current = user;
    }
  }, [user]);

  const deactivateChildLock = useCallback(async () => {
    try {
      await updateChildLock({ enabled: false }); // disable child lock
      setChildLockEnabled(false);
      refreshUser();
      createPositiveSnackbar(t('SETTINGS_LOCKER_PIN_CODE_DEACTIVATED'));
    } catch (err) {
      logger.error(`Failed to Deactivate PIN for user`, err);
      createNegativeSnackbar(t('SETTINGS_LOCKER_PIN_CODE_UPDATE_ERROR'));
    }
  }, [createNegativeSnackbar, createPositiveSnackbar, logger, refreshUser, t]);

  const onPinCodeSet = useCallback(() => {
    refreshUser();
    createPositiveSnackbar(t('SETTINGS_LOCKER_PIN_CODE_UPDATED'));
  }, [createPositiveSnackbar, refreshUser, t]);

  const onSetPinModalDone = useCallback(() => {
    setIsSetPinModalOpen(false);
    setChildLockEnabled(true);
    onPinCodeSet();
  }, [onPinCodeSet]);

  const onChangePinModalDone = useCallback(() => {
    setIsChangePinModalOpen(false);
    onPinCodeSet();
  }, [onPinCodeSet]);

  const onParentalControlSwitch = useCallback(
    (enabled: boolean) => {
      if (enabled) {
        setIsSetPinModalOpen(true);
      } else {
        deactivateChildLock();
      }
    },
    [deactivateChildLock],
  );

  if (userError) {
    logger.error('Failed to fetch user', userError);
    throw userError;
  }

  return (
    <>
      <StyledSubSectionContainerShort>
        <StyledInputGroupShort>
          <StyledSubSectionShort>
            <StyledSubSectionLabel aria-hidden={true}>
              {t('SETTINGS_LOCKER_PIN_CODE')}
            </StyledSubSectionLabel>
            <RadixModal open={isSetPinModalOpen} onOpenChange={setIsSetPinModalOpen}>
              <RadixModal.Trigger>
                <WobbleSwitch
                  aria-label={t('SETTINGS_LOCKER_PIN_CODE')}
                  value={childLockEnabled}
                  onValueChange={onParentalControlSwitch}
                />
              </RadixModal.Trigger>
              <SetPinCodeModalContent
                open={isSetPinModalOpen}
                onDone={onSetPinModalDone}
                title={t('SETTINGS_LOCKER_SET_PIN_CODE')}
              />
            </RadixModal>
          </StyledSubSectionShort>
        </StyledInputGroupShort>
      </StyledSubSectionContainerShort>
      {childLockEnabled && (
        <RadixModal open={isChangePinModalOpen} onOpenChange={setIsChangePinModalOpen}>
          <RadixModal.Trigger>
            <StyledChangePinCodeButton
              variant="ghost"
              aria-label={t('A11Y_CHANGE_LOCKER_PINCODE')}
              isLoading={loading}
            >
              {t('SETTINGS_LOCKER_CHANGE_PIN_CODE')}
            </StyledChangePinCodeButton>
          </RadixModal.Trigger>
          <SetPinCodeModalContent
            open={isChangePinModalOpen}
            onDone={onChangePinModalDone}
            title={t('SETTINGS_LOCKER_CHANGE_PIN_CODE')}
          />
        </RadixModal>
      )}
    </>
  );
};
