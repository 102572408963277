import { Switch } from '@telia-company/tv.oneapp-web-ui';
import { useSnackbars } from 'hooks';

import {
  StyledSubSection,
  StyledSubSectionDefinitionDescription,
  StyledSubSectionDefinitionTerm,
} from 'pages/navigation.styles';
import { useCallback, useEffect, useState } from 'react';
import { MfaStatus } from 'services/userService';
import { useTranslation } from 'react-i18next';
import { RadixModal } from 'components/RadixModal';
import { ModalContentCreateMfa } from './components/ModalContentCreateMfa';
import { RadixModalContentRemoveMfa } from './components/ModalContentRemoveMfa';

type MfaStatusSubSectionProps = {
  mfaStatus?: MfaStatus;
  verificationMethod: MfaStatus['verificationMethod'];
  canChange: boolean;
  defaultValue?: string;
};
export const MfaStatusSubSection = ({
  mfaStatus,
  verificationMethod,
  canChange,
  defaultValue,
}: MfaStatusSubSectionProps) => {
  const { createPositiveSnackbar } = useSnackbars();
  const [modalOpen, setModalOpen] = useState(false);
  const [switchValue, setSwitchValue] = useState(!!mfaStatus);
  const { t } = useTranslation();

  useEffect(() => {
    if (!modalOpen) {
      setSwitchValue(!!mfaStatus);
    }
  }, [mfaStatus, modalOpen]);

  const labelMap: Record<MfaStatus['verificationMethod'], string> = {
    email: t('MFA_SECTION_AUTHENTICATE_EMAIL'),
    sms: t('MFA_SECTION_AUTHENTICATE_SMS'),
  };

  const value = mfaStatus?.value
    ? t('MFA_SECTION_VERIFIED_VALUE', { value: mfaStatus.value })
    : t('MFA_SECTION_UNSET_VALUE');

  const onSuccessfulRemove = useCallback(() => {
    setModalOpen(false);
    createPositiveSnackbar(t('MFA_SECTION_SUCCESSFUL_REMOVAL_MESSAGE'));
  }, [createPositiveSnackbar, t]);

  const onSuccessfulCreate = useCallback(() => {
    setModalOpen(false);
    switch (verificationMethod) {
      case 'sms':
        createPositiveSnackbar(t('MFA_SECTION_SUCCESSFUL_ACTIVATION_MESSAGE_SMS'));
        break;
      case 'email':
        createPositiveSnackbar(t('MFA_SECTION_SUCCESSFUL_ACTIVATION_MESSAGE_EMAIL'));
        break;
      default:
        // compiler should catch this if using verification method that is not defined
        verificationMethod satisfies never;
    }
  }, [createPositiveSnackbar, t, verificationMethod]);

  return (
    <StyledSubSection>
      <div>
        <StyledSubSectionDefinitionTerm>
          {labelMap[verificationMethod]}
        </StyledSubSectionDefinitionTerm>
        <StyledSubSectionDefinitionDescription>{value}</StyledSubSectionDefinitionDescription>
      </div>
      {canChange && (
        <RadixModal open={modalOpen} onOpenChange={setModalOpen}>
          <RadixModal.Trigger>
            <Switch
              aria-label={labelMap[verificationMethod]}
              value={switchValue}
              onValueChange={(value) => {
                setSwitchValue(value);
                setModalOpen(true);
              }}
            />
          </RadixModal.Trigger>
          {switchValue ? (
            <ModalContentCreateMfa
              verificationMethod={verificationMethod}
              value={defaultValue ?? ''}
              onCreated={() => onSuccessfulCreate()}
            />
          ) : (
            !!mfaStatus && (
              <RadixModalContentRemoveMfa
                mfaStatus={mfaStatus}
                onRemoved={() => onSuccessfulRemove()}
              />
            )
          )}
        </RadixModal>
      )}
    </StyledSubSection>
  );
};
