import { StyledSubSectionHeadingEmphasis } from '../../../navigation.styles';
import {
  StyledSubSectionHelpLink,
  StyledButtonContainerHelpLink,
  StyledSubSectionHelpLinkWrapper,
  StyledSubSectionHelpLinkContent,
  StyledHelpLinkButton,
} from '../../HelpPage.styles';
import { SupportIcon } from '../Support/SupportIcon';

const iconNames = ['supportIcon'] as const;
type IconName = (typeof iconNames)[number];

const allowedIcons: Record<IconName, JSX.Element> = {
  supportIcon: <SupportIcon />,
};

export type HelpLinkType = {
  labelText: string;
  labelContent?: string;
  url: string;
  buttonText: string;
  buttonAriaLabel?: string;
};

export type HelpLinkProps = {
  link: HelpLinkType;
  iconString?: IconName;
};

export const HelpLink = ({ link, iconString }: HelpLinkProps) => {
  const icon = iconString ? allowedIcons[iconString] : null;

  const button = (
    <StyledHelpLinkButton
      as={'a'}
      href={link.url}
      variant="secondary"
      aria-label={link.buttonAriaLabel}
    >
      {icon}
      {link.buttonText}
    </StyledHelpLinkButton>
  );

  return (
    <StyledSubSectionHelpLink>
      <StyledSubSectionHelpLinkWrapper>
        <StyledSubSectionHeadingEmphasis>{link.labelText}</StyledSubSectionHeadingEmphasis>
        {link.labelContent && (
          <StyledSubSectionHelpLinkContent>{link.labelContent}</StyledSubSectionHelpLinkContent>
        )}
      </StyledSubSectionHelpLinkWrapper>
      <StyledButtonContainerHelpLink>{button}</StyledButtonContainerHelpLink>
    </StyledSubSectionHelpLink>
  );
};
