import { Button } from '@telia-company/tv.oneapp-web-ui';
import { SmartLink } from 'components';
import {
  StyledButtonContainer,
  StyledSubSection,
  StyledSubSectionDefinitionDescription,
  StyledSubSectionDefinitionTerm,
} from 'pages/navigation.styles';
import { useTranslation } from 'react-i18next';
import { User } from 'services/userService';
import { useGetPasswordChangeLabelAndLink } from './hooks/useGetPasswordChangeLabelAndLink';

type PasswordProps = {
  user?: User;
};

export const Password = ({ user }: PasswordProps) => {
  const { t } = useTranslation();
  const { changeLabel, changeLink } = useGetPasswordChangeLabelAndLink();

  return (
    <StyledSubSection>
      <div>
        <StyledSubSectionDefinitionTerm>
          {t('ACCOUNT_INFO_PASSWORD_SECTION_HEADING')}
        </StyledSubSectionDefinitionTerm>
        <StyledSubSectionDefinitionDescription aria-hidden={true}>
          {user ? <>&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;</> : '...'}
        </StyledSubSectionDefinitionDescription>
      </div>
      <StyledButtonContainer>
        <Button
          as={SmartLink}
          variant="secondary"
          disabled={!user}
          to={changeLink}
          aria-label={t('CHANGE_PASSWORD')}
        >
          {changeLabel}
        </Button>
      </StyledButtonContainer>
    </StyledSubSection>
  );
};
