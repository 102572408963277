import styled from '@emotion/styled';
import { textPrimary, textSecondary } from 'theme/theme.utils';

export const StyledDeviceContainer = styled.div`
  display: flex;
  max-width: 440px;
`;
export const StyledDeviceInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
export const StyledDeviceName = styled.h3`
  ${({ theme }) => textPrimary(theme)}
  margin: 0 0 8px 0;
`;
export const StyledDeviceLastUsed = styled.p`
  ${({ theme }) => textSecondary(theme)}
  color: ${({ theme }) => theme.designTokens.color.text.secondary};
  margin: 0;
`;

export const StyledDeviceLocation = styled.p`
  ${({ theme }) => textSecondary(theme)}
  color: ${({ theme }) => theme.designTokens.color.text.secondary};
  margin: 0;
`;
