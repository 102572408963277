import { useSnackbars } from 'hooks';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetPurchasePinCodeState } from './hooks';
import { RequestPurchasePinCode } from './components/RequestPurchasePinCode/RequestPurchasePinCode';
import {
  StyledSubSection,
  StyledSubSectionContainer,
  StyledSubSectionLabel,
} from 'pages/navigation.styles';
import { Spinner } from '@telia-company/tv.oneapp-web-ui';
import {
  StyledChangePinCodeButton,
  StyledInputGroupShort,
  StyledSubSectionContainerShort,
  StyledSubSectionShort,
} from '../PinCodeSubSection/PinCodeSubSection.styles';
import { RadixModal } from 'components/RadixModal';
import { WobbleSwitch } from '../WobbleSwitch';
import { DeactivatePinCodeModalContent } from './components/DeactivatePinCodeModalContent';
import { ActivatePinCodeModalContent } from './components/ActivatePinCodeModalContent';
import { ChangePinCodeModalContent } from './components/ChangePinCodeModalContent';

export const SettingsRentalPinControls = () => {
  const { loading, rentalPinCodeState, refreshRentalPinCodeState } = useGetPurchasePinCodeState();
  const { t } = useTranslation();
  const [isTogglePinModalOpen, setIsTogglePinModalOpen] = useState(false);
  const [isChangePinModalOpen, setIsChangePinModalOpen] = useState(false);
  const [isPurchasePinCodeSet, setIsPurchasePinCodeSet] = useState<boolean>();
  const { createPositiveSnackbar } = useSnackbars();

  const onChangePinState = useCallback(() => {
    setIsTogglePinModalOpen(true);
  }, []);

  const onPinCodeDeactivated = useCallback(() => {
    setIsTogglePinModalOpen(false);
    createPositiveSnackbar(t('SETTINGS_LOCKER_PIN_CODE_DEACTIVATED'));
    refreshRentalPinCodeState();
  }, [createPositiveSnackbar, refreshRentalPinCodeState, t]);

  const onPinCodeActivated = useCallback(() => {
    setIsTogglePinModalOpen(false);
    createPositiveSnackbar(t('SETTINGS_RENTAL_PIN_ACTIVATED'));
    refreshRentalPinCodeState();
  }, [createPositiveSnackbar, refreshRentalPinCodeState, t]);

  const onPinCodeChanged = useCallback(() => {
    setIsChangePinModalOpen(false);
    createPositiveSnackbar(t('SETTINGS_RENTAL_PIN_ACTIVATED'));
  }, [createPositiveSnackbar, t]);

  const onPinCodeChangeAborted = useCallback(() => {
    setIsChangePinModalOpen(false);
  }, []);

  // Until we know the state of the rental pin code, we can't render the sub section
  useEffect(() => {
    if (!rentalPinCodeState) {
      return;
    }
    setIsPurchasePinCodeSet(rentalPinCodeState.set);
  }, [rentalPinCodeState]);

  // While we're still unsure of the state, make the sub section look like it's loading
  if (isPurchasePinCodeSet === undefined) {
    return (
      <StyledSubSectionContainer>
        <StyledSubSection>
          <Spinner size={40} />
        </StyledSubSection>
      </StyledSubSectionContainer>
    );
  }

  // We've not yet set the purchase pin code - so we need to request it
  if (!isPurchasePinCodeSet) {
    return <RequestPurchasePinCode />;
  }

  return (
    <>
      <StyledSubSectionContainerShort>
        <StyledInputGroupShort>
          <StyledSubSectionShort>
            <StyledSubSectionLabel aria-hidden={true}>
              {t('SETTINGS_RENTAL_PIN_CODE')}
            </StyledSubSectionLabel>
            <RadixModal open={isTogglePinModalOpen} onOpenChange={setIsTogglePinModalOpen}>
              <RadixModal.Trigger asChild>
                <WobbleSwitch
                  aria-label={t('SETTINGS_LOCKER_PIN_CODE')}
                  value={rentalPinCodeState?.active ?? false}
                  onValueChange={onChangePinState}
                />
              </RadixModal.Trigger>
              {rentalPinCodeState?.active ? (
                <DeactivatePinCodeModalContent
                  open={isTogglePinModalOpen}
                  onDone={onPinCodeDeactivated}
                />
              ) : (
                <ActivatePinCodeModalContent
                  open={isTogglePinModalOpen}
                  onDone={onPinCodeActivated}
                />
              )}
            </RadixModal>
          </StyledSubSectionShort>
        </StyledInputGroupShort>
      </StyledSubSectionContainerShort>
      {rentalPinCodeState?.set && (
        <RadixModal open={isChangePinModalOpen} onOpenChange={setIsChangePinModalOpen}>
          <RadixModal.Trigger>
            <StyledChangePinCodeButton
              variant="ghost"
              aria-label={t('A11Y_CHANGE_PURCHASE_PINCODE')}
              isLoading={loading}
            >
              {t('SETTINGS_LOCKER_CHANGE_PIN_CODE')}
            </StyledChangePinCodeButton>
          </RadixModal.Trigger>
          <ChangePinCodeModalContent
            open={isChangePinModalOpen}
            onDone={onPinCodeChanged}
            onAbort={onPinCodeChangeAborted}
          />
        </RadixModal>
      )}
    </>
  );
};
