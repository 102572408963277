import { useState, createRef } from 'react';
import {
  StyledChevron,
  StyledDropDownContainer,
  StyledSelectContent,
  StyledSelectItem,
  StyledSelectScrollDownButton,
  StyledSelectScrollUpButton,
  StyledSelectTrigger,
  StyledSelectViewport,
  StyledTriggerButton,
} from './SettingsDropDown.styles';
import { AnimatePresence, m } from 'framer-motion';
import * as Select from '@radix-ui/react-select';
import { Chevron } from 'components';

const AnimatedContent = m(StyledSelectContent);

export type DropDownItem = {
  id: string;
  label: string;
};

type SettingsDropDownProps = {
  items: DropDownItem[];
  selectedItem?: DropDownItem;
  label: string;
  onSelect: (item: DropDownItem) => void;
};

export const SettingsDropDown = ({
  items,
  selectedItem,
  label,
  onSelect,
}: SettingsDropDownProps) => {
  const [open, setOpen] = useState(false);
  const containerRef = createRef<HTMLDivElement>();

  const onValueChange = (id: string) => {
    const item = items.find((item) => item.id === id);
    if (item) {
      onSelect(item);
    }
  };

  return (
    <StyledDropDownContainer ref={containerRef}>
      <Select.Root
        value={selectedItem?.id}
        onValueChange={onValueChange}
        onOpenChange={(isOpen) => setOpen(isOpen)}
      >
        <StyledSelectTrigger asChild>
          <StyledTriggerButton variant="ghost">
            <Select.Value />
            <StyledChevron open={open} />
          </StyledTriggerButton>
        </StyledSelectTrigger>
        <Select.Portal>
          <AnimatePresence>
            <AnimatedContent
              initial={{ opacity: 0 }}
              animate={{ opacity: open ? 1 : 0 }}
              exit={{ opacity: 0 }}
            >
              <StyledSelectScrollUpButton>
                <Chevron open={true} />
              </StyledSelectScrollUpButton>
              <StyledSelectViewport>
                {items.map(({ id, label }) => (
                  <StyledSelectItem key={id} value={id}>
                    <Select.ItemText>{label}</Select.ItemText>
                  </StyledSelectItem>
                ))}
              </StyledSelectViewport>
              <StyledSelectScrollDownButton>
                <Chevron open={false} />
              </StyledSelectScrollDownButton>
            </AnimatedContent>
          </AnimatePresence>
        </Select.Portal>
      </Select.Root>
    </StyledDropDownContainer>
  );
};
