import { StyledButtonStrip, StyledInputGroup, StyledMainHeading } from 'pages/navigation.styles';
import { useTranslation } from 'react-i18next';
import { StyledCurrentUsername, StyledCurrentUsernameHeader } from './ChangeUsername.styles';
import { Button, Input } from '@telia-company/tv.oneapp-web-ui';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useChangeUsername } from './hooks/useChangeUsername';
import { useGetUser, useLogger, useSnackbars } from 'hooks';
import { validateUsername } from './utils/validateUsername.utils';
import {
  AccountUsernameExistsError,
  AccountUsernameFormatError,
  AccountWrongPasswordError,
} from '@telia-company/tv.common-sdk-authentication/dist/ErrorTypes';

type ValidationErrors = {
  newUsername?: string;
  password?: string;
};

export const ChangeUsername = () => {
  const logger = useLogger('ChangeUsername');
  const { t } = useTranslation();
  const { user, error: errorUser } = useGetUser();
  const { createNegativeSnackbar, createPositiveSnackbar } = useSnackbars();
  const [newUsername, setNewUsername] = useState('');
  const [password, setPassword] = useState('');
  const { changeUsername, error, busy, changed } = useChangeUsername();
  const [validationErrors, setValidationErrors] = useState<ValidationErrors>({});

  useEffect(() => {
    if (!error) {
      return;
    }
    if (error instanceof AccountWrongPasswordError) {
      setValidationErrors({ password: t('COMMON_WRONG_PASSWORD') });
    } else if (error instanceof AccountUsernameExistsError) {
      setValidationErrors({ newUsername: t('ACCOUNT_CHANGE_USERNAME_ALREADY_IN_USE') });
    } else if (error instanceof AccountUsernameFormatError) {
      setValidationErrors({ newUsername: t('ACCOUNT_CHANGE_USERNAME_WRONG_FORMAT') });
    } else {
      createNegativeSnackbar(t('ACCOUNT_CHANGE_USERNAME_FAILURE'));
    }
  }, [createNegativeSnackbar, error, t]);

  useEffect(() => {
    if (changed) {
      setNewUsername('');
      setPassword('');
      createPositiveSnackbar(t('ACCOUNT_CHANGE_USERNAME_SUCCESS'));
    }
  }, [changed, createPositiveSnackbar, t]);

  if (errorUser) {
    logger.error('Failed to retrieve user', errorUser);
    throw errorUser;
  }

  const onSave = async () => {
    const trimmedUsername = newUsername.trim();
    if (!validateUsername(trimmedUsername)) {
      setValidationErrors({ newUsername: t('ACCOUNT_CHANGE_USERNAME_WRONG_FORMAT') });
      return;
    }
    setValidationErrors({});
    changeUsername(trimmedUsername, password);
  };

  return (
    <>
      <StyledMainHeading>{t('ACCOUNT_CHANGE_USERNAME_HEADING')}</StyledMainHeading>
      <StyledCurrentUsernameHeader>
        {t('ACCOUNT_CHANGE_USERNAME_CURRENT_USERNAME_HEADER')}
      </StyledCurrentUsernameHeader>
      <StyledCurrentUsername>{user?.username}</StyledCurrentUsername>
      <form onSubmit={(e) => e.preventDefault()}>
        <StyledInputGroup>
          <div>
            <Input
              label={t('ACCOUNT_CHANGE_USERNAME_NEW_USERNAME_LABEL')}
              onChange={(e) => setNewUsername(e.target.value)}
              value={newUsername}
              state={validationErrors.newUsername ? 'INVALID' : 'DEFAULT'}
              message={validationErrors.newUsername}
            />
          </div>
        </StyledInputGroup>
        <StyledInputGroup>
          <div>
            <Input
              label={t('ACCOUNT_CHANGE_USERNAME_CONFIRM_WITH_PASSWORD_LABEL')}
              onChange={(e) => setPassword(e.target.value)}
              autoComplete="current-password"
              type="password"
              value={password}
              showPasswordIcon={true}
              state={validationErrors.password ? 'INVALID' : 'DEFAULT'}
              message={validationErrors.password}
            />
          </div>
        </StyledInputGroup>
        <StyledButtonStrip>
          <Button as={Link} variant="ghost" to="..">
            {t('COMMON_BUTTON_CANCEL')}
          </Button>
          <Button type="submit" onClick={() => onSave()} isLoading={busy} disabled={busy}>
            {t('COMMON_BUTTON_SAVE')}
          </Button>
        </StyledButtonStrip>
      </form>
    </>
  );
};
