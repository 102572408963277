import { ServiceConfig } from '@telia-company/tv.common-sdk/dist/ServiceConfig';
import { DataManager } from '@telia-company/tv.common-sdk/dist/DataManager';
import { country } from 'environment';

type GetMediaAccessHistoryProps = {
  cacheClass: string;
};

export interface MediaAccessHistoryItem {
  mediaId: string;
  accessType: 'RENTAL' | 'EST';
  localizedPricePaid: string;
  title: string;
  startTime: string;
  endTime: string;
  contentType: string;
}

type MediaAccessHistoryResponse = MediaAccessHistoryItem[];

const MEDIA_ACCESS_HISTORY_DAYS_BACK_IN_TIME = 183; // 6 Months

export const getMediaAccessHistory = async ({
  cacheClass,
}: GetMediaAccessHistoryProps): Promise<MediaAccessHistoryResponse> => {
  const dataManager = DataManager.instance();
  const purchasegateway = ServiceConfig.getService('purchasegateway');
  const url = purchasegateway.getUrl(`/rest/secure/v1/mediaaccess/history`);
  const headers = purchasegateway.getHeaders();
  return (await dataManager.sendGet(url, cacheClass, {
    headers,
    secure: true,
    query: {
      daysBackInTime: MEDIA_ACCESS_HISTORY_DAYS_BACK_IN_TIME,
    },
  })) as unknown as MediaAccessHistoryResponse;
};

type GetMediaPrepaidsProps = {
  cacheClass: string;
};

type MediaPrepaid = {
  id: string;
  country: string;
  tvAccountId: string;
  quantity: number;
  timeUsed: number;
  startTime: string;
  endTime: string;
};

export const getMediaPrepaids = async ({
  cacheClass,
}: GetMediaPrepaidsProps): Promise<MediaPrepaid[]> => {
  const dataManager = DataManager.instance();
  const purchasegateway = ServiceConfig.getService('purchasegateway');
  const url = purchasegateway.getUrl('/rest/secure/v2/mediaprepaids');
  const headers = purchasegateway.getHeaders();
  return (await dataManager.sendGet(url, cacheClass, {
    headers,
    secure: true,
  })) as unknown as MediaPrepaid[];
};

type GetSelectionMenuProps = {
  cacheClass: string;
};

type BaseItemResponse = {
  id: string;
  name: string;
  description: string;
  logoUrl: string;
  vod: boolean;
  externalRegistration?: {
    url: string;
    barcodeUrl: string;
  };
  tvPackageId?: string;
};

export type SelectableItemResponse = BaseItemResponse & {
  isSelected: boolean;
  points?: number;
  vod: boolean;
  storeId?: string;
  channelId?: string;
  hasPremiumAccess?: boolean;
};

export type FixedItemResponse = BaseItemResponse;

export type SelectionMenuResponse = {
  selectables: SelectableItemResponse[];
  fixed: FixedItemResponse[];
  pointsRemaining: number;
  pointsTotal: number;
};

export const getSelectionMenu = async ({
  cacheClass,
}: GetSelectionMenuProps): Promise<SelectionMenuResponse> => {
  const dataManager = DataManager.instance();
  const purchasegateway = ServiceConfig.getService('purchasegateway');
  const url = purchasegateway.getUrl('/rest/secure/v1/selection-menu');
  const headers = purchasegateway.getHeaders();
  return (await dataManager.sendGet(url, cacheClass, {
    headers,
    secure: true,
  })) as unknown as SelectionMenuResponse;
};

type SetSelectionMenuProps = {
  ids: string[];
  cacheClass: string;
};

export const setSelectionMenu = async ({
  ids,
  cacheClass,
}: SetSelectionMenuProps): Promise<void> => {
  const dataManager = DataManager.instance();
  const purchasegateway = ServiceConfig.getService('purchasegateway');
  const url = purchasegateway.getUrl('/rest/secure/v1/selection-menu');
  const headers = purchasegateway.getHeaders();
  await dataManager.sendPost(url, cacheClass, {
    headers,
    secure: true,
    postAsJson: true,
    post: {
      ids,
    },
  });
};

type GetSelectionPackagesProps = {
  cacheClass: string;
};

type SelectionPackage = {
  name: string;
  storeIds: string[];
  channelIds: string[];
};

export type SelectionPackageResponse = Record<string, SelectionPackage>;

export const getSelectionPackages = async ({
  cacheClass,
}: GetSelectionPackagesProps): Promise<SelectionPackageResponse> => {
  const dataManager = DataManager.instance();
  const purchasegateway = ServiceConfig.getService('purchasegateway');
  const url = purchasegateway.getUrl('/rest/v1/selection-menu/selectable-tv-packages');
  const headers = { 'X-Country': country, ...(purchasegateway.getHeaders() ?? {}) };
  return (await dataManager.sendGet(url, cacheClass, {
    headers,
    secure: true,
  })) as unknown as SelectionPackageResponse;
};
