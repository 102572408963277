import { AccountOldPasswordError } from '@telia-company/tv.common-sdk-authentication/dist/ErrorTypes';
import { Button, Input } from '@telia-company/tv.oneapp-web-ui';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useSavePassword } from './hooks';
import { useSnackbars } from 'hooks';
import { StyledButtonStrip, StyledMainHeading, StyledInputGroup } from '../../../navigation.styles';
import { StyledForgotPasswordLink } from './ChangePassword.styles';
import { SmartLink } from 'components';
import { Config } from '@telia-company/tv.common-sdk/dist/Config';
import { validatePassword } from './utils';

type ValidationErrors = {
  currentPassword?: string;
  newPassword?: string;
  confirmPassword?: string;
};

export const ChangePassword = () => {
  const { t } = useTranslation();
  const { save, saved, saving, error } = useSavePassword();
  const { createNegativeSnackbar, createPositiveSnackbar } = useSnackbars();
  const [newPassword, setNewPassword] = useState<string>('');
  const [currentPassword, setCurrentPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [validationErrors, setValidationErrors] = useState<ValidationErrors>({});

  useEffect(() => {
    if (error) {
      if (error instanceof AccountOldPasswordError) {
        setValidationErrors((previous) => ({
          ...previous,
          currentPassword: t('ACCOUNT_CHANGE_PASSWORD_WARN_CURRENT_PASSWORD_WRONG'),
        }));
      } else {
        createNegativeSnackbar(t('ACCOUNT_CHANGE_PASSWORD_NOTIFICATION_GENERAL_ERROR'));
      }
    }
  }, [error, createNegativeSnackbar, t]);

  useEffect(() => {
    if (saved) {
      setNewPassword('');
      setCurrentPassword('');
      setConfirmPassword('');
      createPositiveSnackbar(t('ACCOUNT_CHANGE_PASSWORD_NOTIFICATION_SAVED'));
    }
  }, [saved, createPositiveSnackbar, t]);

  const validate = useCallback(() => {
    const newValidationErrors: ValidationErrors = {
      currentPassword: !currentPassword ? t('COMMON_WARN_PASSWORD_REQUIRED') : undefined,
      newPassword: !newPassword
        ? t('ACCOUNT_CHANGE_PASSWORD_WARN_NEW_PASSWORD_REQUIRED')
        : !validatePassword(newPassword)
        ? t('ACCOUNT_CHANGE_PASSWORD_FORMAT_ERROR')
        : undefined,
      confirmPassword:
        newPassword !== confirmPassword
          ? t('ACCOUNT_CHANGE_PASSWORD_WARN_MISMATCHING_PASSWORDS')
          : undefined,
    };
    setValidationErrors(newValidationErrors);
    return Object.values(newValidationErrors).some(Boolean) === false;
  }, [currentPassword, newPassword, confirmPassword, t]);

  const savePassword = useCallback(() => {
    if (validate()) {
      save({ newPassword, oldPassword: currentPassword });
    }
  }, [currentPassword, newPassword, save, validate]);

  return (
    <>
      <StyledMainHeading>{t('ACCOUNT_CHANGE_PASSWORD_HEADING')}</StyledMainHeading>
      <form onSubmit={(e) => e.preventDefault()}>
        <StyledInputGroup>
          <div>
            <Input
              label={t('ACCOUNT_CHANGE_PASSWORD_CURRENT_PASSWORD_LABEL')}
              disabled={saving}
              showPasswordIcon={true}
              type={'password'}
              onChange={(e) => setCurrentPassword(e.target.value)}
              value={currentPassword}
              state={validationErrors.currentPassword ? 'INVALID' : 'DEFAULT'}
              message={validationErrors.currentPassword}
            />
          </div>
        </StyledInputGroup>
        <StyledForgotPasswordLink>
          <SmartLink to={`${Config.get('one-app-base-url')}/login/forgotpassword`}>
            {t('FORGOT_PASSWORD_LINK')}
          </SmartLink>
        </StyledForgotPasswordLink>
        <StyledInputGroup>
          <div>
            <Input
              label={t('ACCOUNT_CHANGE_PASSWORD_NEW_PASSWORD_LABEL')}
              disabled={saving}
              type={'password'}
              showPasswordIcon={true}
              onChange={(e) => setNewPassword(e.target.value)}
              value={newPassword}
              state={!!validationErrors.newPassword ? 'INVALID' : 'DEFAULT'}
              message={validationErrors.newPassword}
            />
          </div>
        </StyledInputGroup>
        <StyledInputGroup>
          <div>
            <Input
              label={t('ACCOUNT_CHANGE_PASSWORD_CONFIRM_PASSWORD_LABEL')}
              type={'password'}
              showPasswordIcon={true}
              onChange={(e) => setConfirmPassword(e.target.value)}
              value={confirmPassword}
              state={!!validationErrors.confirmPassword ? 'INVALID' : 'DEFAULT'}
              message={validationErrors.confirmPassword}
            />
          </div>
        </StyledInputGroup>
        <StyledButtonStrip>
          <Button as={Link} disabled={saving} variant="ghost" to="..">
            {t('COMMON_BUTTON_CANCEL')}
          </Button>
          <Button type="submit" disabled={saving} onClick={() => savePassword()} isLoading={saving}>
            {t('COMMON_BUTTON_SAVE')}
          </Button>
        </StyledButtonStrip>
      </form>
    </>
  );
};
