import { Button } from '@telia-company/tv.oneapp-web-ui';
import { SmartLink } from 'components';
import { FeatureEnabled } from 'components/FeatureEnabled';
import {
  StyledButtonContainer,
  StyledSubSection,
  StyledSubSectionDefinitionDescription,
  StyledSubSectionDefinitionTerm,
} from 'pages/navigation.styles';
import { useTranslation } from 'react-i18next';
import { User } from 'services/userService';

type UsernameProps = {
  user?: User;
};

export const Username = ({ user }: UsernameProps) => {
  const { t } = useTranslation();
  return (
    <StyledSubSection>
      <div>
        <StyledSubSectionDefinitionTerm>
          {t('ACCOUNT_INFO_USERNAME_SECTION_HEADING')}
        </StyledSubSectionDefinitionTerm>
        <StyledSubSectionDefinitionDescription>
          {user ? user.username || t('COMMON_NOT_AVAILABLE') : '...'}
        </StyledSubSectionDefinitionDescription>
      </div>
      <FeatureEnabled featureToggles={['Account.Username.Change']}>
        <StyledButtonContainer>
          <Button
            aria-label={t('CHANGE_USERNAME')}
            as={SmartLink}
            variant="secondary"
            to={'/account/username'}
          >
            {t('COMMON_BUTTON_CHANGE')}
          </Button>
        </StyledButtonContainer>
      </FeatureEnabled>
    </StyledSubSection>
  );
};
