import {
  FormEvent,
  FocusEvent,
  KeyboardEvent,
  useRef,
  forwardRef,
  useImperativeHandle,
  ClipboardEventHandler,
  useId,
  useState,
} from 'react';
import {
  StyledPinContainer,
  StyledErrorMessage,
  StyledInput,
  StyledContainer,
} from './PinField.styles';
import { useTranslation } from 'react-i18next';

export type PinFieldHandle = {
  clear: () => void;
  focus: () => void;
};

export type PinFieldChangeEvent = {
  value: string;
  lastIndexChanged: number;
};

type PinFieldProps = {
  id?: string;
  required?: boolean;
  className?: string;
  onChange?: (event: PinFieldChangeEvent) => void;
  autoFocus?: boolean;
  errorMessage?: string;
  length: number;
};

export const PinField = forwardRef<PinFieldHandle, PinFieldProps>(
  (
    { id, className, required, onChange, autoFocus, errorMessage: fieldErrorMessage, length },
    ref,
  ) => {
    const { t } = useTranslation();
    const inputRefs = useRef<(HTMLInputElement | null)[]>([]);
    const messageId = useId();
    const [pinDigitErrors, setPinDigitErrors] = useState<string[]>([]);

    const onInput = (index: number, { target }: FormEvent<HTMLInputElement>) => {
      // Somehow typescript doesn't recognize the target as a given HTMLInputElement
      if (!(target instanceof HTMLInputElement)) {
        return;
      }

      const newErrors = [...pinDigitErrors];
      newErrors[index] = '';
      setPinDigitErrors(newErrors);

      if (target.value && index < inputRefs.current.length - 1) {
        inputRefs.current[index + 1]?.focus();
      }

      onChange?.({
        value: inputRefs.current.map((inputEl) => inputEl?.value).join(''),
        lastIndexChanged: index,
      });
    };

    const onKeyDown = (index: number, { key, target }: KeyboardEvent<HTMLInputElement>) => {
      // Somehow typescript doesn't recognize the target as a given HTMLInputElement
      if (!(target instanceof HTMLInputElement)) {
        return;
      }

      if (key === 'Backspace' && !target.value && index > 0) {
        inputRefs.current[index - 1]?.focus();
      }
    };

    const onFocus = (index: number, { target }: FocusEvent<HTMLInputElement>) => {
      target.select();
    };

    const onPaste: ClipboardEventHandler<HTMLInputElement> = (event) => {
      event.preventDefault();
      const paste = event.clipboardData?.getData('text');
      if (paste?.length === inputRefs.current.length) {
        paste.split('').forEach((char, index) => {
          const inputEl = inputRefs.current[index];
          if (inputEl) {
            inputEl.value = char;
          }
        });
        onChange?.({
          value: paste,
          lastIndexChanged: paste.length - 1,
        });

        inputRefs.current[paste.length - 1]?.focus();
      }
    };

    const onInvalid = (index: number, e: FormEvent<HTMLInputElement>) => {
      e.preventDefault();
      const newErrors = [...pinDigitErrors];
      newErrors[index] = t('PIN_VALIDATION_ERROR_FORMAT');
      setPinDigitErrors(newErrors);
    };

    useImperativeHandle(ref, () => ({
      clear: () => {
        inputRefs.current.forEach((inputEl) => {
          if (inputEl) {
            inputEl.value = '';
          }
        });
      },
      focus: () => {
        inputRefs.current[0]?.focus();
      },
    }));

    const firstDigitError = pinDigitErrors.find(Boolean);
    const errorMessage = firstDigitError ?? fieldErrorMessage;

    return (
      <StyledContainer>
        <StyledPinContainer className={className}>
          {Array.from({ length }).map((_, index) => (
            <StyledInput
              {...(id && index === 0 ? { id } : {})}
              aria-invalid={!!(pinDigitErrors[index] || fieldErrorMessage)}
              autoFocus={autoFocus && index === 0}
              type="password"
              inputMode="numeric"
              pattern="[0-9]"
              key={index}
              maxLength={1}
              onFocus={(event) => onFocus(index, event)}
              onInput={(event) => onInput(index, event)}
              onInvalid={(event) => onInvalid(index, event)}
              onKeyDown={(event) => onKeyDown(index, event)}
              ref={(inputEl) => (inputRefs.current[index] = inputEl)}
              onPaste={onPaste}
              required={required}
              aria-label={t('A11Y_ENTER_PIN_X_OF_Y', { index: index + 1, count: length })}
              aria-describedby={messageId}
            />
          ))}
        </StyledPinContainer>
        {!!errorMessage && (
          <StyledErrorMessage id={messageId} aria-live="assertive" role="alert">
            {errorMessage}
          </StyledErrorMessage>
        )}
      </StyledContainer>
    );
  },
);
