import { Variants } from 'framer-motion';
import { MenuItemGroup } from '../../hooks';
import { StyledDropDownMenuContainer } from './DropDownMenu.styles';
import { DropDownMenuItem } from './DropDownMenuItem';
import { StyledList } from '../HeaderViewSmall/SideMenu.styles';

type DropDownMenuProps = {
  open: boolean;
  menuItemGroups: MenuItemGroup[];
};

const animations: Variants = {
  open: {
    display: 'block',
    opacity: 1,
    transition: { duration: 0.3 },
  },
  closed: {
    opacity: 0,
    transition: { duration: 0.3 },
    transitionEnd: { display: 'none' },
  },
};

export const DropDownMenu = ({ open, menuItemGroups }: DropDownMenuProps) => {
  return (
    <StyledDropDownMenuContainer
      animate={open ? 'open' : 'closed'}
      initial="closed"
      variants={animations}
    >
      {menuItemGroups.map(({ menuItems }, i) => (
        <StyledList key={i} role="list">
          {menuItems.map((menuItem, i) => (
            <li key={menuItem.id}>
              <DropDownMenuItem
                key={i}
                menuItem={menuItem}
                variant={menuItem.id === 'logout' ? 'dark' : 'normal'}
              />
            </li>
          ))}
        </StyledList>
      ))}
    </StyledDropDownMenuContainer>
  );
};
