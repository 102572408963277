import { css, Global, useTheme } from '@emotion/react';
import { StyledHideOneTrustFloatingButton } from 'OneTrust.styles';
import emotionNormalize from 'emotion-normalize';

export const GlobalStyles = () => {
  const theme = useTheme();
  return (
    <Global
      styles={css`
        ${emotionNormalize}

        * {
          box-sizing: border-box;
        }
        body {
          overflow-x: hidden;
          overflow-y: scroll;
        }

        body.no-scroll {
          overflow-y: hidden;
        }

        html,
        body:not(.is-tabbing) *:focus {
          outline: 0;
        }

        html {
          scroll-behavior: smooth;
          scroll-padding-top: var(--header-scroll-padding-top, 50px);
        }

        body {
          font-family: ${theme.designFonts.font.primary.regular.face};
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          touch-action: pan-x pan-y;
          background-color: ${theme.designTokens.color.background.primary};
          color: ${theme.designTokens.color.text.primary};
          margin: 0;
          padding: 0;
        }

        button,
        a {
          cursor: pointer;
        }

        small {
          color: ${theme.designTokens.color.text.disabled};
        }

        a {
          display: inline-block;
          text-decoration: none;
          color: ${theme.designTokens.color.text.primary};
          cursor: pointer;
        }

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
          font-family: ${theme.designFonts.font.primary.regular.face};
          margin-bottom: 16px;
          margin-top: 0;
          text-transform: ${theme.designTokens.typography.headingPrimary.textTransform};
        }
        ${StyledHideOneTrustFloatingButton}
      `}
    />
  );
};
