import { Config } from '@telia-company/tv.common-sdk/dist/Config';
import { useTranslation } from 'react-i18next';
import { isFeatureEnabled } from 'featureToggles';
import { HelpLink, HelpLinkType } from '../HelpLink';
import { StyledSectionHeading, StyledSubSectionList } from '../../../navigation.styles';
import { useId } from 'react';

export const FeedbackAndFaq = () => {
  const { t } = useTranslation();
  const headingId = useId();

  const links: HelpLinkType[] = [
    {
      labelText: t('HELP_FAQ_TITLE'),
      labelContent: t('HELP_FAQ_TEXT'),
      url: Config.get('one-app-faq-path'),
      buttonText: t('HELP_BUTTON_GO_TO_FAQ'),
    },
  ];

  const showFeedback = isFeatureEnabled(['Help.ShowFeedback']);
  if (showFeedback) {
    links.push({
      labelText: t('HELP_FEEDBACK_TITLE'),
      labelContent: t('HELP_FEEDBACK_TEXT'),
      url: Config.get('help-page-links')['feedback'],
      buttonText: t('HELP_BUTTON_GO_TO_SUGGESTIONS'),
    });
  }

  return (
    <section aria-labelledby={headingId}>
      <StyledSectionHeading id={headingId}>
        {t('HELP_FEEDBACK_AND_FAQ_SUBHEADING')}
      </StyledSectionHeading>
      <StyledSubSectionList role="list">
        {links.map((link, id) => (
          <li key={id}>
            <HelpLink link={link} />
          </li>
        ))}
      </StyledSubSectionList>
    </section>
  );
};
