import { MouseEvent } from 'react';
import { MenuItem } from '../../hooks';
import {
  StyledMenuItemImage,
  StyledMenuItemImageLabelContainer,
  StyledMenuItemInfo,
  StyledSmartLink,
} from './MenuItemLink.styles';

type MenuItemLinkProps = {
  onClick?: (e: MouseEvent) => void;
  menuItem: MenuItem;
};

export const MenuItemLink = ({
  onClick,
  menuItem: { info, label, imageUrl, url, ariaLabel, onClick: menuItemOnClick },
}: MenuItemLinkProps) => {
  return (
    <StyledSmartLink
      to={url}
      onClick={(e) => menuItemOnClick?.(e) && onClick?.(e)}
      aria-label={ariaLabel}
    >
      <StyledMenuItemImageLabelContainer>
        {imageUrl && <StyledMenuItemImage src={imageUrl} alt="" />}
        {label}
      </StyledMenuItemImageLabelContainer>
      {info && <StyledMenuItemInfo>{info}</StyledMenuItemInfo>}
    </StyledSmartLink>
  );
};
