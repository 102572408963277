import { useEffect, useState } from 'react';
import {
  StyledCollapsibleButton,
  StyledColumn,
  StyledHeading,
  StyledLinkList,
  StyledMotion,
  StyledOptAnonButton,
} from './Column.styles';
import { useMediaQueryMinSize as useMediaQueryMinWidth } from 'hooks';
import { Chevron } from 'components/Chevron';
import { AnimatePresence } from 'framer-motion';

export type ColumnLink = {
  name?: string;
  link?: string;
  type?: 'link' | 'button';
};

export type ColumnType = {
  heading?: string;
  linkList?: ColumnLink[];
};

export const Column = ({ heading, linkList }: ColumnType) => {
  const isLargeWidth = useMediaQueryMinWidth('L');
  const [open, setOpen] = useState<boolean>(isLargeWidth);
  const toggleOpen = () => setOpen(!open);

  useEffect(() => {
    setOpen(isLargeWidth);
  }, [isLargeWidth]);

  return (
    <StyledColumn>
      {isLargeWidth ? (
        <StyledHeading className="open">{heading}</StyledHeading>
      ) : (
        <StyledCollapsibleButton onClick={toggleOpen} aria-expanded={open}>
          <StyledHeading className={open ? 'open' : ''}>{heading}</StyledHeading>
          <Chevron open={open} size={'small'} />
        </StyledCollapsibleButton>
      )}

      <AnimatePresence initial={true}>
        {open && (
          <StyledMotion
            animate="open"
            initial={isLargeWidth ? 'open' : 'close'}
            exit="close"
            transition={{ duration: 0.2, ease: 'easeOut' }}
            variants={{
              close: { height: 0, opacity: 0 },
              open: { height: 'auto', opacity: 1 },
            }}
          >
            <StyledLinkList>
              {linkList?.map((link, index) => (
                // currently the only item using type button is the cookie-settings, In case another item also needs to be a button the logic has to be changed.
                <li key={index}>
                  {link.type === 'button' ? (
                    <StyledOptAnonButton className="optanon-toggle-display" id="ot-sdk-btn">
                      {link.name}
                    </StyledOptAnonButton>
                  ) : (
                    <a href={link.link || '/'}>{link.name}</a>
                  )}
                </li>
              ))}
            </StyledLinkList>
          </StyledMotion>
        )}
      </AnimatePresence>
    </StyledColumn>
  );
};
