import {
  StyledSectionHeading,
  StyledSectionSubHeadingDescription,
  StyledSubSection,
  StyledSubSectionContainer,
  StyledSubSectionDefinitionDescription,
  StyledSubSectionDefinitionTerm,
} from 'pages/navigation.styles';
import { useFetchFunction } from 'hooks/useFetchFunction';
import { getMfaStatus, mfaChangeEvent } from 'services/userService';
import { useEffect, useMemo } from 'react';
import { Button } from '@telia-company/tv.oneapp-web-ui';
import { SmartLink } from 'components';
import { useLogger } from 'hooks';
import { useTranslation } from 'react-i18next';
import { isFeatureEnabled } from 'featureToggles';

export const MfaSection = () => {
  const logger = useLogger('MfaSection');
  const { data, loading, error, refresh } = useFetchFunction(getMfaStatus);
  const { t } = useTranslation();

  useEffect(() => {
    // Whenever the MFA status changes, we want to refresh the data
    const unsubscribe = mfaChangeEvent.on(() => refresh());
    return () => {
      mfaChangeEvent.off(unsubscribe);
    };
  });

  useEffect(() => {
    if (error) {
      logger.error('Failed to fetch MFA status', error);
    }
  }, [error, logger]);

  const status = useMemo(() => {
    if (loading) {
      return '...';
    }
    if (data) {
      return data.some((mfa) => mfa.status === 'ACTIVE')
        ? t('ACCOUNT_MFA_SECTION_MFA_STATUS_ACTIVE')
        : t('ACCOUNT_MFA_SECTION_MFA_STATUS_INACTIVE');
    }
    return t('ERROR_GENERIC_INFORMATION');
  }, [data, loading, t]);

  const allowChange = isFeatureEnabled(['MfaChange']);

  return (
    <section aria-label={t('ACCOUNT_MFA_SECTION_HEADING')}>
      <StyledSectionHeading>{t('ACCOUNT_MFA_SECTION_HEADING')}</StyledSectionHeading>
      <StyledSectionSubHeadingDescription>
        {t('ACCOUNT_MFA_SECTION_DESCRIPTION')}
      </StyledSectionSubHeadingDescription>
      <StyledSubSectionContainer>
        <StyledSubSection>
          <div>
            <StyledSubSectionDefinitionTerm>
              {t('ACCOUNT_MFA_SECTION_SUB_HEADING')}
            </StyledSubSectionDefinitionTerm>
            <StyledSubSectionDefinitionDescription>{status}</StyledSubSectionDefinitionDescription>
          </div>
          <Button
            variant="secondary"
            as={SmartLink}
            to="/account/mfa"
            aria-label={allowChange ? t('CHANGE_MFA_SETTINGS') : t('SHOW_MFA_SETTINGS')}
          >
            {allowChange ? t('COMMON_BUTTON_CHANGE') : t('COMMON_BUTTON_SHOW')}
          </Button>
        </StyledSubSection>
      </StyledSubSectionContainer>
    </section>
  );
};
