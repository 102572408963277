import { Spinner } from '@telia-company/tv.oneapp-web-ui';
import { useEffect, useState } from 'react';
import { StyledSpinnerContainer } from './SectionSpinner.styles';

export type SectionSpinnerProps = {
  className?: string;
};

export const SectionSpinner = ({ className }: SectionSpinnerProps) => {
  const [spinnerVisible, setSpinnerVisible] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => setSpinnerVisible(true), 250);
    return () => clearTimeout(timeout);
  }, []);

  return (
    <StyledSpinnerContainer className={className} aria-busy={spinnerVisible}>
      {spinnerVisible && <Spinner size={48} />}
    </StyledSpinnerContainer>
  );
};
