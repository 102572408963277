import { Overlay } from 'components/Overlay';
import { LazyMotion, Variants } from 'framer-motion';
import { useState } from 'react';
import { MenuItem, MenuItemGroup } from '../../hooks';
import { MenuItemLink } from './MenuItemLink';
import {
  StyledList,
  StyledSideMenuContainer,
  StyledSideMenuGeneralSection,
  StyledSideMenuUserSection,
} from './SideMenu.styles';

const SIDEBAR_WIDTH = 300;

const animations: Variants = {
  visible: {
    left: 0,
    transition: {
      duration: 0.3,
    },
  },
  hidden: {
    left: -SIDEBAR_WIDTH,
    transition: {
      duration: 0.3,
    },
  },
};

type SideMenuProps = {
  open: boolean;
  menuItems: MenuItem[];
  userMenuItems?: MenuItem[];
  userMenuItemGroups?: MenuItemGroup[];
  onDismiss?: () => void;
};

// lazy load features from framer-motion on demand
const loadFeatures = () =>
  import('../../../Footer/utils/fetchFramerMotionFeatures').then((res) => res.default);

export const SideMenu = ({ onDismiss, open, menuItems, userMenuItemGroups }: SideMenuProps) => {
  const [draggingX, setDraggingX] = useState(false);
  return (
    <Overlay visible={open} onClick={() => onDismiss?.()}>
      <LazyMotion features={loadFeatures}>
        <StyledSideMenuContainer
          animate={open ? 'visible' : 'hidden'}
          variants={animations}
          initial="hidden"
          exit="hidden"
          drag="x"
          dragConstraints={{
            left: -SIDEBAR_WIDTH,
            right: 0,
          }}
          dragDirectionLock={true}
          dragElastic={false}
          dragMomentum={false}
          onDirectionLock={(axis) => {
            if (axis === 'x') {
              setDraggingX(true);
            }
          }}
          onDragEnd={() => {
            if (draggingX && onDismiss) {
              onDismiss();
            }
            setDraggingX(false);
          }}
        >
          <StyledSideMenuGeneralSection>
            <StyledList role="list">
              {menuItems.map((menuItem, i) => (
                <li key={i}>
                  <MenuItemLink onClick={() => onDismiss?.()} menuItem={menuItem} />
                </li>
              ))}
            </StyledList>
          </StyledSideMenuGeneralSection>
          {userMenuItemGroups && (
            <StyledSideMenuUserSection>
              {userMenuItemGroups.map((menuItemGroup, i) => (
                <StyledList role="list" key={i}>
                  {menuItemGroup.menuItems.map((menuItem, i) => (
                    <li key={i}>
                      <MenuItemLink onClick={() => onDismiss?.()} menuItem={menuItem} />
                    </li>
                  ))}
                </StyledList>
              ))}
            </StyledSideMenuUserSection>
          )}
        </StyledSideMenuContainer>
      </LazyMotion>
    </Overlay>
  );
};
