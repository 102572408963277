import { oneAppUrl } from 'utils';
import { useTranslation } from 'react-i18next';
import { HelpLink, HelpLinkType } from '../HelpLink';
import { StyledSubSectionHeading, StyledSubSectionList } from '../../../navigation.styles';
import { getLinkUrlFromOtherLinksInConfig } from '../../utils';
import { useId } from 'react';

export const OtherLinks = () => {
  const { t } = useTranslation();
  const headingId = useId();

  const links: HelpLinkType[] = [
    {
      labelText: t('HELP_RESPONSIBLE_PUBLISHERS_TITLE'),
      url: oneAppUrl(getLinkUrlFromOtherLinksInConfig('publishers')),
      buttonText: t('HELP_BUTTON_READ_MORE'),
      buttonAriaLabel: t('A11Y_READ_MORE_ABOUT', { label: t('HELP_RESPONSIBLE_PUBLISHERS_TITLE') }),
    },
    {
      labelText: t('HELP_USER_TERMS_TITLE'),
      url: oneAppUrl(getLinkUrlFromOtherLinksInConfig('terms')),
      buttonText: t('HELP_BUTTON_READ_MORE'),
      buttonAriaLabel: t('A11Y_READ_MORE_ABOUT', { label: t('HELP_USER_TERMS_TITLE') }),
    },
  ];

  return (
    <section aria-labelledby={headingId}>
      <StyledSubSectionHeading id={headingId}>
        {t('HELP_OTHER_LINKS_SUBHEADING')}
      </StyledSubSectionHeading>
      <StyledSubSectionList role="list">
        {links.map((link, id) => (
          <li key={id}>
            <HelpLink link={link} />
          </li>
        ))}
      </StyledSubSectionList>
    </section>
  );
};
